import React, { useEffect, useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { AppContext, UserContext } from "../App";
import Loading from "./Loading";
import ErrorPage from "./Error";
import CardLink from "../CardLink";
import { Button } from "@material-ui/core";
import util from "../util";

function GetStarted() {
  const history = useHistory();
  const api = useContext(AppContext).api;
  const userContext = useContext(UserContext);
  const [fundraiser, setFundraiser] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        const fundraisers = await api.getUserFundraisers(userContext.user.id);
        if (fundraisers.length) setFundraiser(fundraisers[0]);
      } catch (err) {
        setError(true);
        util.error(
          err,
          `Failed to get fundraisers for user: ${userContext.user.id}`
        );
      }
      setLoading(false);
    })();
  }, [api, userContext.user]);

  function preview() {
    util.scrollTop();
    history.push(`/p/${userContext.user.id}`);
  }

  return loading ? (
    <Loading />
  ) : error ? (
    <ErrorPage />
  ) : (
    <div id="get-started" className="view with-nav long">
      <div className="inner">
        <h2>Ready to change the world?</h2>
        <p>
          Just perform an <span className="buzzword">act of kindness</span> for
          someone else. Here are{" "}
          <Button
            className="linkish thin"
            onClick={() => history.push("/faq#UWFHSUCAQ")}
          >
            some ideas
          </Button>{" "}
          to get you started.
        </p>
        <p>
          Then{" "}
          <Button
            className="linkish thin"
            onClick={() => history.push("/faq#F4HFy7OhN")}
          >
            ask them{" "}
          </Button>{" "}
          to pay it forward if they can and send them your unique pay-it-forward
          link.
        </p>

        {fundraiser ? (
          <>
            <CardLink
              header={`Your pay-it-forward link for ${fundraiser.fundraiser.name}:`}
              url={`https://rippl.me/f/${fundraiser.id}`}
            />

            <p className="small">
              <Button
                className="linkish"
                onClick={() => history.push(`/f/${fundraiser.id}`)}
              >
                Here&apos;s what they&apos;ll see
              </Button>
            </p>
          </>
        ) : (
          <>
            <CardLink
              header="Your pay-it-forward link:"
              url={`https://rippl.me/p/${userContext.user.id}`}
            />

            <p className="small">
              <Button className="linkish" onClick={preview}>
                Here&apos;s what they&apos;ll see
              </Button>
            </p>
          </>
        )}

        <p>
          You can follow the impact of your acts of kindness on your dashboard.
        </p>
        <Button
          variant="contained"
          color="primary"
          onClick={() => history.push("/dashboard")}
        >
          Go to My Dashboard
        </Button>
      </div>
    </div>
  );
}

export default GetStarted;
