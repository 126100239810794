import React, { useContext, useState } from "react";
import { AppContext } from "../App";
import { useHistory } from "react-router-dom";
import LoginForm from "../LoginForm";
import Loading from "./Loading";
import ErrorBar from "../ErrorBar";
import util from "../util";

function JoinFundraiserLogin({ fundraiser }) {
  const api = useContext(AppContext).api;
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  async function onLoggedIn(user) {
    setLoading(true);
    try {
      await api.joinFundraiser(fundraiser.id, user.id);
      util.scrollTop();
      history.replace(`/ripple`);
    } catch (err) {
      if (err.status === 409) {
        util.scrollTop();
        history.replace(`/ripple`);
      } else {
        setError(true);
        setLoading(false);
        util.error(err, `Failed to join fundraiser: ${fundraiser.id}`);
      }
    }
  }

  return loading ? (
    <Loading />
  ) : (
    <div id="join-fundraiser-login" className="view">
      <div className="inner">
        <div className="header">
          <h1>Login</h1>
        </div>

        <LoginForm onLoggedIn={onLoggedIn} />
        <ErrorBar error={error} />
      </div>
    </div>
  );
}

export default JoinFundraiserLogin;
