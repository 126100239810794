import React from "react";
import "../assets/css/SelectCharitySearch.css";
import Panel from "../Panel";
import { useRouteMatch, useHistory } from "react-router-dom";
import { Button, Grid, Card } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRibbon } from "@fortawesome/free-solid-svg-icons";
import util from "../util";

function SelectCharitySearch({ charities, onSelect }) {
  const { url } = useRouteMatch();
  const history = useHistory();

  function view(id) {
    util.scrollTop();
    history.push(`${util.getParentPath(url)}/view/${id}`);
  }

  return (
    <div id="select-charity-search" className="view with-nav long">
      <div className="inner">
        <h2>How would you like to help?</h2>

        <Panel heading="Featured Charities">
          <Grid container justify="center">
            {charities.map((charity) => (
              <Card key={charity.id}>
                <div
                  className={`card-image ${!charity.logoUrl && "missing"}`}
                  onClick={() => view(charity.id)}
                >
                  {charity.logoUrl ? (
                    <img src={charity.logoUrl} alt="" />
                  ) : (
                    <FontAwesomeIcon icon={faRibbon} />
                  )}
                </div>
                <div className="card-footer">
                  <div className="card-title">{charity.name}</div>
                  <div className="card-links">
                    <Button className="more" onClick={() => view(charity.id)}>
                      Charity Info
                    </Button>
                    <Button
                      className="select"
                      variant="contained"
                      color="primary"
                      onClick={async () => onSelect(charity)}
                    >
                      Select
                    </Button>
                  </div>
                </div>
              </Card>
            ))}
          </Grid>
        </Panel>
      </div>
    </div>
  );
}

export default SelectCharitySearch;
