import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import "../assets/css/Profile.css";
import { UserContext } from "../App";
import Panel from "../Panel";
import { Button } from "@material-ui/core";

function Profile() {
  const userContext = useContext(UserContext);
  const history = useHistory();

  return (
    <div id="profile" className="view with-nav">
      <Panel heading="Your Profile" textAlign="left">
        <Panel.Body>
          <div className="field-list">
            <div className="field-row">
              <div className="field-name">First Name:</div>
              <div className="field-value">{userContext.user.firstName}</div>
            </div>
            <div className="field-row">
              <div className="field-name">Email:</div>
              <div className="field-value">{userContext.user.email}</div>
            </div>
            <div className="field-row">
              <div className="field-name">Refer to me as:</div>
              <div className="field-value">
                {userContext.user.gender === "Male"
                  ? "He"
                  : userContext.user.gender === "Female"
                  ? "She"
                  : "They"}
              </div>
            </div>
          </div>
        </Panel.Body>
      </Panel>

      <Button
        variant="contained"
        color="primary"
        onClick={() => history.push("/edit-profile")}
      >
        Edit
      </Button>
      <div id="forgot">
        <Button
          onClick={() => history.push("/forgot-password", { change: true })}
        >
          Change password
        </Button>
      </div>
    </div>
  );
}

export default Profile;
