import React from "react";
import "../assets/css/SelectCharityView.css";
import Panel from "../Panel";
import NotFound from "./NotFound";
import { useHistory, useRouteMatch, useParams } from "react-router-dom";
import { Button } from "@material-ui/core";
import _ from "lodash";

function SelectCharityView({ charities, onSelect }) {
  const { url } = useRouteMatch();
  const history = useHistory();
  const { cid } = useParams();
  const charity = _.find(charities, (it) => it.id === cid);

  function back() {
    const base = url.match(/(\/.+)\/.+\//)[1];
    history.push(`${base}/search`);
  }

  return (
    <>
      {!charity ? (
        <NotFound />
      ) : (
        <div id="select-charity-view" className="view with-nav long">
          <div className="inner">
            <h3>{charity.name}</h3>

            <Panel heading="Mission" textAlign="left">
              <Panel.Body>
                <p className="small">{charity.mission}</p>
              </Panel.Body>
            </Panel>

            <Panel heading="Website" textAlign="left">
              <Panel.Body>
                <p className="small">
                  <a
                    href={charity.websiteUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {charity.websiteUrl}
                  </a>
                </p>
              </Panel.Body>
            </Panel>

            <Button onClick={back}>Back</Button>
            <Button
              variant="contained"
              color="primary"
              onClick={async () => onSelect(charity)}
            >
              Select
            </Button>
          </div>
        </div>
      )}
    </>
  );
}

export default SelectCharityView;
