import React from "react";
import { useRouteMatch, useHistory } from "react-router-dom";
import FloatingInput from "../FloatingInput";
import { Button } from "@material-ui/core";
import util from "../util";
import _ from "lodash";

function PifDesc({ act, onUpdateDescription }) {
  const { url } = useRouteMatch();
  const history = useHistory();
  const firstName = _.get(act, "actor.firstName");

  function handleSubmit() {
    history.push(`${util.getParentPath(url)}/pledge`);
  }

  return (
    <div id="pif-desc" className="view with-nav">
      <div className="inner">
        <h2>What did {firstName} do for you?</h2>

        <form
          className="full-width"
          noValidate
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <fieldset>
            <div className="input-group">
              <FloatingInput
                type="multiline"
                name="description"
                value={act.description}
                onChange={(field, value) => {
                  onUpdateDescription(value);
                }}
              />
            </div>
          </fieldset>
          <div className="button-row">
            <Button type="button" onClick={() => history.goBack()}>
              Back
            </Button>
            <Button
              type="button"
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              disabled={!act.description}
            >
              Next
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default PifDesc;
