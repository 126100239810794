import React, { useState, useCallback, useContext } from "react";
import { AppContext, UserContext } from "./App";
import FloatingInput from "./FloatingInput";
import ErrorBar from "./ErrorBar";
import Spin from "./Spin";
import util from "./util";
import { Button } from "@material-ui/core";

function RegisterForm({ preUser, onUserCreated }) {
  const appContext = useContext(AppContext);
  const userContext = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(preUser || {});
  const [invalidFields, setInvalidFields] = useState([]);
  const [errMsg, setErrMsg] = useState();
  const [error, setError] = useState(false);
  const handleValidate = useCallback(util.handleValidate(setInvalidFields), []);

  const passwordValidate = useCallback(
    (val) => {
      return val === user.password ? null : "Passwords do not match";
    },
    [user]
  );

  function handleChange(field, value) {
    setUser((prev) => ({ ...prev, [field]: value }));
  }

  async function handleSubmit(e) {
    e.preventDefault();
    if (invalidFields.length) return;
    setLoading(true);
    setError(false);
    setErrMsg();

    try {
      const res = await appContext.api.register(user);
      await appContext.api.login(user.email, user.password);
      util.event("User", "Register");
      userContext.setUser(res.body);
      onUserCreated(res.body);
    } catch (err) {
      if (err.status === 409) {
        setErrMsg("Sorry, that email address is already in use.");
      } else {
        setError(true);
        util.error(err, "Registration failed");
      }
      setLoading(false);
    }
  }

  function go(path) {
    window.open(path, "_blank");
  }

  return (
    <form
      className={loading ? "loading" : ""}
      noValidate
      onSubmit={(e) => {
        e.preventDefault();
      }}
    >
      <fieldset>
        <div className="input-group">
          <FloatingInput
            name="firstName"
            label="First Name"
            value={user.firstName}
            onChange={handleChange}
            required
            maxLength={255}
            onValidate={handleValidate}
          />
        </div>
        <div className="input-group">
          <FloatingInput
            name="email"
            label="Email"
            type="email"
            value={user.email}
            onChange={handleChange}
            required
            maxLength={255}
            validate='^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$'
            onValidate={handleValidate}
            autoComplete="username"
          />
        </div>
        <div className="input-group">
          <FloatingInput
            name="password"
            label="Password"
            type="password"
            value={user.password}
            onChange={handleChange}
            required
            maxLength={120}
            onValidate={handleValidate}
            autoComplete="new-password"
          />
        </div>
        <div className="input-group">
          <FloatingInput
            name="passwordConfirm"
            label="Confirm Password"
            type="password"
            value={user.passwordConfirm}
            onChange={handleChange}
            required
            maxLength={120}
            onValidate={handleValidate}
            validate={passwordValidate}
            autoComplete="new-password"
          />
        </div>
        {errMsg && <div className="form-msg">{errMsg}</div>}
        <Spin />
      </fieldset>
      <Button
        type="button"
        variant="contained"
        color="primary"
        onClick={handleSubmit}
        disabled={loading || invalidFields.length > 0}
      >
        Submit
      </Button>

      <p className="subtext">
        By clicking Submit, you agree to our{" "}
        <Button className="linkish" onClick={() => go("/privacy")}>
          Privacy Policy
        </Button>{" "}
        and{" "}
        <Button className="linkish" onClick={() => go("/terms")}>
          Terms of Use
        </Button>
        .
      </p>
      <ErrorBar error={error} />
    </form>
  );
}

export default RegisterForm;
