import React, { useState, useEffect, useContext } from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import Pledges from "./Pledges";
import Pledge from "./Pledge";
import View from "../View";
import NavBar from "../NavBar";
import NotFound from "./NotFound";
import ErrorPage from "./Error";
import { AppContext } from "../App";
import util from "../util";
import _ from "lodash";

function PledgesContainer(props) {
  const api = useContext(AppContext).api;
  const parentLoading = props.loading;
  const { path } = useRouteMatch();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [acts, setActs] = useState();

  useEffect(() => {
    if (parentLoading) return;
    (async () => {
      try {
        setActs(await api.getUserPledges());
      } catch (err) {
        setError(true);
        util.error(err, "Failed to get user pledges");
      }
      setLoading(false);
    })();
  }, [api, parentLoading]);

  function onUpdateAct(act) {
    setActs((prevActs) => {
      const i = _.findIndex(prevActs, { id: act.id });
      const newActs = [...prevActs];
      newActs[i] = act;
      return newActs;
    });
  }

  return (
    <>
      {error ? (
        <ErrorPage />
      ) : (
        <Switch>
          <Route exact path={path}>
            <View loading={loading} secure>
              <NavBar />
              <Pledges acts={acts} />
            </View>
          </Route>
          <Route path={`${path}/:aid`}>
            <View loading={loading} secure>
              <NavBar />
              <Pledge acts={acts} onUpdateAct={onUpdateAct} />
            </View>
          </Route>
          <Route path="*">
            <View>
              <NotFound />
            </View>
          </Route>
        </Switch>
      )}
    </>
  );
}

export default PledgesContainer;
