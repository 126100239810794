import React, { useState, useEffect, useContext } from "react";
import { Switch, Route, useRouteMatch, useParams } from "react-router-dom";
import { AppContext } from "../App";
import View from "../View";
import NavBar from "../NavBar";
import NotFound from "./NotFound";
import ErrorPage from "./Error";
import InviteLand from "./InviteLand";
import InviteRegister from "./InviteRegister";
import InviteLogin from "./InviteLogin";
import util from "../util";

function InviteContainer(props) {
  const appContext = useContext(AppContext);
  const parentLoading = props.loading;
  const { path } = useRouteMatch();
  const { aid } = useParams();
  const [act, setAct] = useState({});
  const [loading, setLoading] = useState(true);
  const [notFound, setNotFound] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (parentLoading) return;
    (async () => {
      try {
        setAct(await appContext.api.getPublicAct(aid));
      } catch (err) {
        if (err.status === 404) {
          setNotFound(true);
        } else {
          setError(true);
          util.error(err, `Failed to get act: ${aid}`);
        }
      }
      setLoading(false);
    })();
  }, [aid, parentLoading, appContext.api]);

  return (
    <>
      {notFound ? (
        <NotFound />
      ) : error ? (
        <ErrorPage />
      ) : (
        <Switch>
          <Route exact path={path}>
            <View loading={loading}>
              <NavBar />
              <InviteLand act={act} />
            </View>
          </Route>
          <Route path={`${path}/register`}>
            <View loading={loading}>
              <InviteRegister act={act} />
            </View>
          </Route>
          <Route path={`${path}/login`}>
            <View loading={loading}>
              <InviteLogin act={act} />
            </View>
          </Route>
          <Route path="*">
            <View>
              <NotFound />
            </View>
          </Route>
        </Switch>
      )}
    </>
  );
}

export default InviteContainer;
