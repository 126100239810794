import React, { useState, useEffect, useContext } from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import Acts from "./Acts";
import Act from "./Act";
import Ripple from "./Ripple";
import View from "../View";
import NavBar from "../NavBar";
import NotFound from "./NotFound";
import ErrorPage from "./Error";
import { AppContext } from "../App";
import util from "../util";

function ActsContainer(props) {
  const api = useContext(AppContext).api;
  const parentLoading = props.loading;
  const { path } = useRouteMatch();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [acts, setActs] = useState();

  useEffect(() => {
    if (parentLoading) return;
    (async () => {
      try {
        setActs(await api.getUserActs());
      } catch (err) {
        setError(true);
        util.error(err, "Failed to get user acts");
      }
      setLoading(false);
    })();
  }, [api, parentLoading]);

  return (
    <>
      {error ? (
        <ErrorPage />
      ) : (
        <Switch>
          <Route exact path={path}>
            <View loading={loading} secure>
              <NavBar />
              <Acts acts={acts} />
            </View>
          </Route>
          <Route exact path={`${path}/:aid`}>
            <View loading={loading} secure>
              <NavBar />
              <Act acts={acts} />
            </View>
          </Route>
          <Route exact path={`${path}/:aid/ripple`}>
            <View loading={loading} secure>
              <NavBar />
              <Ripple acts={acts} />
            </View>
          </Route>
          <Route path="*">
            <View>
              <NotFound />
            </View>
          </Route>
        </Switch>
      )}
    </>
  );
}

export default ActsContainer;
