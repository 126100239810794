import React from "react";
import "./assets/css/PanelList.css";
import Panel from "./Panel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";

function PanelList({ id, className, loading, children }) {
  return (
    <div id={id} className={classNames("panel-list", className, { loading })}>
      {children}
    </div>
  );
}

function Item({ id, children, onClick, ...rest }) {
  return (
    <Panel key={id} onClick={onClick} segmented {...rest}>
      {children}
      {onClick && (
        <div className="caret">
          <FontAwesomeIcon icon={faChevronRight} />
        </div>
      )}
    </Panel>
  );
}

PanelList.Item = Item;
export default PanelList;
