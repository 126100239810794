import React, { useState, useEffect, useContext } from "react";
import { useParams, useHistory } from "react-router-dom";
import "../assets/css/Pledge.css";
import { AppContext } from "../App";
import Panel from "../Panel";
import PanelList from "../PanelList";
import CardLink from "../CardLink";
import ErrorBar from "../ErrorBar";
import NotFound from "./NotFound";
import Spin from "../Spin";
import FloatingInput from "../FloatingInput";
import Breadcrumbs from "../Breadcrumbs";
import util from "../util";
import { IconButton, Button } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRibbon } from "@fortawesome/free-solid-svg-icons";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import _ from "lodash";
import classNames from "classnames";

function Pledge({ acts, onUpdateAct }) {
  const { aid } = useParams();
  const history = useHistory();
  const api = useContext(AppContext).api;
  const act = _.find(acts, (it) => it.id === aid);
  const [childActs, setChildActs] = useState([
    {
      id: "asdf",
      actor: { firstName: "Xxxx" },
      actee: { firstName: "Xxxx" },
      description: "Xxxx xxxx xx x xxxx xxxxxx xxxx xxxxx x xxxx.",
      donation: {
        amount: 0,
      },
      createdAt: new Date(),
    },
  ]);
  const [loadingChildren, setLoadingChildren] = useState(!!act.completed);
  const [error, setError] = useState(false);
  const [edit, setEdit] = useState(false);
  const [description, setDescription] = useState(act.description);
  const [loadingDescription, setLoadingDescription] = useState(false);

  useEffect(() => {
    if (!act) return;
    (async () => {
      try {
        setChildActs(await api.getChildActs(act.id));
      } catch (err) {
        setError(true);
        util.error(err, `Failed to get child acts for act: ${act.id}`);
      }
      setLoadingChildren(false);
    })();
  }, [api, act]);

  async function saveDescription() {
    setLoadingDescription(true);
    try {
      await api.updateActDescription(act.id, description);
      setEdit(false);
      onUpdateAct({ ...act, description });
    } catch (err) {
      setError(true);
      util.error(err, `Failed update description for act: ${act.id}`);
    }
    setLoadingDescription(false);
  }

  return (
    <>
      {!act ? (
        <NotFound />
      ) : (
        <div id="pledge" className="view with-nav long expand">
          <div className="inner">
            <Breadcrumbs links={[{ copy: "Your Pledges", url: "/pledges" }]} />
            <Panel id="description" segmented>
              <Panel.Segment
                primary
                value={act.actor.firstName}
                footer={
                  <>
                    <div>Helped you</div>
                    <div>{util.formatDate(act.createdAt)}</div>
                  </>
                }
              />
              <Panel.Segment
                copy
                label="How:"
                value={
                  <>
                    {edit ? (
                      <>
                        <div className="edit-icon">
                          <IconButton
                            onClick={() => {
                              setEdit(false);
                              setDescription(act.description);
                            }}
                          >
                            <FontAwesomeIcon icon={faTimes} />
                          </IconButton>
                        </div>
                        <form
                          className={classNames("full-width", {
                            loading: loadingDescription,
                          })}
                          noValidate
                          onSubmit={(e) => {
                            e.preventDefault();
                          }}
                        >
                          <fieldset>
                            <div className="input-group">
                              <FloatingInput
                                type="multiline"
                                name="description"
                                value={description}
                                onChange={(field, value) => {
                                  setDescription(value);
                                }}
                              />
                            </div>
                          </fieldset>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={saveDescription}
                            disabled={!description}
                          >
                            Save
                          </Button>
                          <div className="hidden">{act.description}</div>
                          <Spin />
                        </form>
                      </>
                    ) : (
                      <>
                        <div className="edit-icon">
                          <IconButton onClick={() => setEdit(true)}>
                            <FontAwesomeIcon icon={faEdit} />
                          </IconButton>
                        </div>
                        {act.description}
                      </>
                    )}
                  </>
                }
              />
            </Panel>

            {act.donation.status === "PendingCharitySelection" &&
              !act.actor.lastLogin && (
                <Panel id="unknown" alert="true">
                  <Panel.Body>
                    <p>
                      {act.actor.firstName} hasn&apos;t signed up to select a
                      charity for your donation yet. Here&apos;s the link to
                      invite ${util.pronoun(act.actor, "him")}:
                    </p>
                    <CardLink
                      header={`${act.actor.firstName}'s invitation link:`}
                      url={`https://rippl.me/i/${act.id}`}
                    />
                  </Panel.Body>
                </Panel>
              )}

            <Panel
              id="donation"
              segmented
              filled
              heading="Your Donation"
              helpText={`This is how much you donated to thank ${
                act.actor.firstName
              } for ${util.pronoun(act.actor, "his")} act of kindness.`}
            >
              <Panel.Segment
                metric
                label="You donated:"
                value={
                  <>
                    <span>
                      <span className="dollar-sign">$</span>
                      {util.toDollarStr(act.donation.amount)}
                    </span>
                  </>
                }
                footer={
                  !!act.donation.fee &&
                  `+ $${util.toDollarStr(act.donation.fee)} for Rippl.me`
                }
              />
              <Panel.Segment
                label={`${act.actor.firstName} selected:`}
                value={
                  <div>
                    {act.donation.status === "PendingCharitySelection" ? (
                      <div className="unselected">
                        {act.actor.firstName} hasn&apos;t selected a charity yet
                      </div>
                    ) : (
                      <>
                        {act.donation.charity.logoUrl ? (
                          <img src={act.donation.charity.logoUrl} alt="" />
                        ) : (
                          <FontAwesomeIcon icon={faRibbon} />
                        )}

                        <div>{act.donation.charity.name}</div>
                      </>
                    )}
                  </div>
                }
              />
            </Panel>

            <Panel
              id="pledge"
              segmented
              filled
              heading="Your Pledge"
              helpText={`This is how many times you pledged to pay forward ${act.actor.firstName}'s act of kindness.`}
            >
              <Panel.Segment
                metric
                value={act.pledge}
                footer={`Act${act.pledge !== 1 ? "s" : ""} of Kindness`}
              />
              <Panel.Segment
                metric
                value={act.completed}
                footer="Completed"
                onClick={
                  act.pledge > act.completed
                    ? () => history.push("/invite")
                    : null
                }
              />
            </Panel>

            {!!act.completed && !error && (
              <PanelList id="child-acts" loading={loadingChildren}>
                {childActs.map((act) => (
                  <PanelList.Item
                    key={act.id}
                    onClick={() => history.push(`/acts/${act.id}`)}
                  >
                    <Panel.Segment
                      primary
                      label="You helped:"
                      value={act.actee.firstName}
                      footer={util.formatDate(act.createdAt)}
                    />
                    <Panel.Segment
                      copy
                      label="How:"
                      value={
                        act.description.length > 120 ? (
                          <>{act.description.substring(0, 119)}...</>
                        ) : (
                          act.description
                        )
                      }
                    />
                    <Panel.Segment
                      metric
                      label="You raised:"
                      value={
                        <>
                          <span>
                            <span className="dollar-sign">$</span>
                            {util.toDollarStr(act.donation.amount)}
                          </span>
                        </>
                      }
                    />
                  </PanelList.Item>
                ))}
                <Spin />
              </PanelList>
            )}
          </div>
        </div>
      )}
      <ErrorBar error={error} />
    </>
  );
}

export default Pledge;
