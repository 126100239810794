import React, { useState } from "react";
import Lightbox from "react-image-lightbox";
import Dashboard from "./assets/images/dashboard.png";
import Act from "./assets/images/act.png";
import Ripple from "./assets/images/ripple.png";
import DashboardLight from "./assets/images/dashboard-light.png";
import ActLight from "./assets/images/act-light.png";
import RippleLight from "./assets/images/ripple-light.png";
import "react-image-lightbox/style.css";

function Screenshots({ light, onCloseLightbox }) {
  const [photoIndex, setPhotoIndex] = useState(0);
  const images = light
    ? [DashboardLight, ActLight, RippleLight]
    : [Dashboard, Act, Ripple];

  return (
    <Lightbox
      mainSrc={images[photoIndex]}
      nextSrc={images[(photoIndex + 1) % images.length]}
      prevSrc={images[(photoIndex + images.length - 1) % images.length]}
      onCloseRequest={onCloseLightbox}
      onMovePrevRequest={() =>
        setPhotoIndex((photoIndex + images.length - 1) % images.length)
      }
      onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % images.length)}
    />
  );
}

export default Screenshots;
