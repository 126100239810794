import React from "react";
import "../assets/css/SelectCharityConfirm.css";
import { useHistory } from "react-router-dom";
import { Button, Grid, Card } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRibbon } from "@fortawesome/free-solid-svg-icons";
import util from "../util";

function SelectCharityConfirm({ act }) {
  const history = useHistory();

  function start() {
    history.push(`/dashboard`);
  }

  return (
    <div id="select-charity-confirm" className="view with-nav long">
      <div className="inner">
        <h2>Well done!</h2>
        <p>
          {act.actee.firstName}&apos;s donation of{" "}
          <span className="buzzword">
            ${util.toDollarStr(act.donation.amount)}
          </span>{" "}
          will be going to the following charity:
        </p>
        <Grid container justify="center">
          <Card>
            {act.donation.charity.logoUrl ? (
              <img src={act.donation.charity.logoUrl} alt="" />
            ) : (
              <FontAwesomeIcon icon={faRibbon} />
            )}
            <p>{act.donation.charity.name}</p>
          </Card>
        </Grid>
        <p>
          We&apos;ll let you know when {act.actee.firstName} pays forward your{" "}
          <span className="buzzword">act of kindness</span>. In the meantime,
          you can track the positive impact you&apos;ve made on the world from
          your dashboard.
        </p>
        <Button variant="contained" color="primary" onClick={start}>
          Go to My Dashboard
        </Button>
      </div>
    </div>
  );
}

export default SelectCharityConfirm;
