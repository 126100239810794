import React from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import "../assets/css/JoinFundraiserRegister.css";
import { Button } from "@material-ui/core";
import RegisterForm from "../RegisterForm";
import util from "../util";

function JoinFundraiserRegister({ fundraiser }) {
  const { url } = useRouteMatch();
  const history = useHistory();

  async function onUserCreated() {
    util.scrollTop();
    history.replace(`/get-started`);
  }

  return (
    <div id="invite-register" className="view with-nav">
      <div className="inner">
        <p>First, let&apos;s set up your account.</p>
        <p id="login-link" className="subtext">
          Already have an account? Log in{" "}
          <Button
            className="linkish"
            onClick={() => history.replace(`${util.getParentPath(url)}/login`)}
          >
            here
          </Button>
          .
        </p>

        <RegisterForm
          preUser={{ fundraiserId: fundraiser.id }}
          onUserCreated={onUserCreated}
        />
      </div>
    </div>
  );
}

export default JoinFundraiserRegister;
