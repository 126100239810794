import React from "react";
import { Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";

function ErrorPage() {
  const history = useHistory();

  function goHome() {
    history.goBack();
  }

  return (
    <div className="view">
      <h1>Oops!</h1>
      <p>
        Looks like something went wrong. We&apos;re on it! Please try again
        later.
      </p>
      <Button onClick={goHome}>Back</Button>
    </div>
  );
}

export default ErrorPage;
