import React, { useState, useContext } from "react";
import { useParams } from "react-router-dom";
import "../assets/css/Unsubscribe.css";
import { AppContext } from "../App";
import { Button } from "@material-ui/core";
import Spin from "../Spin";
import util from "../util";

function OAuth() {
  const appContext = useContext(AppContext);
  const { uid } = useParams();
  const [loading, setLoading] = useState(false);
  const [done, setDone] = useState(false);

  async function unsub() {
    setLoading(true);

    try {
      await appContext.api.unsubscribe(uid);
    } catch (err) {
      util.error(err, `Failed to unsubscribe user: ${uid}`);
    }

    setLoading(false);
    setDone(true);
  }

  return (
    <div id="unsubscribe" className="view">
      <div className="inner">
        <p className="large">Unsubscribe from all Rippl.me emails?</p>
        <p className="small">
          You will no longer receive email alerts about activity on Rippl.me.
        </p>

        {loading ? (
          <Spin />
        ) : done ? (
          <p>Done.</p>
        ) : (
          <Button variant="contained" color="primary" onClick={unsub}>
            Yes, Unsubscribe Me!
          </Button>
        )}
      </div>
    </div>
  );
}

export default OAuth;
