import React, { useState, useContext } from "react";
import { useRouteMatch, useHistory } from "react-router-dom";
import "../assets/css/PifDonation.css";
import { UserContext } from "../App";
import { Grid, Button } from "@material-ui/core";
import util from "../util";
import _ from "lodash";

function PifDonation({ act, onUpdateDonation, onUpdateBilling }) {
  const userContext = useContext(UserContext);
  const [more, setMore] = useState(
    act.donation && [5, 10, 20].indexOf(act.donation) < 0
  );
  const { url } = useRouteMatch();
  const history = useHistory();
  const firstName = _.get(act, "actor.firstName");

  function toggleMore() {
    setMore((prev) => !prev);
  }

  function handleChange(e) {
    const val = parseInt(e.target.value);
    onUpdateDonation(isNaN(val) ? undefined : val);
    onUpdateBilling({
      ...act.billing,
      fee: calcFee(val),
    });
  }

  function handleSelect(amt) {
    onUpdateDonation(amt);
    onUpdateBilling({
      ...act.billing,
      fee: calcFee(amt),
    });
  }

  function calcFee(amt) {
    return isNaN(amt) || amt <= 20 ? 100 : amt * 5;
  }

  function handleSubmit() {
    if (!act.donation) return;
    const base = util.getParentPath(url);
    history.push(userContext.user ? `${base}/billing` : `${base}/account`);
  }

  return (
    <div id="pif-donation" className="view with-nav">
      <div className="inner">
        <h2>How much will you donate to {firstName}&apos;s charity?</h2>

        <form
          className="full-width"
          noValidate
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <fieldset>
            <Grid container justify="center">
              <Button
                type="button"
                className={act.donation === 5 ? "selected" : ""}
                variant="outlined"
                onClick={() => handleSelect(5)}
              >
                $5
              </Button>
              <Button
                type="button"
                className={act.donation === 10 ? "selected" : ""}
                variant="outlined"
                onClick={() => handleSelect(10)}
              >
                $10
              </Button>
              <Button
                type="button"
                className={act.donation === 20 ? "selected" : ""}
                variant="outlined"
                onClick={() => handleSelect(20)}
              >
                $20
              </Button>
            </Grid>
            <Button type="button" id="more" onClick={toggleMore}>
              other amount...
            </Button>
            {more && (
              <div id="more">
                <div className="input-group">
                  <label>Donation Amount ($)</label>
                  <input
                    type="text"
                    name="donation"
                    value={act.donation || ""}
                    onChange={handleChange}
                  />
                </div>
              </div>
            )}
            {act.fundraiser ? (
              <p className="small">
                {firstName} is raising money for{" "}
                <a
                  href={act.fundraiser.charity.websiteUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {act.fundraiser.charity.name}
                </a>
                .
              </p>
            ) : (
              <p className="small">
                {firstName} will select a charity once you&apos;ve made your
                donation.
              </p>
            )}
          </fieldset>
          <div className="button-row">
            <Button type="button" onClick={() => history.goBack()}>
              Back
            </Button>

            <Button
              type="button"
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              disabled={!act.donation}
            >
              Next
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default PifDonation;
