import React, { useState, useContext } from "react";
import "../assets/css/JoinFundraiserLand.css";
import { AppContext, UserContext } from "../App";
import Panel from "../Panel";
import Screenshots from "../Screenshots";
import Loading from "./Loading";
import BottomLinks from "../BottomLinks";
import ErrorBar from "../ErrorBar";
import util from "../util";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { Button } from "@material-ui/core";
import { faHeart } from "@fortawesome/free-regular-svg-icons";
import { faShare } from "@fortawesome/free-solid-svg-icons";
import { faRibbon } from "@fortawesome/free-solid-svg-icons";
import _ from "lodash";

function JoinFundraiserLand({ fundraiser }) {
  const appContext = useContext(AppContext);
  const api = appContext.api;
  const userContext = useContext(UserContext);
  const history = useHistory();
  const location = useLocation();
  const { url } = useRouteMatch();
  const [showScreenshots, setShowScreenshots] = useState(false);
  const light = _.get(appContext, "ui.theme") === "light";
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  function openLightbox() {
    setShowScreenshots(true);
    util.event("Lightbox", "Open", "Screenshots");
  }

  function closeLightbox() {
    setShowScreenshots(false);
    util.event("Lightbox", "Close", "Screenshots");
  }

  async function start() {
    if (userContext.user) {
      setLoading(true);
      try {
        await api.joinFundraiser(fundraiser.id, userContext.user.id);
        util.scrollTop();
        history.replace(`/ripple`);
      } catch (err) {
        if (err.status === 409) {
          util.scrollTop();
          history.replace(`/ripple`);
        } else {
          setError(true);
          setLoading(false);
          util.error(err, `Failed to join fundraiser: ${fundraiser.id}`);
        }
      }
    } else {
      util.scrollTop();
      history.push(_.get(location, "state.goto") || `${url}/register`);
    }
  }

  return loading ? (
    <Loading />
  ) : (
    <div id="join-fundraiser-land" className="view long with-nav">
      <div className="inner">
        <h2>How do you change the world?</h2>
        <p>
          One <span className="buzzword">act of kindness</span> at a time.
        </p>
        <p>
          You&apos;ve been invited to join a pay-it-forward fundraiser for{" "}
          {fundraiser.charity.name}.
        </p>
        {fundraiser.charity.logoUrl && (
          <a
            href={fundraiser.charity.websiteUrl}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              className="charity-logo"
              src={fundraiser.charity.logoUrl}
              alt=""
            />
          </a>
        )}

        <p>Here&apos;s how it works:</p>

        <Panel align="left" icon={faHeart} withArrow filled>
          <Panel.Body>
            <p>
              You perform an act of kindness for someone and ask them to pay it
              forward if they can.
            </p>
            <p className="small supporting">
              An act of kindness could be anything. Think about what unique
              gifts you have that could be helpful to someone else. Here are{" "}
              <Button
                className="linkish"
                onClick={() => history.push("/faq#UWFHSUCAQ")}
              >
                some ideas
              </Button>
              .
            </p>
          </Panel.Body>
        </Panel>

        <Panel align="right" icon={faRibbon} withArrow filled>
          <Panel.Body>
            <p>
              To thank you, they make a donation to {fundraiser.charity.name}{" "}
              and pledge to perform one or more acts of kindness for someone
              else.
            </p>
            <p className="small supporting">
              You can track their progress in paying it forward and the ripple
              effect you&apos;ve created from your{" "}
              <Button className="linkish" onClick={openLightbox}>
                dashboard
              </Button>
              .
            </p>
          </Panel.Body>
        </Panel>

        <Panel align="left" icon={faShare} filled>
          <Panel.Body>
            <p>
              They complete their pledged acts of kindness and ask those
              they&apos;ve helped to pay it forward to continue the chain.
            </p>
            <p className="small supporting">
              Starting with a single act of kindness, if each person paid it
              forward to two other people and donated just $10, after 15 steps
              in the chain the result would be 65K acts of kindness and $655K
              raised for {fundraiser.charity.name}. That&apos;s the power of the
              ripple effect!
            </p>
          </Panel.Body>
        </Panel>

        {showScreenshots && (
          <Screenshots light={light} onCloseLightbox={closeLightbox} />
        )}

        <p>
          Start a chain reaction of kindness and help {fundraiser.charity.name}{" "}
          make the world a better place.
        </p>
        <Button variant="contained" color="primary" onClick={start}>
          Join Now
        </Button>
        <BottomLinks returnPath={url} />
        <ErrorBar error={error} />
      </div>
    </div>
  );
}

export default JoinFundraiserLand;
