import React from "react";
import Spin from "../Spin";
import "../assets/css/Loading.css";

function Loading() {
  return (
    <div id="loading" className="view">
      <Spin />
    </div>
  );
}

export default Loading;
