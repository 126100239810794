import React from "react";
import "../assets/css/SelectCharityLand.css";
import { useRouteMatch, useHistory } from "react-router-dom";
import { Button } from "@material-ui/core";
import Panel from "../Panel";
import util from "../util";

function SelectCharityLand({ act }) {
  const { url } = useRouteMatch();
  const history = useHistory();

  function start() {
    util.scrollTop();
    history.push(`${url}/search`);
  }

  return (
    <div id="select-charity-land" className="view with-nav long">
      <div className="inner">
        <p>Good news!</p>
        <p>
          {act.actee.firstName} has pledged to pay forward your{" "}
          <span className="buzzword">act of kindness</span> to{" "}
          <span className="buzzword">{act.pledge}</span> other{" "}
          {act.pledge > 1 ? "people" : "person"} and has made a{" "}
          <span className="buzzword">
            ${util.toDollarStr(act.donation.amount)}
          </span>{" "}
          donation on your behalf.
        </p>
        <p>
          Here&apos;s how {act.actee.firstName} said you helped{" "}
          {util.pronoun(act.actee, "him")}:
        </p>
        <Panel filled>
          <Panel.Body>
            <p>{act.description}</p>
          </Panel.Body>
        </Panel>
        <p>
          The next step is for you to select a charity for{" "}
          {`${act.actee.firstName}'s`} donation.
        </p>
        <Button variant="contained" color="primary" onClick={start}>
          Select a Charity
        </Button>
      </div>
    </div>
  );
}

export default SelectCharityLand;
