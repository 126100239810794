import React, { useContext } from "react";
import { AppContext } from "./App";
import _ from "lodash";

function RippleImage({ impactGraph }) {
  const appContext = useContext(AppContext);
  const light = _.get(appContext, "ui.theme") === "light";
  const timeScale = Math.min(
    1.25,
    Math.max(
      0.5,
      30 /
        ((_.maxBy(impactGraph, "started").started -
          _.minBy(impactGraph, "started").started) /
          86400000)
    )
  );

  return (
    <div id="ripple-img">
      <svg viewBox="-125,-50,250,100">
        <defs>
          <radialGradient
            id="grad1"
            cx="50%"
            cy="50%"
            r="50%"
            fx="50%"
            fy="50%"
          >
            <stop
              offset="0%"
              style={{
                stopColor: "rgba(0,0,0,0.2)",
                stopOpacity: 1,
              }}
            />
            <stop
              offset="100%"
              style={{
                stopColor: "rgb(0,0,0)",
                stopOpacity: 0,
              }}
            />
          </radialGradient>
          <linearGradient id="grad2" gradientTransform="rotate(90)">
            <stop
              offset="0%"
              style={{
                stopColor: "#295a8c",
                stopOpacity: 1,
              }}
            />
            <stop
              offset="60%"
              style={{
                stopColor: "#295a8c",
                stopOpacity: 0,
              }}
            />
          </linearGradient>
        </defs>
        <g transform={`scale(1,0.15) translate(0,290)`}>
          <ellipse
            cx="0"
            cy="0"
            rx={impactGraph.length * 20}
            ry="40"
            fill="url(#grad1)"
          />
        </g>
        <g transform={`scale(${timeScale},${timeScale / 3})`}>
          {impactGraph.map((depth, i) => {
            const prev = i > 0 && impactGraph[i - 1];
            const deltaT = depth.started - prev.started;
            depth.radius = prev.radius
              ? prev.radius +
                5 +
                prev.strokeWidth +
                ((deltaT || 0) / (14 * 24 * 60 * 60 * 1000)) * 10
              : 10;
            depth.strokeWidth = 2 * depth.acts;

            const circle = (
              <circle
                key={`${
                  depth.acts + depth.donations + depth.started.valueOf()
                }`}
                fill="transparent"
                stroke={`rgba(${light ? "81,149,204," : "255,255,255,"}${
                  0.3 +
                  Math.min(0.5, (depth.donations / depth.acts / 2000) * 0.2)
                })`}
                strokeWidth={depth.strokeWidth}
                r={depth.radius}
                x="0"
                y="0"
                cx="0"
                cy="0"
              />
            );
            return circle;
          })}
        </g>
        {!light && (
          <g>
            <rect
              x="-125"
              y="-50"
              width="100%"
              height="100%"
              fill="url(#grad2)"
            />
          </g>
        )}
      </svg>
    </div>
  );
}

export default RippleImage;
