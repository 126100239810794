import React, { useContext, useEffect, useState } from "react";
import "./assets/css/Fundraiser.css";
import Panel from "./Panel";
import RotatePhoneIcon from "./RotatePhoneIcon";
import Spin from "./Spin";
import util from "./util";
import { AppContext } from "./App";
import _ from "lodash";
import {
  LineChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Line,
} from "recharts";
import moment from "moment";
import classNames from "classnames";

function FundraiserChart({
  fundraiser,
  dataSrc,
  heading,
  dataKey,
  dataLabel,
  currency,
  testData,
  helpText,
}) {
  const fid = fundraiser.id;
  const appContext = useContext(AppContext);
  const light = _.get(appContext, "ui.theme") === "light";
  const launched =
    fundraiser.launchDate && moment(fundraiser.launchDate).isBefore(moment());
  const [summary, setSummary] = useState([]);
  const [loading, setLoading] = useState(launched);

  useEffect(() => {
    if (!launched) return;
    (async () => {
      try {
        const data = testData || (await dataSrc(fid));
        if (!data.length) {
          setLoading(false);
          return;
        }
        const start = moment(fundraiser.launchDate);
        const end = moment(_.maxBy(data, "day").day);
        const range = end.diff(start, "days") + 1;
        const preLaunchData = _.remove(data, (val) =>
          moment(val.day).isBefore(moment(fundraiser.launchDate))
        );
        const preLaunchSum = preLaunchData.length
          ? _.sumBy(preLaunchData, dataKey)
          : 0;
        const summary = [];
        let sum = preLaunchSum;

        for (let i = 0, j = 0; i < range; i++) {
          const day = moment(start.valueOf() + 24 * 60 * 60 * 1000 * i);
          if (moment(data[j].day).isSame(day)) {
            sum += data[j][dataKey];
            j++;
          }
          summary[i] = {
            day: `Day ${i + 1}`,
            [dataLabel]: currency ? sum / 100 : sum,
          };
        }
        summary.unshift({
          day: "Pre-Launch",
          [dataLabel]: currency ? preLaunchSum / 100 : preLaunchSum,
        });
        setSummary(summary);
        setLoading(false);
      } catch (err) {
        util.error(err, "Failed to get user fundraisers");
      }
    })();
  }, [
    fid,
    fundraiser.launchDate,
    launched,
    dataSrc,
    dataKey,
    dataLabel,
    currency,
    testData,
  ]);

  return (
    <>
      <Panel
        heading={heading}
        loading={loading}
        className={classNames({ launched: launched && summary.length })}
        helpText={helpText}
      >
        {launched ? (
          summary.length ? (
            <div className="chart">
              <LineChart
                width={530}
                height={250}
                data={summary}
                margin={{ top: 5, right: 15, left: 0, bottom: 5 }}
              >
                <CartesianGrid
                  stroke={light ? "rgba(0,0,0,0.25)" : "rgba(255,255,255,0.25)"}
                  strokeDasharray="5 5"
                  interval={0}
                />
                <XAxis
                  dataKey="day"
                  stroke={light ? "#888" : "rgba(255,255,255,0.75)"}
                  tickLine={false}
                />
                <YAxis
                  stroke={light ? "#888" : "rgba(255,255,255,0.75)"}
                  interval="preserveStartEnd"
                  allowDecimals={false}
                  tickLine={false}
                  tickFormatter={currency ? (tick) => `$${tick}` : null}
                />
                <Tooltip
                  wrapperStyle={{ width: 150 }}
                  contentStyle={{
                    backgroundColor: light
                      ? "rgba(240,240,240,0.8)"
                      : "rgba(75,115,155,0.8)",
                    borderColor: light ? "#ddd" : "#295a8c",
                    textAlign: "left",
                  }}
                  formatter={
                    currency ? (val) => `$${util.toDollarStr(val * 100)}` : null
                  }
                />
                <Line
                  type="monotoneX"
                  dataKey={dataLabel}
                  stroke="#37c5c5"
                  strokeWidth={2}
                  dot={false}
                />
              </LineChart>
            </div>
          ) : (
            <div className="no-chart not-launched">
              <p>No data yet</p>
            </div>
          )
        ) : (
          <div className="no-chart not-launched">
            <p>Not launched yet</p>
          </div>
        )}
        <div className="no-chart rotate">
          <RotatePhoneIcon />
          <p>Rotate device to see charts</p>
        </div>
        <Spin />
      </Panel>
    </>
  );
}

export default FundraiserChart;
