import React, { useContext, useState, useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";
import "../assets/css/EditProfile.css";
import Panel from "../Panel";
import { AppContext, UserContext } from "../App";
import Spin from "../Spin";
import FloatingInput from "../FloatingInput";
import util from "../util";
import {
  Button,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@material-ui/core";
import _ from "lodash";

function EditProfile() {
  const api = useContext(AppContext).api;
  const userContext = useContext(UserContext);
  const [user, setUser] = useState({ ...userContext.user });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [done, setDone] = useState(false);
  const [invalidFields, setInvalidFields] = useState([]);
  const history = useHistory();
  const handleValidate = useCallback(util.handleValidate(setInvalidFields), []);

  useEffect(() => {
    if (done) history.push("/profile");
  });

  function handleChange(field, value) {
    setUser((prev) => ({ ...prev, [field]: value }));
  }

  function handleGenderChange(elem, value) {
    const fieldName = _.get(elem, "target.name");
    setUser((prev) => ({ ...prev, [fieldName]: value }));
  }

  async function handleSubmit(e) {
    e.preventDefault();
    if (invalidFields.length) return;
    setError(false);
    setLoading(true);

    try {
      await api.updateUser(user);
      userContext.setUser(user);
      setDone(true);
    } catch (err) {
      setError(true);
      util.error(err, `Failed to update user: ${user.id}`);
    }
    setLoading(false);
  }

  return (
    <div id="edit-profile" className="view with-nav">
      <form
        className={loading ? "loading" : ""}
        noValidate
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <Panel heading="Edit Profile" textAlign="left">
          <Panel.Body>
            <fieldset>
              <div className="input-group">
                <FloatingInput
                  name="firstName"
                  label="First Name"
                  value={user.firstName}
                  onChange={handleChange}
                  required
                  maxLength={255}
                  onValidate={handleValidate}
                />
              </div>
              <div className="input-group">
                <FloatingInput
                  name="email"
                  label="Email"
                  type="email"
                  value={user.email}
                  onChange={handleChange}
                  required
                  maxLength={255}
                  validate='^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$'
                  onValidate={handleValidate}
                  autoComplete="username"
                />
              </div>
              <div className="input-group">
                <FormControl component="fieldset">
                  <FormLabel component="legend">Refer to me as</FormLabel>
                  <RadioGroup
                    aria-label="gender"
                    name="gender"
                    value={`${user.gender}`}
                    onChange={handleGenderChange}
                  >
                    <FormControlLabel
                      value="Male"
                      control={<Radio />}
                      label="He"
                    />
                    <FormControlLabel
                      value="Female"
                      control={<Radio />}
                      label="She"
                    />
                    <FormControlLabel
                      value="Other"
                      control={<Radio />}
                      label="They"
                    />
                  </RadioGroup>
                </FormControl>{" "}
              </div>
            </fieldset>
            {error && (
              <div className="form-msg">
                Oops! Looks like something went wrong. We&apos;re on it. Please
                try again later.
              </div>
            )}
            <Spin />
          </Panel.Body>
        </Panel>
        <Button variant="contained" color="primary" onClick={handleSubmit}>
          Save
        </Button>
      </form>
    </div>
  );
}

export default EditProfile;
