import React, { useEffect, useContext, useState } from "react";
import "../assets/css/Home.css";
import { AppContext, UserContext } from "../App";
import Panel from "../Panel";
import Screenshots from "../Screenshots";
import Pif from "../assets/images/pif.svg";
import { useHistory, useLocation } from "react-router-dom";
import { Button } from "@material-ui/core";
import { faHeart } from "@fortawesome/free-regular-svg-icons";
import { faShare } from "@fortawesome/free-solid-svg-icons";
import { faRibbon } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import util from "../util";
import _ from "lodash";
import { Element, scroller } from "react-scroll";

function Home() {
  const appContext = useContext(AppContext);
  const userContext = useContext(UserContext);
  const history = useHistory();
  const location = useLocation();
  const [showScreenshots, setShowScreenshots] = useState(false);
  const light = _.get(appContext, "ui.theme") === "light";

  useEffect(() => {
    if (userContext.user) history.push("/dashboard");
  });

  function go() {
    util.scrollTop();
    history.push(_.get(location, "state.goto") || "/register");
  }

  function scrollDown() {
    scroller.scrollTo("how", {
      duration: 750,
      delay: 0,
      smooth: "easeInOutCubic",
      offset: -100,
    });
  }

  function openLightbox() {
    setShowScreenshots(true);
    util.event("Lightbox", "Open", "Screenshots");
  }

  function closeLightbox() {
    setShowScreenshots(false);
    util.event("Lightbox", "Close", "Screenshots");
  }

  return (
    <div id="home" className="view long">
      <div id="pif">
        <img src={Pif} alt="" />
      </div>
      <div className="hero">
        <div>
          <h1>A single act of kindness can change the world.</h1>
          <div id="see">
            <Button onClick={() => scrollDown()}>
              See how
              <FontAwesomeIcon icon={faChevronDown} />
            </Button>
          </div>
        </div>
      </div>

      <div className="inner">
        <Element name="how">
          <div id="how-it-works">
            <h2>How do you change the world?</h2>
            <p>
              One <span className="buzzword">act of kindness</span> at a time.
            </p>
            <p className="smallish">
              Rippl.me combines paying forward acts of kindness with charity
              donations to create a ripple effect that grows exponentially into
              a tsunami of good.
            </p>
            <p className="smallish">Here&apos;s how it works:</p>

            <Panel align="left" icon={faHeart} withArrow filled>
              <Panel.Body>
                <p>
                  You perform an act of kindness for someone and ask them to pay
                  it forward if they can.
                </p>
                <p className="small supporting">
                  An act of kindness could be anything. Think about what unique
                  gifts you have that could be helpful to someone else. Here are{" "}
                  <Button
                    className="linkish"
                    onClick={() => history.push("/faq#UWFHSUCAQ")}
                  >
                    some ideas
                  </Button>
                  .
                </p>
              </Panel.Body>
            </Panel>

            <Panel align="right" icon={faRibbon} withArrow filled>
              <Panel.Body>
                <p>
                  To thank you, they make a charity donation and pledge to
                  perform one or more acts of kindness for someone else.
                </p>
                <p className="small supporting">
                  You get to select the charity for their donation, and you can
                  track their progress in paying it forward and the ripple
                  effect you&apos;ve created from your{" "}
                  <Button className="linkish" onClick={openLightbox}>
                    dashboard
                  </Button>
                  .
                </p>
              </Panel.Body>
            </Panel>

            <Panel align="left" icon={faShare} filled>
              <Panel.Body>
                <p>
                  They complete their pledged acts of kindness and ask those
                  they&apos;ve helped to pay it forward to continue the chain.
                </p>
                <p className="small supporting">
                  Starting with a single act of kindness, if each person paid it
                  forward to two other people and donated just $10 to charity,
                  after 15 steps in the chain the result would be 65K acts of
                  kindness and $655K raised for charity. That&apos;s the power
                  of the ripple effect!
                </p>
              </Panel.Body>
            </Panel>

            {showScreenshots && (
              <Screenshots light={light} onCloseLightbox={closeLightbox} />
            )}

            <p>Start a chain reaction of kindness!</p>
            <Button variant="contained" color="primary" onClick={go}>
              Join Now
            </Button>
          </div>
        </Element>
      </div>
    </div>
  );
}

export default Home;
