import React, { useState, useEffect, useContext } from "react";
import { Switch, Route, useRouteMatch, useParams } from "react-router-dom";
import { AppContext } from "../App";
import View from "../View";
import NavBar from "../NavBar";
import NotFound from "./NotFound";
import ErrorPage from "./Error";
import JoinFundraiserLand from "./JoinFundraiserLand";
import JoinFundraiserRegister from "./JoinFundraiserRegister";
import JoinFundraiserLogin from "./JoinFundraiserLogin";
import util from "../util";

function JoinFundraiserContainer(props) {
  const api = useContext(AppContext).api;
  const parentLoading = props.loading;
  const { path } = useRouteMatch();
  const { fid } = useParams();
  const [fundraiser, setFundraiser] = useState({});
  const [loading, setLoading] = useState(true);
  const [notFound, setNotFound] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (parentLoading) return;
    (async () => {
      try {
        setFundraiser(await api.getFundraiser(fid));
      } catch (err) {
        if (err.status === 404) {
          setNotFound(true);
        } else {
          setError(true);
          util.error(err, `Failed to get fundraiser: ${fid}`);
        }
      }
      setLoading(false);
    })();
  }, [fid, parentLoading, api]);

  return (
    <>
      {notFound ? (
        <NotFound />
      ) : error ? (
        <ErrorPage />
      ) : (
        <Switch>
          <Route exact path={path}>
            <View loading={loading}>
              <NavBar />
              <JoinFundraiserLand fundraiser={fundraiser} />
            </View>
          </Route>
          <Route path={`${path}/register`}>
            <View loading={loading}>
              <JoinFundraiserRegister fundraiser={fundraiser} />
            </View>
          </Route>
          <Route path={`${path}/login`}>
            <View loading={loading}>
              <JoinFundraiserLogin fundraiser={fundraiser} />
            </View>
          </Route>
          <Route path="*">
            <View>
              <NotFound />
            </View>
          </Route>
        </Switch>
      )}
    </>
  );
}

export default JoinFundraiserContainer;
