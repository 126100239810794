import React from "react";
import { Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";

function NotFound() {
  const history = useHistory();

  function goHome() {
    history.goBack();
  }

  return (
    <div className="view">
      <h1>Oops!</h1>
      <p>
        Looks like you&apos;re looking for something that doesn&apos;t exist.
      </p>
      <Button onClick={goHome}>Back</Button>
    </div>
  );
}

export default NotFound;
