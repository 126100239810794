import React, { useState, useCallback, useContext } from "react";
import { useLocation } from "react-router-dom";
import { AppContext } from "../App";
import FloatingInput from "../FloatingInput";
import util from "../util";
import Spin from "../Spin";
import { Button } from "@material-ui/core";
import _ from "lodash";

function ForgotPassword() {
  const appContext = useContext(AppContext);
  const [login, setLogin] = useState({});
  const [loading, setLoading] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const [error, setError] = useState(false);
  const [done, setDone] = useState(false);
  const [invalidFields, setInvalidFields] = useState([]);
  const handleValidate = useCallback(util.handleValidate(setInvalidFields), []);
  const location = useLocation();
  const change = _.get(location, "state.change");

  function handleChange(field, value) {
    setLogin((prev) => ({ ...prev, [field]: value }));
  }

  async function handleSubmit(e) {
    e.preventDefault();
    if (invalidFields.length) return;
    setDone(false);
    setError(false);
    setNotFound(false);
    setLoading(true);

    try {
      await appContext.api.requestPasswordReset(login.email);
      setDone(true);
    } catch (err) {
      if (err.status === 404) {
        setNotFound(true);
      } else {
        setError(true);
        util.error(
          err,
          `Failed to request password reset for email: ${login.email}`
        );
      }
    }
    setLoading(false);
  }

  return (
    <div id="forgot-password" className="view">
      <div className="inner">
        {done ? (
          <p>All set! Check your email.</p>
        ) : (
          <>
            {change ? (
              <h2>Need to change your password?</h2>
            ) : (
              <h2>Forgot your password?</h2>
            )}
            <p>No problem. We&apos;ll send you a link to reset it.</p>

            <form
              className={loading ? "loading" : ""}
              noValidate
              onSubmit={(e) => {
                e.preventDefault();
              }}
            >
              <fieldset>
                <div className="input-group">
                  <FloatingInput
                    name="email"
                    label="Email"
                    type="email"
                    value={login.email}
                    onChange={handleChange}
                    required
                    maxLength={255}
                    validate='^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$'
                    onValidate={handleValidate}
                    autoComplete="username"
                  />
                </div>
                <Spin />
              </fieldset>
              {notFound && (
                <div className="form-msg">
                  Oops! Looks like we don&apos;t have an account for that email
                  address.
                </div>
              )}
              {error && (
                <div className="form-msg">
                  Oops! Looks like something went wrong. We&apos;re on it.
                  Please try again later.
                </div>
              )}
              <Button
                type="button"
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                disabled={loading || invalidFields.length > 0}
              >
                Submit
              </Button>
            </form>
          </>
        )}
      </div>
    </div>
  );
}

export default ForgotPassword;
