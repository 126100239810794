import React from "react";
import { useRouteMatch, useHistory } from "react-router-dom";
import { Grid, Button } from "@material-ui/core";
import util from "../util";

function PifPledge({ act, onUpdatePledge }) {
  const { url } = useRouteMatch();
  const history = useHistory();

  function handleSubmit() {
    history.push(`${util.getParentPath(url)}/donation`);
  }

  return (
    <div id="pif-pledge" className="view with-nav">
      <div className="inner">
        <h2>How many times will you pay it forward?</h2>

        <form
          className="full-width"
          noValidate
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <fieldset>
            <Grid container justify="center">
              <Button
                className={act.pledge === 1 ? "selected" : ""}
                variant="outlined"
                onClick={() => onUpdatePledge(1)}
              >
                1
              </Button>
              <Button
                className={act.pledge === 2 ? "selected" : ""}
                variant="outlined"
                onClick={() => onUpdatePledge(2)}
              >
                2
              </Button>
              <Button
                className={act.pledge === 3 ? "selected" : ""}
                variant="outlined"
                onClick={() => onUpdatePledge(3)}
              >
                3
              </Button>
            </Grid>
          </fieldset>
          <p className="small">
            This is how many <span className="buzzword">acts of kindness</span>{" "}
            you&apos;ll perform for someone else.
          </p>

          <div className="button-row">
            <Button type="button" onClick={() => history.goBack()}>
              Back
            </Button>
            <Button
              type="button"
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              disabled={!act.pledge}
            >
              Next
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default PifPledge;
