import React, { useState, useEffect } from "react";
import InputMask from "react-input-mask";
import "./assets/css/FloatingInput.css";
import { TextareaAutosize } from "@material-ui/core";
import util from "./util";
import _ from "lodash";

function FloatingInput({
  name,
  label,
  type,
  value,
  maxLength,
  required,
  mask,
  securityMask,
  validate,
  onChange,
  onValidate,
  errorMessage,
  autoComplete,
  autoFocus,
}) {
  const [dirty, setDirty] = useState(false);
  const [blurred, setBlurred] = useState(false);
  const [floating, setFloating] = useState(value);
  const [focused, setFocused] = useState(false);
  const [masked, setMasked] = useState(false);
  const missing = required && dirty && !value;
  const invalid =
    blurred &&
    dirty &&
    value &&
    validate &&
    !util.isValidValue(validate, value);
  const errMsg = errorMessage || (_.isFunction(validate) && validate(value));
  const maskedVal = mask && mask.replace(/[a9*]/g, "_");
  const displayValue = value
    ? (securityMask && !focused && !invalid && securityMask(value)) || value
    : "";

  useEffect(() => {
    if (validate) {
      onValidate(name, util.isValidValue(validate, value));
    } else if (required) {
      onValidate(name, value);
    }

    return () => {
      if (validate || required) onValidate(name, true);
    };
  }, [validate, name, value, required, onValidate]);

  function handleFocus() {
    setFloating(true);
    setFocused(true);
  }

  function handleBlur(e) {
    const val = e.target.value;
    setFloating(val !== "");
    setBlurred(val !== "");
    setFocused(false);
    onChange(name, _.trim(val));
  }

  function handleChange(e) {
    const val = e.target.value;
    if (mask && !dirty && (val === maskedVal || (masked && val === ""))) {
      setMasked(val === maskedVal);
      return;
    }
    setDirty(true);
    setMasked(val === maskedVal);
    onChange(name, val);
  }

  return (
    <div
      className={`input floating ${floating ? "float" : ""} ${
        missing || invalid ? "has-error has-danger" : ""
      }`}
    >
      <label htmlFor="textfield">{label}</label>
      {type === "multiline" ? (
        <TextareaAutosize
          value={value}
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          autoComplete={autoComplete}
          rowsMax={4}
          autoFocus={autoFocus}
        />
      ) : mask ? (
        <InputMask
          id={name}
          name={name}
          type={type || "text"}
          required={required}
          value={displayValue}
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          mask={focused ? mask : null}
          alwaysShowMask={false}
          autoComplete={autoComplete}
        />
      ) : (
        <input
          id={name}
          name={name}
          type={type || "text"}
          required={required}
          maxLength={maxLength}
          value={displayValue}
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          autoComplete={autoComplete}
        />
      )}
      {missing && (
        <div className="help-block with-errors">
          {errMsg || `Please provide ${label.toLowerCase()}`}
        </div>
      )}
      {invalid && (
        <div className="help-block with-errors">
          {errMsg || `Please provide a valid ${label.toLowerCase()}`}
        </div>
      )}
    </div>
  );
}

export default FloatingInput;
