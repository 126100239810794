import React, { useState } from "react";
import { Tooltip, ClickAwayListener } from "@material-ui/core";

function ClickableTooltip(props) {
  const [open, setOpen] = useState(false);

  function toggleOpen() {
    setOpen((prev) => !prev);
  }

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <Tooltip
        {...props}
        open={open}
        disableFocusListener
        disableHoverListener
        disableTouchListener
      >
        <span className="click-target" onClick={() => toggleOpen()}>
          {props.children}
        </span>
      </Tooltip>
    </ClickAwayListener>
  );
}

export default ClickableTooltip;
