import React from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { Button } from "@material-ui/core";
import "../assets/css/Acts.css";
import PanelList from "../PanelList";
import Panel from "../Panel";
import RotatePhoneIcon from "../RotatePhoneIcon";
import util from "../util";
import classNames from "classnames";

function Acts({ acts }) {
  const history = useHistory();
  const { url } = useRouteMatch();

  function viewAct(aid) {
    util.scrollTop();
    history.push(`${url}/${aid}`);
  }

  return (
    <div
      id="acts"
      className={classNames("view", "with-nav", {
        long: acts.length,
        wide: acts.length,
      })}
    >
      <div className="inner">
        {!acts.length ? (
          <p>
            Looks like you haven&apos;t performed any{" "}
            <span className="buzzword">acts of kindness</span> yet. Now&apos;s a
            great time to{" "}
            <Button
              className="linkish thin"
              onClick={() => history.push("/ripple")}
            >
              get started
            </Button>
            !
          </p>
        ) : (
          <>
            <h3>Your Acts of Kindness</h3>
            <PanelList>
              <RotatePhoneIcon />
              {acts.map((act) => (
                <PanelList.Item key={act.id} onClick={() => viewAct(act.id)}>
                  <Panel.Segment
                    primary
                    label="You helped:"
                    value={act.actee.firstName}
                    footer={util.formatDate(act.createdAt)}
                  />
                  <Panel.Segment
                    copy
                    label="How:"
                    value={
                      act.description.length > 120
                        ? act.description.substring(0, 119) + "..."
                        : act.description
                    }
                  />
                  <Panel.Segment
                    metric
                    label={`${util
                      .pronoun(act.actee, "he")
                      .replace(/^\w/, (c) => c.toUpperCase())} donated:`}
                    value={
                      <>
                        <span>
                          <span className="dollar-sign">$</span>
                          {util.toDollarStr(act.donation.amount)}
                        </span>
                      </>
                    }
                  />
                  <Panel.Segment
                    label={`${util
                      .pronoun(act.actee, "he")
                      .replace(/^\w/, (c) => c.toUpperCase())} pledged:`}
                    completed={act.completed === act.pledge}
                  >
                    <Panel.Segment
                      metric
                      value={act.pledge}
                      footer={`Act${act.pledge !== 1 ? "s" : ""} of Kindness`}
                    />
                    <Panel.Segment
                      metric
                      value={act.completed}
                      footer="Completed"
                    />
                  </Panel.Segment>
                </PanelList.Item>
              ))}
            </PanelList>
          </>
        )}
      </div>
    </div>
  );
}

export default Acts;
