import React from "react";
import "../assets/css/About.css";
import Panel from "../Panel";
import util from "../util";
import { useHistory, useLocation } from "react-router-dom";
import { Button } from "@material-ui/core";
import _ from "lodash";

function About() {
  const history = useHistory();
  const location = useLocation();
  const returnPath = _.get(location, "state.goto");

  function go() {
    util.scrollTop();
    history.push(returnPath || "/");
  }

  return (
    <div id="about" className="view long with-nav wide">
      <div className="inner">
        <Panel filled>
          <Panel.Body>
            <p>Our mission:</p>
            <h2>To show the world the power of kindness.</h2>
          </Panel.Body>
        </Panel>
        <p className="text-left">
          At Rippl.me, we&apos;re convinced that kindness will be the guiding
          principle of the next stage of our evolution as a society. Instead of
          being a society fueled by the economics of scarcity and transactions
          between two people that benefit only those two people, we see a future
          where people help each other&mdash;not because they expect something
          in return, but because it feels good and they expect their kindness to
          be paid forward to someone else, creating a ripple effect that
          multiplies that single act of kindness over and over again as it
          continues to be paid forward. The result is that a single act of
          kindness can spawn hundreds of additional acts and thousands of
          dollars raised for charity&mdash;a chain reaction of
          kindness&mdash;benefiting society as a whole far more than a single
          transaction between two people.
        </p>
        <p className="text-left">
          The goal of the Rippl.me platform is to show people the full impact
          that their acts of kindness have on the world by tracking the ripple
          effect of a single act as it radiates outward. We hope to inspire
          people to live differently, with kindness as their primary motivation
          instead of self-interest.
        </p>

        {returnPath && (
          <Button variant="contained" color="primary" onClick={go}>
            Back
          </Button>
        )}
      </div>
    </div>
  );
}

export default About;
