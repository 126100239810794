import packageJson from "../package.json";
import React, {
  createContext,
  useState,
  useEffect,
  useCallback,
  useRef,
} from "react";
import NavBar from "./NavBar";
import Home from "./views/Home";
import Connect from "./views/Connect";
import OAuth from "./views/OAuth";
import Register from "./views/Register";
import Login from "./views/Login";
import ForgotPassword from "./views/ForgotPassword";
import ResetPassword from "./views/ResetPassword";
import Profile from "./views/Profile";
import EditProfile from "./views/EditProfile";
import Dashboard from "./views/Dashboard";
import InviteContainer from "./views/InviteContainer";
import PifContainer from "./views/PifContainer";
import SelectCharityContainer from "./views/SelectCharityContainer";
import PifAsk from "./views/PifAsk";
import ActsContainer from "./views/ActsContainer";
import PledgesContainer from "./views/PledgesContainer";
import FundraisersContainer from "./views/FundraisersContainer";
import JoinFundraiserContainer from "./views/JoinFundraiserContainer";
import GetStarted from "./views/GetStarted";
import NewRipple from "./views/NewRipple";
import About from "./views/About";
import Faq from "./views/Faq";
import Privacy from "./views/Privacy";
import Terms from "./views/Terms";
import Contact from "./views/Contact";
import Unsubscribe from "./views/Unsubscribe";
import WhenLoaded from "./WhenLoaded";
import View from "./View";
import Loading from "./views/Loading";
import Splash from "./views/Splash";
import NotFound from "./views/NotFound";
import Su from "./views/Su";
import Api from "./Api";
import util from "./util";
import { Router, Switch, Route } from "react-router-dom";
import { CssBaseline } from "@material-ui/core";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import Cookies from "js-cookie";

export const AppContext = createContext({});
export const UserContext = createContext({
  setUser: () => {},
});

function App({ prod, browserHistory, defaultUi, loggedIn }) {
  console.debug(`v${packageJson.version} (${prod ? "prod" : "dev"})`);
  const [loading, setLoading] = useState(true);
  const [userContext, setUserContext] = useState({
    setUser: (user) => {
      Cookies.set("user", { loggedIn: !!user }, { expires: 365 });
      setUserContext((prev) => ({ ...prev, user: user }));
    },
  });
  const setUserCallback = useCallback(userContext.setUser, []);
  const api = useRef(new Api(prod ? util.prodContext : util.devContext));
  const [ui, setUi] = useState({
    ...defaultUi,
    setUiTheme: (theme) => {
      setUi((prev) => ({ ...prev, theme }));
    },
  });

  useEffect(() => {
    (async () => {
      if (await api.current.refresh()) {
        const user = await api.current.getUser();
        setUserCallback(user);
      }
      setLoading(false);
    })();
  }, [setUserCallback, api]);

  useEffect(() => {
    Cookies.set("ui", ui, { expires: 365 });
  });

  const theme = createMuiTheme({
    palette: {
      type: "dark",
      primary: {
        main: "#1b9696",
      },
      background: {
        default: "#295a8c",
      },
    },
    typography: {
      fontSize: 16,
      fontFamily: ["Raleway", "sans-serif"],
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AppContext.Provider
        value={
          prod
            ? { config: util.prodContext, api: api.current, ui }
            : { config: util.devContext, api: api.current, ui }
        }
      >
        <UserContext.Provider value={userContext}>
          <Router history={browserHistory}>
            <Switch>
              <Route exact path="/">
                {loggedIn && loading ? (
                  <View>
                    <Splash />
                  </View>
                ) : (
                  <View>
                    <WhenLoaded loading={loading}>
                      <NavBar />
                    </WhenLoaded>
                    <Home />
                  </View>
                )}
              </Route>
              <Route path="/stripe-connect">
                <View>
                  <Connect />
                </View>
              </Route>
              <Route path="/oauth">
                <View>
                  <OAuth />
                </View>
              </Route>
              <Route path="/register">
                <View loading={loading}>
                  <NavBar />
                  <Register />
                </View>
              </Route>
              <Route path="/login">
                <View>
                  <Login />
                </View>
              </Route>
              <Route path="/forgot-password">
                <View>
                  <ForgotPassword />
                </View>
              </Route>
              <Route path="/reset-password/:token">
                <View>
                  <ResetPassword />
                </View>
              </Route>
              <Route path="/profile">
                <View loading={loading} secure>
                  <NavBar />
                  <Profile />
                </View>
              </Route>
              <Route path="/edit-profile">
                <View loading={loading} secure>
                  <NavBar />
                  <EditProfile />
                </View>
              </Route>
              <Route path="/p/:uid?">
                <PifContainer loading={loading} />
              </Route>
              <Route path="/f/:uid?">
                <PifContainer loading={loading} fundraiser />
              </Route>
              <Route path="/dashboard">
                <View loading={loading} secure>
                  <NavBar />
                  <Dashboard />
                </View>
              </Route>
              <Route path="/select-charity/:aid">
                <SelectCharityContainer loading={loading} />
              </Route>
              <Route path="/invite">
                <View loading={loading} secure>
                  <NavBar />
                  <PifAsk />
                </View>
              </Route>
              <Route path="/i/:aid">
                <InviteContainer loading={loading} />
              </Route>
              <Route path="/join/:fid">
                <JoinFundraiserContainer loading={loading} />
              </Route>
              <Route path="/unsubscribe/:uid">
                <View>
                  <Unsubscribe />
                </View>
              </Route>
              <Route path="/get-started">
                <View loading={loading} secure>
                  <NavBar />
                  <GetStarted />
                </View>
              </Route>
              <Route path="/ripple">
                <View loading={loading} secure>
                  <NavBar />
                  <NewRipple />
                </View>
              </Route>
              <Route path="/acts">
                <ActsContainer loading={loading} />
              </Route>
              <Route path="/pledges">
                <PledgesContainer loading={loading} />
              </Route>
              <Route path="/fundraisers">
                <FundraisersContainer loading={loading} />
              </Route>
              <Route path="/about">
                <View>
                  <WhenLoaded loading={loading}>
                    <NavBar />
                  </WhenLoaded>
                  <About />
                </View>
              </Route>
              <Route path="/faq">
                <View>
                  <WhenLoaded loading={loading}>
                    <NavBar />
                  </WhenLoaded>
                  <Faq />
                </View>
              </Route>
              <Route path="/privacy">
                <View>
                  <WhenLoaded loading={loading}>
                    <NavBar />
                  </WhenLoaded>
                  <Privacy />
                </View>
              </Route>
              <Route path="/terms">
                <View>
                  <WhenLoaded loading={loading}>
                    <NavBar />
                  </WhenLoaded>
                  <Terms />
                </View>
              </Route>
              <Route path="/contact">
                <View>
                  <WhenLoaded loading={loading}>
                    <NavBar />
                  </WhenLoaded>
                  <Contact />
                </View>
              </Route>
              <Route path="/loading">
                <View>
                  <Loading />
                </View>
              </Route>
              <Route path="/splash">
                <View>
                  <Splash />
                </View>
              </Route>
              <Route path="/su/:suid">
                <View>
                  <Su />
                </View>
              </Route>
              <Route path="*">
                <View>
                  <NotFound />
                </View>
              </Route>
            </Switch>
          </Router>
        </UserContext.Provider>
      </AppContext.Provider>
    </ThemeProvider>
  );
}

export default App;
