import React, { useState } from "react";
import "./assets/css/CardLink.css";
import { Grid, Card, Snackbar, Slide, Button } from "@material-ui/core";
import ClickableTooltip from "./ClickableTooltip";
import Alert from "@material-ui/lab/Alert";
import * as clipboard from "clipboard-polyfill";
import QRCode from "qrcode.react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-regular-svg-icons";

function CardLink({ header, url, qrCode }) {
  const [copied, setCopied] = useState(false);
  const base = window.location.href.match(/.+:\/\/[^/]+/)[0];
  const link = url.replace(/.+:\/\/[^/]+/, base);

  function copy() {
    clipboard.writeText(link);
    setCopied(true);
  }

  function SlideUp(props) {
    return <Slide {...props} direction="up" />;
  }

  return (
    <div className="card-link">
      <Grid container justify="center">
        <Card>
          <div className="card-header">{header}</div>
          {qrCode && (
            <div className="qrcode">
              <QRCode value={link} renderAs="svg" />
              <div className="card-help">
                <ClickableTooltip title="This is a QR code. If you're helping someone in person, you can show them this code and they can use their phone's camera to scan it and get to your pay-it-forward page.">
                  <span className="copy">What&apos;s this?</span> &uarr;
                </ClickableTooltip>
              </div>
            </div>
          )}
          <div className="card-link" onClick={copy}>
            {url.replace(/.+:\/\//, "")}
          </div>
          <div className="copy-button">
            <Button
              type="button"
              variant="contained"
              color="primary"
              onClick={copy}
            >
              <FontAwesomeIcon icon={faCopy} />
              Copy
            </Button>
          </div>
        </Card>
      </Grid>

      {copied && (
        <Snackbar
          open={copied}
          TransitionComponent={SlideUp}
          autoHideDuration={3000}
          onClose={() => setCopied(false)}
        >
          <Alert elevation={6} variant="filled" severity="success">
            Copied!
          </Alert>
        </Snackbar>
      )}
    </div>
  );
}

export default CardLink;
