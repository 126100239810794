import React, { useContext, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import "../assets/css/PifLand.css";
import Panel from "../Panel";
import Screenshots from "../Screenshots";
import { AppContext, UserContext } from "../App";
import BottomLinks from "../BottomLinks";
import { Button } from "@material-ui/core";
import { faShare } from "@fortawesome/free-solid-svg-icons";
import { faRibbon } from "@fortawesome/free-solid-svg-icons";
import { faShareAlt } from "@fortawesome/free-solid-svg-icons";
import _ from "lodash";
import util from "../util";

function PifLand({ act }) {
  const appContext = useContext(AppContext);
  const actor = act.actor || {};
  const history = useHistory();
  const { url } = useRouteMatch();
  const userContext = useContext(UserContext);
  const light = _.get(appContext, "ui.theme") === "light";
  const [showScreenshots, setShowScreenshots] = useState(false);
  const fundraiser = act.fundraiser;

  function start() {
    history.push(`${url}/what`);
  }

  function openLightbox() {
    setShowScreenshots(true);
    util.event("Lightbox", "Open", "Screenshots");
  }

  function closeLightbox() {
    setShowScreenshots(false);
    util.event("Lightbox", "Close", "Screenshots");
  }

  return (
    <div id="pif-land" className="view long with-nav">
      <div className="inner">
        {_.get(userContext, "user.id") === actor.id && (
          <Panel id="self-alert" heading="Note" alert>
            <Panel.Body>
              <p className="small">
                This link is intended for you to send to someone else when you
                ask them to pay it forward.
              </p>
            </Panel.Body>
          </Panel>
        )}
        <p>Hi there!</p>
        <p>
          Looks like {actor.firstName} has performed an{" "}
          <span className="buzzword">act of kindness</span> for you and is
          asking you to pay it forward if you can.
        </p>
        <p>Here&apos;s how it works:</p>

        <Panel align="left" icon={faShare} filled withArrow>
          <Panel.Body>
            <p>
              You pledge to pay it forward by performing one or more{" "}
              <span className="buzzword">acts of kindness</span> for someone
              else.
            </p>
            <p className="small supporting">
              {actor.firstName} will be able to track the progress of your
              pledge and see how you&apos;ve paid his kindness forward.
            </p>
          </Panel.Body>
        </Panel>

        <Panel align="right" icon={faRibbon} withArrow filled>
          <Panel.Body>
            {fundraiser ? (
              <>
                <p>You make a donation to {actor.firstName}&apos;s charity.</p>
                {fundraiser.charity.logoUrl && (
                  <a
                    href={fundraiser.charity.websiteUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      className="charity-logo"
                      src={fundraiser.charity.logoUrl}
                      alt=""
                    />
                  </a>
                )}
              </>
            ) : (
              <p>
                You make a donation to a charity that {actor.firstName} will
                select.
              </p>
            )}
            <p className="small supporting">
              Paying it forward can take a while, so you can show your
              appreciation right now with a small donation.{" "}
              {fundraiser ? (
                <>
                  {actor.firstName} is part of a fundraiser to benefit{" "}
                  <a
                    href={fundraiser.charity.websiteUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {fundraiser.charity.name}
                  </a>
                  .
                </>
              ) : (
                <>
                  {actor.firstName} can select from our{" "}
                  <Button
                    className="linkish"
                    onClick={() => history.push("/faq#FooHcxGE3")}
                  >
                    featured charities
                  </Button>{" "}
                  covering a broad range of causes from saving the environment
                  to supporting life-saving medical research. We&apos;ll let you
                  know which one {util.pronoun(actor, "he")} selects.
                </>
              )}
            </p>
          </Panel.Body>
        </Panel>

        <Panel align="left" icon={faShareAlt} filled>
          <Panel.Body>
            <p>
              You perform an <span className="buzzword">act of kindness</span>{" "}
              for someone else and ask them to pay it forward to continue the
              chain.
            </p>
            <p className="small supporting">
              Starting with a single act of kindness, if each person paid it
              forward to two other people and donated just $10 to charity, after
              15 steps in the chain the result would be 65K acts of kindness and
              $655K raised for charity. That&apos;s the power of the ripple
              effect! You can track the total impact of your ripple effect from
              your{" "}
              <Button className="linkish" onClick={openLightbox}>
                dashboard
              </Button>
              .
            </p>
          </Panel.Body>
        </Panel>

        <p>
          Help {actor.firstName} keep the ripple going and be part of something
          that can truly change the world.
        </p>
        <Button variant="contained" color="primary" onClick={start}>
          Pay it Foward!
        </Button>
        <BottomLinks returnPath={url} />
        {showScreenshots && (
          <Screenshots light={light} onCloseLightbox={closeLightbox} />
        )}
      </div>
    </div>
  );
}

export default PifLand;
