import React, { useEffect, useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import RegisterForm from "../RegisterForm";
import { UserContext } from "../App";
import util from "../util";

function Register() {
  const history = useHistory();
  const userContext = useContext(UserContext);
  const [loggedInUser] = useState(userContext.user);

  useEffect(() => {
    if (loggedInUser) history.replace("/dashboard");
  }, [history, loggedInUser]);

  async function onUserCreated() {
    util.scrollTop();
    history.push("/get-started");
  }

  return (
    <div id="register" className="view with-nav">
      <div className="header">
        <h1>Join Now!</h1>
        <p>&hellip;and start a ripple of kindness</p>
        <RegisterForm onUserCreated={onUserCreated} />
      </div>
    </div>
  );
}

export default Register;
