import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import "../assets/css/Fundraiser.css";
import { AppContext } from "../App";
import NotFound from "./NotFound";
import Breadcrumbs from "../Breadcrumbs";
import Panel from "../Panel";
import CardLink from "../CardLink";
import FundraiserChart from "../FundraiserChart";
import util from "../util";
import _ from "lodash";

function Fundraiser({ fundraisers }) {
  const { fid } = useParams();
  const fundraiser = _.find(fundraisers, (it) => it.id === fid);
  const api = useContext(AppContext).api;

  return !fundraiser ? (
    <NotFound />
  ) : (
    <div id="fundraiser" className="view with-nav long expand">
      <div className="inner">
        <Breadcrumbs
          links={[{ copy: "Your Fundraisers", url: "/fundraisers" }]}
        />
        {fundraiser.charity.logoUrl && (
          <img
            className="charity-logo"
            src={fundraiser.charity.logoUrl}
            alt=""
          />
        )}

        <Panel id="title" segmented>
          <Panel.Segment
            primary
            value={fundraiser.name}
            footer={
              <div>
                Launched:{" "}
                {fundraiser.launchDate
                  ? util.formatDate(fundraiser.launchDate)
                  : "TBD"}
              </div>
            }
          />
          <Panel.Segment
            id="join-link"
            label="Join Link:"
            value={
              <CardLink
                url={`https://rippl.me/join/${
                  fundraiser.code || fundraiser.id
                }`}
              />
            }
          />
        </Panel>

        <Panel id="summary" heading="Summary" filled segmented>
          <Panel.Segment
            metric
            label="Participants:"
            value={fundraiser.participants}
          />
          <Panel.Segment
            id="acts"
            metric
            label="Acts of Kindness:"
            value={fundraiser.acts}
          />
          <Panel.Segment
            metric
            label="Total Raised:"
            value={
              <>
                <span className="dollar-sign">$</span>
                {util.toDollarStr(fundraiser.donations)}
              </>
            }
          />
        </Panel>

        <FundraiserChart
          fundraiser={fundraiser}
          dataSrc={api.getFundraiserParticipantSummary.bind(api)}
          heading="Participants"
          dataKey="count"
          dataLabel="Participants"
          // testData={testParticipants}
          helpText="This chart shows the cumulative number of participants in the fundraiser since launch. A participant is someone who joined the fundraiser via the sign up link. This chart does not include people paying forward acts of kindness performed by participants."
        />

        <FundraiserChart
          fundraiser={fundraiser}
          dataSrc={api.getFundraiserActSummary.bind(api)}
          heading="Acts of Kindness"
          dataKey="count"
          dataLabel="Acts"
          // testData={testActs}
          helpText="This chart shows the cumulative number of acts of kindness performed as part of the fundraiser since launch. This includes the entire ripple effect of acts of kindness being paid forward."
        />

        <FundraiserChart
          fundraiser={fundraiser}
          dataSrc={api.getFundraiserDonationSummary.bind(api)}
          heading="Total Raised"
          dataKey="sum"
          dataLabel="Total"
          currency={true}
          // testData={testDonations}
          helpText="This chart shows the cumulative amount raised since launch, and includes the entire ripple effect of acts of kindness being paid forward."
        />
      </div>
    </div>
  );
}

/*
const testParticipants = [
  {
    day: "2020-07-11T00:00:00.000Z",
    count: 1,
  },
  {
    day: "2020-07-12T00:00:00.000Z",
    count: 1,
  },
  {
    day: "2020-08-01T00:00:00.000Z",
    count: 4,
  },
  {
    day: "2020-08-02T00:00:00.000Z",
    count: 3,
  },
  {
    day: "2020-08-03T00:00:00.000Z",
    count: 2,
  },
  {
    day: "2020-08-05T00:00:00.000Z",
    count: 1,
  },
  {
    day: "2020-08-08T00:00:00.000Z",
    count: 1,
  },
  {
    day: "2020-08-09T00:00:00.000Z",
    count: 3,
  },
  {
    day: "2020-08-11T00:00:00.000Z",
    count: 1,
  },
];

const testActs = [
  {
    day: "2020-08-02T00:00:00.000Z",
    count: 1,
  },
  {
    day: "2020-08-04T00:00:00.000Z",
    count: 1,
  },
  {
    day: "2020-08-05T00:00:00.000Z",
    count: 2,
  },
  {
    day: "2020-08-06T00:00:00.000Z",
    count: 5,
  },
  {
    day: "2020-08-07T00:00:00.000Z",
    count: 2,
  },
  {
    day: "2020-08-09T00:00:00.000Z",
    count: 1,
  },
  {
    day: "2020-08-11T00:00:00.000Z",
    count: 1,
  },
];

const testDonations = [
  {
    day: "2020-08-02T00:00:00.000Z",
    sum: 1000,
  },
  {
    day: "2020-08-04T00:00:00.000Z",
    sum: 5000,
  },
  {
    day: "2020-08-05T00:00:00.000Z",
    sum: 20000,
  },
  {
    day: "2020-08-06T00:00:00.000Z",
    sum: 4550,
  },
  {
    day: "2020-08-07T00:00:00.000Z",
    sum: 2000,
  },
  {
    day: "2020-08-09T00:00:00.000Z",
    sum: 500,
  },
  {
    day: "2020-08-11T00:00:00.000Z",
    sum: 2000,
  },
];
*/

export default Fundraiser;
