import { useContext } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { AppContext, UserContext } from "./App";

function Secure({ children }) {
  const appContext = useContext(AppContext);
  const userContext = useContext(UserContext);
  const location = useLocation();
  const history = useHistory();

  if (!userContext.user) {
    history.replace("/login", { goto: location.pathname });
    return null;
  } else if (appContext.api.jwtExpired()) appContext.api.refresh();

  return children;
}

export default Secure;
