import React from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { Button } from "@material-ui/core";
import "../assets/css/Fundraisers.css";
import PanelList from "../PanelList";
import RotatePhoneIcon from "../RotatePhoneIcon";
import Panel from "../Panel";
import util from "../util";
import classNames from "classnames";

function Fundraisers({ fundraisers }) {
  const history = useHistory();
  const { url } = useRouteMatch();

  function viewFundraiser(aid) {
    util.scrollTop();
    history.push(`${url}/${aid}`);
  }

  return (
    <div
      id="fundraisers"
      className={classNames("view", "with-nav", {
        long: fundraisers.length,
        wide: fundraisers.length,
      })}
    >
      <div className="inner">
        {!fundraisers.length ? (
          <>
            <p>Looks like you haven&apos;t started any fundraisers yet.</p>
            <p>
              <Button
                className="linkish thin"
                onClick={() => history.push("/ripple")}
              >
                Give us a shout
              </Button>{" "}
              to learn more.
            </p>
          </>
        ) : (
          <>
            <h3>Your Fundraisers</h3>
            <PanelList>
              <RotatePhoneIcon />
              {fundraisers.map((fundraiser) => (
                <PanelList.Item
                  key={fundraiser.id}
                  onClick={() => viewFundraiser(fundraiser.id)}
                >
                  <Panel.Segment
                    primary
                    value={fundraiser.name}
                    footer={
                      <div>
                        Launched:{" "}
                        {fundraiser.launchDate
                          ? util.formatDate(fundraiser.launchDate)
                          : "TBD"}
                      </div>
                    }
                  />
                  <Panel.Segment
                    metric
                    label="Participants:"
                    value={fundraiser.participants}
                  />
                  <Panel.Segment
                    id="acts"
                    metric
                    label="Acts of Kindness:"
                    value={fundraiser.acts}
                  />
                  <Panel.Segment
                    metric
                    label="Total Raised:"
                    value={
                      <>
                        <span className="dollar-sign">$</span>
                        {util.toDollarStr(fundraiser.donations)}
                      </>
                    }
                  />
                </PanelList.Item>
              ))}
            </PanelList>
          </>
        )}
      </div>
    </div>
  );
}

export default Fundraisers;
