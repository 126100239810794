import React, { useState, useEffect, useContext } from "react";
import {
  Switch,
  Route,
  useRouteMatch,
  useParams,
  useHistory,
} from "react-router-dom";
import { AppContext } from "../App";
import View from "../View";
import NavBar from "../NavBar";
import NotFound from "./NotFound";
import SelectCharityLand from "./SelectCharityLand";
import SelectCharitySearch from "./SelectCharitySearch";
import SelectCharityView from "./SelectCharityView";
import SelectCharityConfirm from "./SelectCharityConfirm";
import SelectCharityTimedOut from "./SelectCharityTimedOut";
import ErrorBar from "../ErrorBar";
import util from "../util";
import Promise from "bluebird";

function SelectCharityContainer(props) {
  const appContext = useContext(AppContext);
  const parentLoading = props.loading;
  const history = useHistory();
  const { path, url } = useRouteMatch();
  const { aid } = useParams();
  const [act, setAct] = useState({});
  const [charities, setCharities] = useState([]);
  const [loading, setLoading] = useState(true);
  const [notFound, setNotFound] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (parentLoading) return;
    (async () => {
      try {
        const [_act, _charities] = await Promise.all([
          appContext.api.getAct(aid),
          appContext.api.getCharities(),
        ]);
        setAct(_act);
        setCharities(_charities);
      } catch (err) {
        setNotFound(true);
      }
      setLoading(false);
    })();
  }, [aid, parentLoading, appContext.api]);

  async function selectCharity(charity) {
    try {
      setLoading(true);
      await appContext.api.selectDonationCharity(act.donation.id, charity);
      util.event("Act", "SelectCharity", charity.name);

      setAct((prev) => {
        const model = { ...prev };
        model.donation = { ...prev.donation };
        model.donation.charity = charity;
        return model;
      });
      const base = url.match(/((\/[^/]+){2})/)[1];
      history.push(`${base}/confirm`);
    } catch (err) {
      setError(true);
      util.error(err, `Failed to select charity for act: ${act.id}`);
    }
    setLoading(false);
  }

  return (
    <>
      {notFound ? (
        <NotFound />
      ) : !loading && act.donation.status !== "PendingCharitySelection" ? (
        <View loading={loading} secure>
          <NavBar />
          <SelectCharityTimedOut act={act} />
        </View>
      ) : (
        <Switch>
          <Route exact path={path}>
            <View loading={loading} secure>
              <NavBar />
              <SelectCharityLand act={act} />
            </View>
          </Route>
          <Route path={`${path}/search`}>
            <View loading={loading} secure>
              <NavBar />
              <SelectCharitySearch
                charities={charities}
                onSelect={selectCharity}
              />
            </View>
          </Route>
          <Route path={`${path}/view/:cid`}>
            <View loading={loading} secure>
              <NavBar />
              <SelectCharityView
                charities={charities}
                onSelect={selectCharity}
              />
            </View>
          </Route>
          <Route path={`${path}/confirm`}>
            <View loading={loading} secure>
              <NavBar />
              <SelectCharityConfirm act={act} />
            </View>
          </Route>
          <Route path="*">
            <View>
              <NotFound />
            </View>
          </Route>
        </Switch>
      )}
      <ErrorBar error={error} />
    </>
  );
}

export default SelectCharityContainer;
