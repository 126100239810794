import React from "react";
import RipplIcon from "../assets/images/rippl-icon.svg";
import "../assets/css/Splash.css";

function Splash() {
  return (
    <div id="splash" className="view">
      <img id="rippl-cutout" src={RipplIcon} alt="" />
    </div>
  );
}

export default Splash;
