import React, { useContext, useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import "../assets/css/Ripple.css";
import { AppContext } from "../App";
import Breadcrumbs from "../Breadcrumbs";
import Panel from "../Panel";
import PanelList from "../PanelList";
import ImpactPanel from "../ImpactPanel";
import Spin from "../Spin";
import Loading from "./Loading";
import ErrorBar from "../ErrorBar";
import util from "../util";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretUp } from "@fortawesome/free-solid-svg-icons";
import _ from "lodash";
import * as d3 from "d3";

function Ripple({ acts }) {
  const appContext = useContext(AppContext);
  const api = appContext.api;
  const light = _.get(appContext, "ui.theme") === "light";
  const { aid } = useParams();
  const act = _.find(acts, (it) => it.id === aid);
  const uid = act.actor.id;
  const [loading, setLoading] = useState(true);
  const [ripple, setRipple] = useState(false);
  const [error, setError] = useState(false);
  const [clickedOn, setClickedOn] = useState();
  const [nodeAct, setNodeAct] = useState({
    id: "asdf",
    actor: { firstName: "Xxxx" },
    actee: { firstName: "Xxxx" },
    description: "Xxxx xxxx xx x xxxx xxxxxx xxxx xxxxx x xxxx.",
    donation: {
      amount: 0,
    },
    createdAt: new Date(),
  });
  const [loadingImpact, setLoadingImpact] = useState(true);
  const [loadingNodeAct, setLoadingNodeAct] = useState(false);
  const [impact, setImpact] = useState([
    { cause: null, acts: 0, donations: 0 },
  ]);
  const [prevImpact, setPrevImpact] = useState([
    { cause: null, acts: 0, donations: 0 },
  ]);
  const d3Root = useRef();
  const nodeSvg = useRef();

  useEffect(() => {
    if (!act) return;
    (async () => {
      try {
        const ripple = await api.getRipple(act.id);
        setRipple(ripple);
        // setRipple(getTestData(aid));
      } catch (err) {
        setError(true);
        util.error(err, `Failed to get impact for act: ${act.id}`);
      }
      setLoading(false);
    })();
  }, [api, act]);

  useEffect(() => {
    if (!ripple) return;
    (async () => {
      try {
        const [impact, prevImpact] = await Promise.all([
          api.getActImpact(ripple.id),
          api.getActImpact(
            ripple.id,
            new Date(new Date().valueOf() - 7 * 24 * 3600000)
          ),
        ]);
        setImpact(impact);
        setPrevImpact(prevImpact);
      } catch (err) {
        setError(true);
        util.error(err, `Failed to get impact for ripple: ${ripple.id}`);
      }
      setLoadingImpact(false);
    })();
  }, [api, ripple]);

  useEffect(() => {
    if (!clickedOn) return;
    (async () => {
      try {
        setLoadingNodeAct(true);
        setNodeAct(await api.getAct(clickedOn.id, true));
      } catch (err) {
        util.error(err, `Failed to get clicked on act: ${clickedOn.id}`);
      }
      setLoadingNodeAct(false);
    })();
  }, [api, clickedOn]);

  useEffect(() => {
    if (loading) return;
    const tree = d3.hierarchy(ripple);
    const links = tree.links();
    const nodes = tree.descendants();
    const scaleFactor = nodes.length / 20;
    const width = 250 + scaleFactor * 250;
    const height = 150 + scaleFactor * 250;
    const svg = d3
      .select(d3Root.current)
      .attr("viewBox", [-width / 2, -height / 2, width, height]);

    const simulation = d3
      .forceSimulation(nodes)
      .force(
        "link",
        d3
          .forceLink(links)
          .id((d) => d.id)
          .distance(1)
          .strength(0.2)
      )
      .force("charge", d3.forceManyBody().strength(-300))
      .force("x", d3.forceX())
      .force(
        "y",
        d3.forceY((d) =>
          d.depth === 0 && nodes.length > 1
            ? -75
            : Math.min(75, Math.abs(d.depth * (1 - scaleFactor) * 20))
        )
      );

    const linkSvg = svg
      .append("g")
      .attr("stroke", "#999")
      .attr("stroke-opacity", 0.6)
      .attr("stroke-width", 2)
      .selectAll("line")
      .data(links)
      .join("line");

    const outlineSvg = svg
      .append("g")
      .append("circle")
      .attr("fill", "transparent")
      .attr("stroke", light ? "#4d94ce" : "#d1dce6")
      .attr("stroke-width", 1.5)
      .attr("r", getRadius(act.donation.amount) + 4)
      .attr("x", 0)
      .attr("y", 0);

    nodeSvg.current = svg
      .append("g")
      .attr("stroke", light ? "#f8f9fa" : "#295a8c")
      .attr("stroke-width", 2)
      .selectAll("circle")
      .data(nodes)
      .join("circle")
      .attr("fill", (d) => {
        d.origColor = d.depth === 0 ? "#1b9696" : "#4d94ce";
        return d.origColor;
      })
      .attr("r", (d) => getRadius(d.data.amount))
      .call(drag(simulation))
      .on("click", clicked);

    function clicked(d) {
      if (d3.event.defaultPrevented) return; // dragged
      nodeSvg.current.transition().attr("fill", (d) => d && d.origColor);
      setClickedOn(d.data);
      d3.select(this).transition().attr("fill", "#99d1ff");
    }

    simulation.on("tick", () => {
      linkSvg
        .attr("x1", (link) => bound(link.source.x, width))
        .attr("y1", (link) => bound(link.source.y, height))
        .attr("x2", (link) => bound(link.target.x, width))
        .attr("y2", (link) => bound(link.target.y, height));

      const outlinePos = {};
      nodeSvg.current
        .attr("cx", (d) => {
          if (d.data.id === aid) outlinePos.x = d.x;
          return bound(d.x, width);
        })
        .attr("cy", (d) => {
          if (d.data.id === aid) outlinePos.y = d.y;
          return bound(d.y, height);
        });

      outlineSvg.attr("cx", bound(outlinePos.x, width));
      outlineSvg.attr("cy", bound(outlinePos.y, height));
    });

    function drag(sim) {
      return d3
        .drag()
        .on("start", (d) => {
          if (!d3.event.active) sim.alphaTarget(0.3).restart();
          d.fx = d.x;
          d.fy = d.y;
        })
        .on("drag", (d) => {
          d.fx = d3.event.x;
          d.fy = d3.event.y;
        })
        .on("end", (d) => {
          if (!d3.event.active) sim.alphaTarget(0);
          d.fx = null;
          d.fy = null;
        });
    }

    function bound(coord, max) {
      if (coord > 0) {
        return Math.min(coord, max / 2 - 12);
      } else {
        return Math.max(coord, -max / 2 + 12);
      }
    }

    function getRadius(amount) {
      return 8 + (Math.round(amount / 100) / 40) * 4;
    }

    return () => {
      simulation.stop();
      linkSvg.remove();
      outlineSvg.remove();
      nodeSvg.current.remove();
    };
  }, [light, act, aid, loading, ripple]);

  function getName(act, role) {
    const roleId = act[role].id;
    return uid === roleId ? "You" : act[role].firstName;
  }

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div id="ripple" className="view with-nav long">
          <div className="inner">
            <Breadcrumbs
              links={[
                {
                  copy: `Your Act for ${act.actee.firstName}`,
                  url: `/acts/${aid}`,
                },
              ]}
            />

            <Panel
              id="tree-view"
              heading="Your Extended Ripple"
              loading={loading}
              helpText={
                <>
                  This view shows the entire ripple that your act of kindness is
                  a part of, starting with the first act of kindness that
                  wasn&apos;t being paid forward from someone else. Each dot is
                  an act of kindness and the size shows the amount raised for
                  charity by that act. Click on a node for details.
                  <div id="view-desc">
                    <div>
                      <svg viewBox="-12,-12,24,24">
                        <g strokeWidth="2">
                          <circle fill="#1b9696" r="10" />
                        </g>
                      </svg>
                      The act that started this ripple
                    </div>
                    <div>
                      <svg viewBox="-12,-12,24,24">
                        <g strokeWidth="2">
                          <circle
                            fill="transparent"
                            stroke={light ? "#4d94ce" : "#d1dce6"}
                            r="10"
                          />
                        </g>
                      </svg>
                      Your act of kindness (outlined)
                    </div>
                  </div>
                </>
              }
            >
              <div id="graph">
                <svg id="d3-root" ref={d3Root} />
              </div>
              <Spin />
            </Panel>

            <ImpactPanel
              id="impact"
              loading={loadingImpact}
              impact={impact}
              prevImpact={prevImpact}
              subheading={
                <>
                  <FontAwesomeIcon icon={faCaretUp} />
                  last 7 days
                </>
              }
            />

            {clickedOn && (
              <PanelList loading={loadingNodeAct}>
                <PanelList.Item key={nodeAct.id}>
                  <Panel.Segment
                    primary
                    label={`${getName(nodeAct, "actor")} helped:`}
                    value={getName(nodeAct, "actee")}
                    footer={util.formatDate(nodeAct.createdAt)}
                  />
                  <Panel.Segment
                    metric
                    label={`${getName(nodeAct, "actor")} raised:`}
                    value={
                      <>
                        <span>
                          <span className="dollar-sign">$</span>
                          {util.toDollarStr(nodeAct.donation.amount)}
                        </span>
                      </>
                    }
                  />
                  <Panel.Segment
                    metric
                    label={`${getName(nodeAct, "actee")} paid it forward:`}
                    value={`${nodeAct.completed || 0}x`}
                  />
                </PanelList.Item>
                <Spin />
              </PanelList>
            )}

            <ErrorBar error={error} />
          </div>
        </div>
      )}
    </>
  );
}

export default Ripple;

/*
function getTestData(aid) {
  return {
    id: "Xrqna5390k",
    parentId: null,
    createdAt: "2020-05-07T21:21:53.263Z",
    children: [
      {
        id: "XI6sDjVnWW",
        parentId: "rqna5390k",
        createdAt: "2020-05-07T21:27:50.743Z",
        children: [
          {
            id: "XfCaldwHZI",
            parentId: "I6sDjVnWW",
            createdAt: "2020-05-07T21:28:50.393Z",
            children: [
              {
                id: "X-No9aj72b",
                parentId: "fCaldwHZI",
                createdAt: "2020-05-07T21:39:56.357Z",
              },
              {
                id: "X-No9aj72b",
                parentId: "I6sDjVnWW",
                createdAt: "2020-05-07T21:28:50.393Z",
                children: [
                  {
                    id: "X-No9aj72b",
                    parentId: "fCaldwHZI",
                    createdAt: "2020-05-07T21:39:56.357Z",
                  },
                  {
                    id: "XI6sDjVnWW",
                    parentId: "rqna5390k",
                    createdAt: "2020-05-07T21:27:50.743Z",
                    children: [
                      {
                        id: "XfCaldwHZI",
                        parentId: "I6sDjVnWW",
                        createdAt: "2020-05-07T21:28:50.393Z",
                        children: [
                          {
                            id: "X-No9aj72b",
                            parentId: "fCaldwHZI",
                            createdAt: "2020-05-07T21:39:56.357Z",
                          },
                          {
                            id: "X-No9aj72b",
                            parentId: "I6sDjVnWW",
                            createdAt: "2020-05-07T21:28:50.393Z",
                            children: [
                              {
                                id: "X-No9aj72b",
                                parentId: "fCaldwHZI",
                                createdAt: "2020-05-07T21:39:56.357Z",
                              },
                              {
                                id: "XI6sDjVnWW",
                                parentId: "rqna5390k",
                                createdAt: "2020-05-07T21:27:50.743Z",
                                children: [
                                  {
                                    id: "XfCaldwHZI",
                                    parentId: "I6sDjVnWW",
                                    createdAt: "2020-05-07T21:28:50.393Z",
                                    children: [
                                      {
                                        id: "X-No9aj72b",
                                        parentId: "fCaldwHZI",
                                        createdAt: "2020-05-07T21:39:56.357Z",
                                      },
                                    ],
                                  },
                                ],
                              },
                              {
                                id: "XfCaldwHZI",
                                parentId: "I6sDjVnWW",
                                createdAt: "2020-05-07T21:28:50.393Z",
                                children: [
                                  {
                                    id: "X-No9aj72b",
                                    parentId: "fCaldwHZI",
                                    createdAt: "2020-05-07T21:39:56.357Z",
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        id: "XfCaldwHZI",
                        parentId: "I6sDjVnWW",
                        createdAt: "2020-05-07T21:28:50.393Z",
                        children: [
                          {
                            id: "X-No9aj72b",
                            parentId: "fCaldwHZI",
                            createdAt: "2020-05-07T21:39:56.357Z",
                          },
                          {
                            id: "XfCaldwHZI",
                            parentId: "I6sDjVnWW",
                            createdAt: "2020-05-07T21:28:50.393Z",
                            children: [
                              {
                                id: "X-No9aj72b",
                                parentId: "fCaldwHZI",
                                createdAt: "2020-05-07T21:39:56.357Z",
                              },
                            ],
                          },
                        ],
                      },
                      {
                        id: "XfCaldwHZI",
                        parentId: "I6sDjVnWW",
                        createdAt: "2020-05-07T21:28:50.393Z",
                        children: [
                          {
                            id: "X-No9aj72b",
                            parentId: "fCaldwHZI",
                            createdAt: "2020-05-07T21:39:56.357Z",
                          },
                          {
                            id: "XfCaldwHZI",
                            parentId: "I6sDjVnWW",
                            createdAt: "2020-05-07T21:28:50.393Z",
                            children: [
                              {
                                id: "X-No9aj72b",
                                parentId: "fCaldwHZI",
                                createdAt: "2020-05-07T21:39:56.357Z",
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    id: "XfCaldwHZI",
                    parentId: "I6sDjVnWW",
                    createdAt: "2020-05-07T21:28:50.393Z",
                    children: [
                      {
                        id: "X-No9aj72b",
                        parentId: "fCaldwHZI",
                        createdAt: "2020-05-07T21:39:56.357Z",
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            id: aid,
            parentId: "rqna5390k",
            createdAt: "2020-05-07T21:27:50.743Z",
            children: [
              {
                id: "X-No9aj72b",
                parentId: "I6sDjVnWW",
                createdAt: "2020-05-07T21:28:50.393Z",
                children: [
                  {
                    id: "X-No9aj72b",
                    parentId: "fCaldwHZI",
                    createdAt: "2020-05-07T21:39:56.357Z",
                  },
                  {
                    id: "XI6sDjVnWW",
                    parentId: "rqna5390k",
                    createdAt: "2020-05-07T21:27:50.743Z",
                    children: [
                      {
                        id: "XfCaldwHZI",
                        parentId: "I6sDjVnWW",
                        createdAt: "2020-05-07T21:28:50.393Z",
                        children: [
                          {
                            id: "X-No9aj72b",
                            parentId: "fCaldwHZI",
                            createdAt: "2020-05-07T21:39:56.357Z",
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        id: "XmPDsTadpK",
        parentId: "rqna5390k",
        createdAt: "2020-05-07T21:37:13.322Z",
        children: [
          {
            id: "XfCaldwHZI",
            parentId: "I6sDjVnWW",
            createdAt: "2020-05-07T21:28:50.393Z",
            children: [
              {
                id: "X-No9aj72b",
                parentId: "fCaldwHZI",
                createdAt: "2020-05-07T21:39:56.357Z",
              },
            ],
          },
        ],
      },
    ],
  };
}
*/
