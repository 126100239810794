import request from "superagent";
import shortid from "shortid";
import jsonwebtoken from "jsonwebtoken";
import Promise from "bluebird";
import util from "./util";

class Api {
  constructor(config) {
    this.config = config;
  }

  async ready() {
    if (!this.refreshing) return;
    return this.refreshing;
  }

  setJwt(jwt) {
    if (!jwt) return;
    this.jwt = jwt;
    const decoded = jsonwebtoken.decode(this.jwt);
    const timeout = decoded.exp * 1000 - new Date().valueOf() - 60000;
    if (this.refresher) clearTimeout(this.refresher);
    this.refresher = setTimeout(() => {
      this.refresh();
    }, timeout);
  }

  clearJwt() {
    this.jwt = null;
    if (this.refresher) clearTimeout(this.refresher);
  }

  jwtExpired() {
    if (!this.jwt) return true;
    const decoded = jsonwebtoken.decode(this.jwt);
    return new Date() > new Date(decoded.exp * 1000);
  }

  async putAuthCode(scope, code) {
    return await request
      .post(`${this.config.api.baseUrl}/auth/authCodes`)
      .set("x-api-key", this.config.api.pk)
      .send({
        partner: "pledgeling",
        env: this.config.prod ? "live" : "test",
        scope,
        code,
      });
  }

  async createContact(email) {
    return await request
      .post(`${this.config.api.baseUrl}/users/contacts`)
      .set("x-api-key", this.config.api.pk)
      .send({ email: email });
  }

  async register(user) {
    let path = `${this.config.api.baseUrl}/users`;
    const model = { ...user };

    if (model.id) {
      path += `/${model.id}`;
    } else {
      model.id = shortid.generate();
    }
    return await request
      .post(path)
      .set("x-api-key", this.config.api.pk)
      .send(model);
  }

  async login(email, password) {
    let res;
    try {
      res = await request
        .post(`${this.config.api.baseUrl}/auth/login`)
        .withCredentials()
        .set("x-api-key", this.config.api.pk)
        .send({
          email,
          password,
        });
    } catch (err) {
      if (err.status === 401) return;
      util.error(err, `Login failed for email: ${email}`);
      throw err;
    }

    if (!res.text) return;
    const jwt = JSON.parse(res.text);
    this.setJwt(jwt);
    return jwt;
  }

  async su(email, password, suid) {
    if (!this.jwt) return;
    await this.ready();

    let res;
    try {
      res = await request
        .post(`${this.config.api.baseUrl}/auth/su/${suid}`)
        .withCredentials()
        .set("x-api-key", this.config.api.pk)
        .set("Authorization", `Bearer ${this.jwt}`)
        .send({
          email,
          password,
        });
    } catch (err) {
      if ([401, 404].indexOf(err.status) > -1) return;
      util.error(err, `Login failed for email: ${email}`);
      throw err;
    }

    if (!res.text) return;
    const jwt = JSON.parse(res.text);
    this.setJwt(jwt);
    return jwt;
  }

  async refresh() {
    console.debug("Refreshing JWT...");
    let done;
    this.refreshing = new Promise((resolve) => {
      done = () => {
        resolve();
        this.refreshing = null;
      };
    });

    let res;
    try {
      res = await request
        .get(`${this.config.api.baseUrl}/auth/refresh`)
        .withCredentials()
        .set("x-api-key", this.config.api.pk);
    } catch (err) {
      done();
      if (err.status === 401) return;
      util.error(err, "Refresh failed");
      throw err;
    }

    if (!res.text) return;
    const jwt = JSON.parse(res.text);
    this.setJwt(jwt);
    console.debug("Done refreshing");
    done();
    return jwt;
  }

  async logout() {
    await request
      .get(`${this.config.api.baseUrl}/auth/logout`)
      .withCredentials()
      .set("x-api-key", this.config.api.pk);
    this.clearJwt();
  }

  async requestPasswordReset(email) {
    await request
      .get(`${this.config.api.baseUrl}/auth/password-reset/${email}`)
      .set("x-api-key", this.config.api.pk);
  }

  async resetPassword(token, password) {
    await request
      .post(`${this.config.api.baseUrl}/auth/password-reset`)
      .set("x-api-key", this.config.api.pk)
      .send({ token, password });
  }

  async getUser(uid) {
    if (!uid && !this.jwt) return;
    await this.ready();

    const decoded = this.jwt ? jsonwebtoken.decode(this.jwt) : null;
    let req = request
      .get(`${this.config.api.baseUrl}/users/${uid || decoded.id}`)
      .set("x-api-key", this.config.api.pk);
    if (this.jwt) req.set("Authorization", `Bearer ${this.jwt}`);
    const res = await req;
    return res.body;
  }

  async getUserImpact(asOfDate) {
    if (!this.jwt) return;
    await this.ready();

    const decoded = jsonwebtoken.decode(this.jwt);
    let req = request
      .get(`${this.config.api.baseUrl}/users/${decoded.id}/impact`)
      .set("x-api-key", this.config.api.pk)
      .set("Authorization", `Bearer ${this.jwt}`);
    if (asOfDate) req = req.query({ asOf: asOfDate.valueOf() });
    const res = await req;
    return res.body;
  }

  async getUserImpactGraph() {
    if (!this.jwt) return;
    await this.ready();

    const decoded = jsonwebtoken.decode(this.jwt);
    const res = await request
      .get(`${this.config.api.baseUrl}/users/${decoded.id}/impactGraph`)
      .set("x-api-key", this.config.api.pk)
      .set("Authorization", `Bearer ${this.jwt}`);
    return res.body;
  }

  async getPendingDonations() {
    if (!this.jwt) return;
    await this.ready();

    const decoded = jsonwebtoken.decode(this.jwt);
    const res = await request
      .get(`${this.config.api.baseUrl}/users/${decoded.id}/pending-donations`)
      .set("x-api-key", this.config.api.pk)
      .set("Authorization", `Bearer ${this.jwt}`);
    return res.body;
  }

  async createAct(act) {
    if (!this.jwt) return;
    await this.ready();

    act.id = shortid.generate();
    if (!act.actor.id) act.actor.id = shortid.generate();
    const model = { ...act };
    delete model.billing;
    delete model.actee.password;
    delete model.actee.passwordConfirm;
    model.donation = {
      id: shortid.generate(),
      amount: act.donation * 100,
      fee: act.billing.fee || null,
      paymentToken: act.billing.paymentToken,
    };
    if (model.fundraiser) {
      model.fundraiser = { ...model.fundraiser };
      delete model.fundraiser.charity;
    }

    return await request
      .post(`${this.config.api.baseUrl}/acts`)
      .set("x-api-key", this.config.api.pk)
      .set("Authorization", `Bearer ${this.jwt}`)
      .send(model);
  }

  async updateActDescription(aid, description) {
    return await request
      .put(`${this.config.api.baseUrl}/acts/${aid}`)
      .set("x-api-key", this.config.api.pk)
      .set("Authorization", `Bearer ${this.jwt}`)
      .send({ description });
  }

  async getAct(id, pub) {
    const req = request
      .get(`${this.config.api.baseUrl}/acts/${id}`)
      .set("x-api-key", this.config.api.pk);
    if (this.jwt && !pub) req.set("Authorization", `Bearer ${this.jwt}`);
    return (await req).body;
  }

  async getChildActs(id) {
    if (!this.jwt) return;
    await this.ready();

    const res = await request
      .get(`${this.config.api.baseUrl}/acts/${id}/children`)
      .set("x-api-key", this.config.api.pk)
      .set("Authorization", `Bearer ${this.jwt}`);
    return res.body;
  }

  async getPublicAct(id) {
    const req = request
      .get(`${this.config.api.baseUrl}/acts/${id}`)
      .set("x-api-key", this.config.api.pk);
    return (await req).body;
  }

  async getActImpact(id, asOfDate) {
    if (!this.jwt) return;
    await this.ready();

    let req = request
      .get(`${this.config.api.baseUrl}/acts/${id}/impact`)
      .set("x-api-key", this.config.api.pk)
      .set("Authorization", `Bearer ${this.jwt}`);
    if (asOfDate) req = req.query({ asOf: asOfDate.valueOf() });
    const res = await req;
    return res.body;
  }

  async getRipple(aid) {
    if (!this.jwt) return;
    await this.ready();

    let req = request
      .get(`${this.config.api.baseUrl}/acts/${aid}/graph`)
      .set("x-api-key", this.config.api.pk)
      .set("Authorization", `Bearer ${this.jwt}`);
    return (await req).body;
  }

  async claimAct(act) {
    if (!this.jwt) return;
    await this.ready();

    return await request
      .post(`${this.config.api.baseUrl}/acts/${act.id}/claim`)
      .set("x-api-key", this.config.api.pk)
      .set("Authorization", `Bearer ${this.jwt}`)
      .send({ id: act.id });
  }

  async getCharities() {
    const res = await request
      .get(`${this.config.api.baseUrl}/charities`)
      .set("x-api-key", this.config.api.pk)
      .set("Authorization", `Bearer ${this.jwt}`);
    return res.body;
  }

  async selectDonationCharity(donationId, charity) {
    if (!this.jwt) return;
    await this.ready();

    return await request
      .post(`${this.config.api.baseUrl}/donations/${donationId}/select-charity`)
      .set("x-api-key", this.config.api.pk)
      .set("Authorization", `Bearer ${this.jwt}`)
      .send({
        id: charity.id,
      });
  }

  async getRecentActivity() {
    if (!this.jwt) return;
    await this.ready();

    const decoded = jsonwebtoken.decode(this.jwt);

    const res = await request
      .get(`${this.config.api.baseUrl}/users/${decoded.id}/activities`)
      .set("x-api-key", this.config.api.pk)
      .set("Authorization", `Bearer ${this.jwt}`);
    return res.body;
  }

  async getOpenPledges() {
    if (!this.jwt) return;
    await this.ready();

    const decoded = jsonwebtoken.decode(this.jwt);
    const res = await request
      .get(`${this.config.api.baseUrl}/users/${decoded.id}/open-pledges`)
      .set("x-api-key", this.config.api.pk)
      .set("Authorization", `Bearer ${this.jwt}`);
    return res.body;
  }

  async getUserPledges() {
    if (!this.jwt) return;
    await this.ready();

    const decoded = jsonwebtoken.decode(this.jwt);
    const res = await request
      .get(`${this.config.api.baseUrl}/users/${decoded.id}/pledges`)
      .set("x-api-key", this.config.api.pk)
      .set("Authorization", `Bearer ${this.jwt}`);
    return res.body;
  }

  async getUserActs() {
    if (!this.jwt) return;
    await this.ready();

    const decoded = jsonwebtoken.decode(this.jwt);
    const res = await request
      .get(`${this.config.api.baseUrl}/users/${decoded.id}/acts`)
      .set("x-api-key", this.config.api.pk)
      .set("Authorization", `Bearer ${this.jwt}`);
    return res.body;
  }

  async updateUser(user) {
    if (!this.jwt) return;
    await this.ready();

    return await request
      .put(`${this.config.api.baseUrl}/users/${user.id}`)
      .set("x-api-key", this.config.api.pk)
      .set("Authorization", `Bearer ${this.jwt}`)
      .send(user);
  }

  async unsubscribe(uid) {
    return await request
      .post(`${this.config.api.baseUrl}/users/unsubscribe`)
      .set("x-api-key", this.config.api.pk)
      .send({
        id: uid,
      });
  }

  async getUserFundraisers() {
    if (!this.jwt) return;
    await this.ready();

    const decoded = jsonwebtoken.decode(this.jwt);
    const res = await request
      .get(`${this.config.api.baseUrl}/users/${decoded.id}/fundraisers`)
      .set("x-api-key", this.config.api.pk)
      .set("Authorization", `Bearer ${this.jwt}`);
    return res.body;
  }

  async getUserOwnedFundraisers() {
    if (!this.jwt) return;
    await this.ready();

    const decoded = jsonwebtoken.decode(this.jwt);
    const res = await request
      .get(`${this.config.api.baseUrl}/users/${decoded.id}/owned-fundraisers`)
      .set("x-api-key", this.config.api.pk)
      .set("Authorization", `Bearer ${this.jwt}`);
    return res.body;
  }

  async getFundraiserParticipant(id) {
    const res = await request
      .get(`${this.config.api.baseUrl}/fundraisers/participants/${id}`)
      .set("x-api-key", this.config.api.pk);
    return res.body;
  }

  async getFundraiser(fid) {
    const res = await request
      .get(`${this.config.api.baseUrl}/fundraisers/${fid}`)
      .set("x-api-key", this.config.api.pk);
    return res.body;
  }

  async joinFundraiser(fid, uid) {
    if (!this.jwt) return;
    await this.ready();

    return await request
      .post(`${this.config.api.baseUrl}/fundraisers/${fid}/participants`)
      .set("x-api-key", this.config.api.pk)
      .set("Authorization", `Bearer ${this.jwt}`)
      .send({
        id: uid,
      });
  }

  async getFundraiserParticipantSummary(fid) {
    if (!this.jwt) return;
    await this.ready();

    const res = await request
      .get(`${this.config.api.baseUrl}/fundraisers/${fid}/participant-summary`)
      .set("x-api-key", this.config.api.pk)
      .set("Authorization", `Bearer ${this.jwt}`);
    return res.body;
  }

  async getFundraiserActSummary(fid) {
    if (!this.jwt) return;
    await this.ready();

    const res = await request
      .get(`${this.config.api.baseUrl}/fundraisers/${fid}/act-summary`)
      .set("x-api-key", this.config.api.pk)
      .set("Authorization", `Bearer ${this.jwt}`);
    return res.body;
  }

  async getFundraiserDonationSummary(fid) {
    if (!this.jwt) return;
    await this.ready();

    const res = await request
      .get(`${this.config.api.baseUrl}/fundraisers/${fid}/donation-summary`)
      .set("x-api-key", this.config.api.pk)
      .set("Authorization", `Bearer ${this.jwt}`);
    return res.body;
  }
}

export default Api;
