import React from "react";
import "./assets/css/ImpactPanel.css";
import Panel from "./Panel";
import CauseIcons from "./CauseIcons";
import Spin from "./Spin";
import util from "./util";
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretUp } from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";

function ImpactPanel({
  id,
  heading,
  subheading,
  helpText,
  loading,
  impact,
  prevImpact,
  onClick,
  className,
}) {
  const acts = _.sumBy(impact, "acts");
  const prevActs = _.sumBy(prevImpact, "acts");
  const donations = _.sumBy(impact, "donations");
  const prevDonations = _.sumBy(prevImpact, "donations");

  return (
    <Panel
      id={id}
      className={classNames("impact-panel", className)}
      heading={heading}
      subheading={subheading}
      loading={loading}
      segmented
      filled
      helpText={helpText}
    >
      <Panel.Segment
        metric
        value={
          <>
            <div>{acts}</div>
            <>
              {prevImpact ? (
                <div className="metric-delta">
                  <span
                    className={classNames("chip", {
                      up: acts > prevActs,
                      flat: acts === prevActs,
                    })}
                  >
                    <FontAwesomeIcon icon={faCaretUp} />
                    <span>{acts - prevActs}</span>
                  </span>
                </div>
              ) : (
                <div className="cause-icons">&nbsp;</div>
              )}
            </>
          </>
        }
        footer="Acts of Kindness"
        onClick={onClick}
      />
      <Panel.Segment
        metric
        value={
          <>
            <div>
              <span className="dollar-sign">$</span>
              {util.toDollarStr(donations)}
            </div>
            <>
              {prevImpact ? (
                <div className="metric-delta">
                  <span
                    className={classNames("chip", {
                      up: donations > prevDonations,
                      flat: donations === prevDonations,
                    })}
                  >
                    <FontAwesomeIcon icon={faCaretUp} />
                    <span className="dollar-sign">$</span>
                    {util.toDollarStr(donations - prevDonations)}
                  </span>
                </div>
              ) : (
                <div className="cause-icons">&nbsp;</div>
              )}
            </>
          </>
        }
        footer="For Charity"
      />
      <Panel.Segment
        metric
        tooltip={
          !!_.sumBy(impact, (i) => (!!i.cause && 1) || 0) && (
            <div>
              {_.orderBy(impact, "donations", "desc").map((imp) => {
                if (!imp.cause) return null;
                return (
                  <div key={imp.cause}>
                    {imp.cause}: {`$${util.toDollarStr(imp.donations)}`}
                  </div>
                );
              })}
            </div>
          )
        }
        value={
          <>
            <div>{_.sumBy(impact, (i) => (!!i.cause && 1) || 0)}</div>
            <CauseIcons impact={impact} />
          </>
        }
        footer={`Cause${
          _.sumBy(impact, (i) => (!!i.cause && 1) || 0) === 1 ? "" : "s"
        } Supported`}
      />
      <Spin />
    </Panel>
  );
}
export default ImpactPanel;
