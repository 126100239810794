import React, { useEffect, useContext, useState } from "react";
import "../assets/css/Faq.css";
import { AppContext } from "../App";
import Spin from "../Spin";
import util from "../util";
import { useHistory, useLocation } from "react-router-dom";
import { Button } from "@material-ui/core";
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
} from "@material-ui/core";
import _ from "lodash";
import { scroller } from "react-scroll";

function Faq() {
  const appContext = useContext(AppContext);
  const history = useHistory();
  const location = useLocation();
  const returnPath = _.get(location, "state.goto");
  const [charities, setCharities] = useState([]);
  const [loading, setLoading] = useState(true);
  const defaultCharity = _.find(charities, { isDefault: true });
  const target = location.hash.substring(1);

  useEffect(() => {
    (async () => {
      try {
        const charities = await appContext.api.getCharities();
        setCharities(charities);
      } catch (err) {
        util.error(err, "Failed to get charities");
      }
      setLoading(false);
    })();
  }, [appContext.api]);

  useEffect(() => {
    if (!target) return;
    scroller.scrollTo(target, {
      duration: 750,
      delay: 0,
      smooth: "easeInOutCubic",
      offset: -100,
    });
  }, [target]);

  function go() {
    util.scrollTop();
    history.push(returnPath || "/");
  }

  const charityListItems = charities.map((charity) => (
    <li key={charity.id}>
      <a href={charity.websiteUrl} target="_blank" rel="noopener noreferrer">
        {charity.name}
      </a>
    </li>
  ));

  const questions = [
    {
      id: "KD0Dwq3ry",
      q: "Acts of kindness, pledges, paying it forward? I'm confused.",
      a: (
        <div>
          <p>
            Let&apos;s see if we can clarify some of the terminology we use
            throughout the platform. An <strong>act of kindness</strong> is
            pretty straightforward. That&apos;s when one person goes out of
            their way to help someone else without expecting anything in return.
            We call them &quot;conscious&quot; acts of kindness because they are
            in no way &quot;random&quot;. The Universe has a way of connecting
            the right people at the right time. Each act of kindness is an
            interaction between two people, the person who performed the act and
            the person who received it.
          </p>
          <p>
            To show this on Rippl.me the person who performed the act of
            kindness sends their unique pay-it-forward link to the person they
            helped, asking them to <strong>pay it forward</strong> if they can.
            Paying it forward involves two steps. First, the receiver of the act
            makes a donation to a charity that the person who helped them gets
            to select. Next, the receiver of the act makes a promise&mdash;a{" "}
            <strong>pledge</strong>&mdash;to perform one or more acts of
            kindness for someone else (they select how many times they&apos;ll
            pay it forward when making their pledge). This pledge is tracked on
            Rippl.me so that both people can see when and how it gets paid
            forward to the next link in the chain reaction of kindness.
          </p>
          <p>
            We refer to this chain reaction as the{" "}
            <strong>ripple effect</strong>, and we have some interesting ways to
            show you what that looks like so you can truly understand how much
            impact a single act of kindness can create in the world. A single
            act of kindness can start a ripple effect that results in thousands
            of additional acts of kindness and hundreds of thousands of dollars
            raised for charity.
          </p>
          <p>
            We call the chain itself, starting with the first act of kindness, a{" "}
            <strong>ripple</strong>. The first act of kindness in a ripple is
            not being paid forward from a previous act, and thus starts a new
            ripple. That ripple then encompasses all of the acts of kindness
            that follow it as people continue to pay it forward. A ripple is
            open-ended in that it will continue to branch out exponentially as
            long as people keep paying it forward. There&apos;s no limit to how
            large a ripple can grow. In theory, starting with a single act of
            kindness, if each person paid it forward to two other people the
            entire population of the earth would be reached in just 32 steps.
          </p>
        </div>
      ),
    },
    {
      id: "UWFHSUCAQ",
      q: "What are some examples of acts of kindness I can perform?",
      a: (
        <div>
          <p>
            There are always opportunities for kindness! One of the best ways to
            start is with your unique skills and talents. What can you do that
            would be difficult or impossible for others to do for themselves?
            Something that&apos;s quick and easy for you could be a game changer
            for someone else. Here&apos;s an idea. Try posting on Facebook,
            Twitter, or LinkedIn something like:
          </p>
          <blockquote>
            These are challenging times for all of us and I&apos;m offering to
            help you with X, or in any other way I can. #offeringhelp #ripplme
          </blockquote>
          <p>
            The world needs kindness now more than ever. Here are some specific
            ideas that are particularly relevant as we continue to navigate the
            current health crisis.
          </p>
          <ul>
            <li>
              Offer to help someone who has lost their job polish up their
              resume, or connect them with someone who might be hiring.
            </li>
            <li>Offer to food shop for someone who isn&apos;t able to.</li>
            <li>
              Help someone build their homeschooling curriculum or form a
              homeschooling group.
            </li>
          </ul>
          <p>Be creative and have fun coming up with ways to help!</p>
        </div>
      ),
    },
    {
      id: "F4HFy7OhN",
      q: "How should I ask someone to pay it forward?",
      a: (
        <div>
          <p>
            Asking someone to pay it forward is easy and shouldn&apos;t feel
            awkward. If you&apos;re helping someone virtually where you&apos;re
            sending your pay-it-forward link online it could be as simple as:
          </p>
          <blockquote>
            Glad I could help you out! Pay it forward if you can.{" "}
            <span className="nowrap">[your link]</span>
          </blockquote>
          <p>
            The landing page explains the pay-it-forward process so there&apos;s
            no need to get into too many details up front. Most people will see
            the value of the ripple effect and be excited to be involved.
            You&apos;ll be notified via email once they pledge to pay it
            forward. Keep in mind that some people may not be able or willing to
            pay it forward, and that&apos;s ok. You&apos;ll still feel great
            about helping someone even it they don&apos;t pay it forward.
          </p>
          <p>
            If you&apos;re helping someone in person and you don&apos;t have
            their contact info to send them your link try something like:
          </p>
          <blockquote>
            <p>
              <strong>Them</strong>: Thank you!
            </p>
            <p>
              <strong>You:</strong> No problem! Happy to help. I'm actually
              paying forward a favor that someone else did for me, so maybe you
              can do the same and keep the chain going. It's all being tracked
              on this app called Rippl.me. If you give me your email address I
              can send you a link to check it out.
            </p>
          </blockquote>
          <p>That&apos;s all it takes!</p>
        </div>
      ),
    },
    {
      id: "bOXmdP6mO",
      q: 'How do "fundraisers" work?',
      a: (
        <div>
          <p>
            A <strong>fundraiser</strong> is a way to harness the power of the
            ripple effect for a single charity by giving you the ability to
            start a ripple where every donation in the pay-it-forward chain
            automatically goes to that single charity, resulting in a
            potentially huge, focused impact. This differs from the default
            ripple behavior where a new charity is selected at each step in the
            chain. We can support fundraisers for over two million registered
            charities.{" "}
            <Button
              className="linkish"
              onClick={() => history.push("/contact")}
            >
              Give us a shout
            </Button>{" "}
            to learn more.
          </p>
        </div>
      ),
    },
    {
      id: "FooHcxGE3",
      q: "Which charities are available for donations?",
      a: (
        <div>
          <p>
            We strive to provide a focused set of top-rated charities that cover
            a broad range of causes without paralyzing you with too many
            choices. We&apos;ve done our homework so you don&apos;t have to.
            We&apos;re also continually evaluating our list and would love to
            hear from you if there are charities you&apos;d like to see
            supported.{" "}
            <Button
              className="linkish"
              onClick={() => history.push("/contact")}
            >
              Give us a shout
            </Button>{" "}
            and let us know! Here&apos;s our current list of featured charities:
          </p>
          <ul>{loading ? <Spin /> : charityListItems}</ul>
        </div>
      ),
    },
    {
      id: "ZDgEjS8bc",
      q: "How much of my donation actually goes to the charity?",
      a: (
        <div>
          <p>
            We don&apos;t take a single cent of your donation and rely solely on
            voluntary tips to pay for the servers and infrastructure required to
            keep Rippl.me running. There are, however, third-party fees involved
            in processing payments and distributing donations to charities which
            are unfortunately unavoidable. Here&apos;s the current fee
            structure:
          </p>
          <ul>
            <li>
              <p>
                <strong>Credit Card Processing Fees:</strong> Our payment
                processing partner{" "}
                <a
                  href="https://stripe.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Stripe
                </a>{" "}
                takes 2.9% + $0.30 of each donation to facilitate credit card
                payments.
              </p>
            </li>
            <li>
              <p>
                <strong>Charity API and Disbursement Fees:</strong> Our donation
                processing partner{" "}
                <a
                  href="https://pledgeling.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Pledgeling
                </a>{" "}
                takes 3% of each donation to facilitate the collection and
                disbursement of donated funds to charities.
              </p>
            </li>
          </ul>
        </div>
      ),
    },
    {
      id: "MKquKRuYF",
      q: "What happens if someone doesn't select a charity for a donation?",
      a: (
        <div>
          <p>
            We give users 3 days to select a charity for a donation made on
            their behalf. If they aren&apos;t able to select a charity within
            that time, the donation will go to our default charity,{" "}
            <a
              href={_.get(defaultCharity, "websiteUrl")}
              target="_blank"
              rel="noopener noreferrer"
            >
              {_.get(defaultCharity, "name")}
            </a>
            .
          </p>
        </div>
      ),
    },
    {
      id: "uwBJVcTk4",
      q: "Are donations tax deductible?",
      a: (
        <div>
          <p>
            Yes! 100% of a charity donation made on Rippl.me (including
            third-party fees) is tax deductible in accordance with the
            applicable federal, state, and local statutes. After making a
            donation, you&apos;ll receive a receipt for tax purposes via email.
          </p>
          <p>
            Tips added to a donation to help us pay for servers and
            infrastructure are not tax deductible at this time but are very much
            appreciated.
          </p>
        </div>
      ),
    },
    {
      id: "06qLA48ad",
      q: "Do you have a mobile app?",
      a: (
        <div>
          <p>
            Yes! This <em>is</em> a mobile app. You can add Rippl.me to the
            homescreen on your mobile device without having to download and
            install a separate app from an app store. Once added to your
            homescreen, this will look and behave like any other app you&apos;ve
            ever installed.
          </p>
          <p>On Apple devices:</p>
          <ol>
            <li>
              From Safari, click the Share icon at the bottom of the browser (or
              on the address bar for iPads).
            </li>
            <li>Then select the &quot;Add to Homescreen&quot; option.</li>
            <li>Accept the default name and click &quot;Add&quot;.</li>
          </ol>
          <p>On Android devices:</p>
          <ol>
            <li>
              From the main browser menu, select &quot;Add to Homescreen&quot;.
            </li>
            <li>Accept the default name and click &quot;Add&quot;.</li>
          </ol>
        </div>
      ),
    },
  ];

  return (
    <div id="faq" className="view long with-nav wide">
      <div className="inner">
        <h2>Frequently Asked Questions</h2>

        <div id="questions">
          {questions.map((question) => (
            <ExpansionPanel
              name={question.id}
              key={question.id}
              defaultExpanded={target === question.id}
            >
              <ExpansionPanelSummary>{question.q}</ExpansionPanelSummary>
              <ExpansionPanelDetails>{question.a}</ExpansionPanelDetails>
            </ExpansionPanel>
          ))}
        </div>

        <p id="contact" className="small">
          Have a question that isn&apos;t answered here?{" "}
          <Button className="linkish" onClick={() => history.push("/contact")}>
            Get in touch with us
          </Button>{" "}
          and we&apos;ll be happy to answer it!
        </p>

        {returnPath && (
          <Button variant="contained" color="primary" onClick={go}>
            Back
          </Button>
        )}
      </div>
    </div>
  );
}

export default Faq;
