import React from "react";
import "./assets/css/CauseIcons.css";
import { Tooltip } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSeedling } from "@fortawesome/free-solid-svg-icons";
import { faDove } from "@fortawesome/free-solid-svg-icons";
import { faHeartbeat } from "@fortawesome/free-solid-svg-icons";
import { faFlask } from "@fortawesome/free-solid-svg-icons";
import { faPaw } from "@fortawesome/free-solid-svg-icons";
import { faTheaterMasks } from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";
import _ from "lodash";

function CauseIcons({ id, className, impact }) {
  const icons = {
    Animals: faPaw,
    Arts: faTheaterMasks,
    Environment: faSeedling,
    Health: faHeartbeat,
    Humanitarian: faDove,
    Science: faFlask,
  };

  const sortedImpact = _.orderBy(impact, "donations", "desc");

  return (
    <div id={id} className={classNames("cause-icons", className)}>
      {sortedImpact.map((imp, i) => {
        if (!imp.cause || i > 2) return null;

        return (
          <Tooltip key={imp.cause} title={imp.cause}>
            <span>
              <FontAwesomeIcon icon={icons[imp.cause]} />
            </span>
          </Tooltip>
        );
      })}
    </div>
  );
}

export default CauseIcons;
