import React from "react";
import "./assets/css/BottomLinks.css";
import { useHistory } from "react-router-dom";
import { Button } from "@material-ui/core";
import util from "./util";

function BottomLinks({ returnPath, contact }) {
  const history = useHistory();

  function go(path) {
    util.scrollTop();
    history.push(path, { goto: returnPath });
  }

  return (
    <div className="bottom-links">
      <Button onClick={() => go("/faq")}>FAQ</Button>
      <Button onClick={() => go("/about")}>About Rippl.me</Button>
      <Button onClick={() => go("/privacy")}>Privacy</Button>
      {contact && <Button onClick={() => go("/contact")}>Contact Us</Button>}
    </div>
  );
}

export default BottomLinks;
