import React from "react";
import { useRouteMatch, useHistory } from "react-router-dom";
import "../assets/css/PifRegister.css";
import { Button } from "@material-ui/core";
import RegisterForm from "../RegisterForm";
import util from "../util";

function PifRegister({ onUpdateActee }) {
  const { url } = useRouteMatch();
  const history = useHistory();
  const base = util.getParentPath(url);

  async function onUserCreated(user) {
    onUpdateActee(user);
    history.replace(`${base}/billing`);
  }

  return (
    <div id="pif-register" className="view">
      <div className="inner">
        <h2>Let&apos;s Make it Official</h2>
        <p>Create your Rippl.me account.</p>
        <p id="login-link" className="subtext">
          Already have an account? Log in{" "}
          <Button
            className="linkish"
            onClick={() => history.replace(`${base}/login`)}
          >
            here
          </Button>
          .
        </p>

        <RegisterForm onUserCreated={onUserCreated} />
      </div>
    </div>
  );
}

export default PifRegister;
