import React, { useState, useEffect, useContext } from "react";
import { useParams, useHistory, useRouteMatch } from "react-router-dom";
import "../assets/css/Act.css";
import { AppContext } from "../App";
import Panel from "../Panel";
import ImpactPanel from "../ImpactPanel";
import PanelList from "../PanelList";
import Breadcrumbs from "../Breadcrumbs";
import ClickableTooltip from "../ClickableTooltip";
import ErrorBar from "../ErrorBar";
import NotFound from "./NotFound";
import Spin from "../Spin";
import util from "../util";
import { Button } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRibbon } from "@fortawesome/free-solid-svg-icons";
import { faCaretUp } from "@fortawesome/free-solid-svg-icons";
import _ from "lodash";

function Act({ acts }) {
  const history = useHistory();
  const { url } = useRouteMatch();
  const { aid } = useParams();
  const api = useContext(AppContext).api;
  const act = _.find(acts, (it) => it.id === aid);
  const [childActs, setChildActs] = useState([
    {
      id: "asdf",
      actor: { firstName: "Xxxx" },
      actee: { firstName: "Xxxx" },
      description: "Xxxx xxxx xx x xxxx xxxxxx xxxx xxxxx x xxxx.",
      donation: {
        amount: 0,
      },
      createdAt: new Date(),
    },
  ]);
  const [impact, setImpact] = useState([
    { cause: null, acts: 0, donations: 0 },
  ]);
  const [prevImpact, setPrevImpact] = useState([
    { cause: null, acts: 0, donations: 0 },
  ]);
  const [loadingChildren, setLoadingChildren] = useState(!!act.completed);
  const [loadingImpact, setLoadingImpact] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (!act) return;
    (async () => {
      try {
        setChildActs(await api.getChildActs(act.id));
      } catch (err) {
        setError(true);
        util.error(err, `Failed to get child acts for act: ${act.id}`);
      }
      setLoadingChildren(false);
    })();
  }, [api, act]);

  useEffect(() => {
    if (!act) return;
    (async () => {
      try {
        const [impact, prevImpact] = await Promise.all([
          api.getActImpact(act.id),
          api.getActImpact(
            act.id,
            new Date(new Date().valueOf() - 7 * 24 * 3600000)
          ),
        ]);
        setImpact(impact);
        setPrevImpact(prevImpact);
      } catch (err) {
        setError(true);
        util.error(err, `Failed to impact for act: ${act.id}`);
      }
      setLoadingImpact(false);
    })();
  }, [api, act]);

  return (
    <>
      {!act ? (
        <NotFound />
      ) : (
        <div id="act" className="view with-nav long expand">
          <div className="inner">
            <Breadcrumbs
              links={[{ copy: "Your Acts of Kindness", url: "/acts" }]}
            />
            <Panel id="description" segmented>
              <Panel.Segment
                primary
                label="You helped:"
                value={act.actee.firstName}
                footer={util.formatDate(act.createdAt)}
              />
              <Panel.Segment copy label="How:" value={act.description} />
            </Panel>

            <ImpactPanel
              id="impact"
              heading="Your Ripple Effect"
              helpText="This is the total impact that this single act of kindness has created in the world as people have continued to pay it forward."
              loading={loadingImpact}
              impact={impact}
              prevImpact={prevImpact}
              subheading={
                <>
                  <FontAwesomeIcon icon={faCaretUp} />
                  last 7 days
                </>
              }
            />

            <div className="panel-nav">
              <Button
                className="linkish"
                onClick={() => history.push(`${url}/ripple`)}
              >
                View Ripple
              </Button>
            </div>

            <Panel
              id="donation"
              segmented
              filled
              heading={`${act.actee.firstName}'s Donation`}
              helpText={`This is how much ${act.actee.firstName} donated to charity to thank you for your act of kindness.`}
            >
              <Panel.Segment
                metric
                label={`${util
                  .pronoun(act.actee, "he")
                  .replace(/^\w/, (c) => c.toUpperCase())} donated:`}
                value={
                  <>
                    <span>
                      <span className="dollar-sign">$</span>
                      {util.toDollarStr(act.donation.amount)}
                    </span>
                  </>
                }
                footer={
                  !!act.donation.fee &&
                  `+ $${util.toDollarStr(act.donation.fee)} for Rippl.me`
                }
              />
              <Panel.Segment
                label="You selected:"
                value={
                  <div>
                    {act.donation.status === "PendingCharitySelection" ? (
                      <Button
                        onClick={() =>
                          history.push(`/select-charity/${act.id}`)
                        }
                      >
                        Please select a charity
                      </Button>
                    ) : (
                      <>
                        {act.donation.charity.logoUrl ? (
                          <img src={act.donation.charity.logoUrl} alt="" />
                        ) : (
                          <FontAwesomeIcon icon={faRibbon} />
                        )}

                        <div>{act.donation.charity.name}</div>
                      </>
                    )}
                  </div>
                }
              />
            </Panel>

            <Panel
              id="pledge"
              segmented
              filled
              heading={`${act.actee.firstName}'s Pledge`}
              helpText={`This is how many times ${act.actee.firstName} pledged to pay it forward.`}
            >
              <Panel.Segment
                metric
                value={act.pledge}
                footer={`Act${act.pledge !== 1 ? "s" : ""} of Kindness`}
              />
              <Panel.Segment metric value={act.completed} footer="Completed" />
            </Panel>

            {!!act.completed && !error && (
              <PanelList id="child-acts" loading={loadingChildren}>
                {childActs.map((act) => (
                  <PanelList.Item key={act.id}>
                    <Panel.Segment
                      primary
                      label={`${act.actor.firstName} helped:`}
                      value={act.actee.firstName}
                      footer={util.formatDate(act.createdAt)}
                    />
                    <Panel.Segment
                      copy
                      label="How:"
                      value={
                        act.description.length > 120 ? (
                          <>
                            {act.description.substring(0, 119)}...
                            <ClickableTooltip title={act.description}>
                              {" "}
                              <span className="more-copy">more</span>
                            </ClickableTooltip>
                          </>
                        ) : (
                          act.description
                        )
                      }
                    />
                    <Panel.Segment
                      metric
                      label={`${act.actor.firstName} raised:`}
                      value={
                        <>
                          <span>
                            <span className="dollar-sign">$</span>
                            {util.toDollarStr(act.donation.amount)}
                          </span>
                        </>
                      }
                    />
                  </PanelList.Item>
                ))}
                <Spin />
              </PanelList>
            )}
          </div>
        </div>
      )}
      <ErrorBar error={error} />
    </>
  );
}

export default Act;
