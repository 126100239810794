import React from "react";
import "../assets/css/Login.css";
import { useHistory, useLocation } from "react-router-dom";
import LoginForm from "../LoginForm";
import { Button } from "@material-ui/core";
import util from "../util";
import _ from "lodash";

function Login() {
  const history = useHistory();
  const location = useLocation();

  function onLoggedIn() {
    util.scrollTop();
    history.replace(_.get(location, "state.goto") || "/dashboard");
  }

  return (
    <div id="login" className="view">
      <div className="header">
        <h1>Login</h1>
      </div>

      <LoginForm onLoggedIn={onLoggedIn} />

      <div id="forgot">
        <Button onClick={() => history.push("/forgot-password")}>
          Forgot your password?
        </Button>
      </div>
    </div>
  );
}

export default Login;
