import React from "react";
import "./assets/css/Panel.css";
import ClickableTooltip from "./ClickableTooltip";
import LeftArrow from "./assets/images/left-arrow.svg";
import RightArrow from "./assets/images/right-arrow.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-regular-svg-icons";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";

function Panel({
  id,
  className,
  alert,
  heading,
  subheading,
  icon,
  align,
  textAlign,
  withArrow,
  segmented,
  children,
  onClick,
  helpText,
  loading,
  filled,
}) {
  return (
    <div
      id={id}
      className={classNames("panel", align, className, {
        alert,
        loading,
        [`text-${textAlign}`]: textAlign,
        "with-heading": heading,
        clickable: onClick,
        segmented,
        filled,
      })}
      onClick={onClick}
    >
      {children}
      {icon && (
        <div className="icon">
          <FontAwesomeIcon icon={icon} />
        </div>
      )}
      {heading && (
        <div className="heading">
          {heading}
          {helpText && (
            <ClickableTooltip title={helpText}>
              <div className="help-icon">
                <FontAwesomeIcon icon={faQuestionCircle} />
              </div>
            </ClickableTooltip>
          )}
        </div>
      )}
      {subheading && <div className="subheading">{subheading}</div>}
      {withArrow && (
        <div className="arrow">
          <img src={align === "left" ? RightArrow : LeftArrow} alt="" />
        </div>
      )}
    </div>
  );
}

function Body({ children }) {
  return <div className="body">{children}</div>;
}

function Section({ children }) {
  return <div className="section">{children}</div>;
}

function Segment({
  id,
  label,
  value,
  footer,
  primary,
  copy,
  metric,
  completed,
  clickable,
  onClick,
  tooltip,
  children,
}) {
  const valueElem = (
    <div className={classNames("value", { metric })}>{children || value}</div>
  );

  return (
    <div
      id={id}
      className={classNames("segment", {
        primary,
        copy,
        nested: !!children,
        completed,
        clickable: clickable || tooltip || onClick,
      })}
      onClick={onClick}
    >
      {label && <div className="label">{label}</div>}
      {tooltip ? (
        <ClickableTooltip title={tooltip} placement="top">
          {valueElem}
        </ClickableTooltip>
      ) : (
        valueElem
      )}
      {footer && <div className="footer">{footer}</div>}
      {(clickable || onClick) && (
        <div className="caret">
          <FontAwesomeIcon icon={faChevronRight} />
        </div>
      )}
    </div>
  );
}

Panel.Body = Body;
Panel.Section = Section;
Panel.Segment = Segment;
export default Panel;
