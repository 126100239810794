import React, { useState, useCallback, useContext } from "react";
import { useParams, useHistory } from "react-router-dom";
import { AppContext } from "../App";
import FloatingInput from "../FloatingInput";
import util from "../util";
import Spin from "../Spin";
import { Button } from "@material-ui/core";

function ResetPassword() {
  const api = useContext(AppContext).api;
  const history = useHistory();
  const { token } = useParams();
  const [login, setLogin] = useState({});
  const [loading, setLoading] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const [error, setError] = useState(false);
  const [done, setDone] = useState(false);
  const [invalidFields, setInvalidFields] = useState([]);
  const handleValidate = useCallback(util.handleValidate(setInvalidFields), []);

  const passwordValidate = useCallback(
    (val) => {
      return val === login.password ? null : "Passwords do not match";
    },
    [login]
  );

  function handleChange(field, value) {
    setLogin((prev) => ({ ...prev, [field]: value }));
  }

  async function handleSubmit(e) {
    e.preventDefault();
    if (invalidFields.length) return;
    setDone(false);
    setError(false);
    setNotFound(false);
    setLoading(true);

    try {
      await api.resetPassword(token, login.password);
      setDone(true);
    } catch (err) {
      if (err.status === 404) {
        setNotFound(true);
      } else {
        setError(true);
        util.error(err, `Failed to reset password for token: ${token}`);
      }
    }
    setLoading(false);
  }

  return (
    <div id="reset-password" className="view">
      <div className="inner">
        {done ? (
          <p>
            All set! Please{" "}
            <Button
              className="linkish thin"
              onClick={() => history.push("/login")}
            >
              login here
            </Button>{" "}
            with your new password.
          </p>
        ) : (
          <>
            <h1>Password Reset</h1>
            <p>Select a new password.</p>

            <form
              className={loading ? "loading" : ""}
              noValidate
              onSubmit={(e) => {
                e.preventDefault();
              }}
            >
              <fieldset>
                <div className="input-group">
                  <FloatingInput
                    name="password"
                    label="Password"
                    type="password"
                    value={login.password}
                    onChange={handleChange}
                    required
                    maxLength={120}
                    onValidate={handleValidate}
                    autoComplete="new-password"
                  />
                </div>
                <div className="input-group">
                  <FloatingInput
                    name="passwordConfirm"
                    label="Confirm Password"
                    type="password"
                    value={login.passwordConfirm}
                    onChange={handleChange}
                    required
                    maxLength={120}
                    onValidate={handleValidate}
                    validate={passwordValidate}
                    autoComplete="new-password"
                  />
                </div>
                <Spin />
              </fieldset>
              {notFound && (
                <div className="form-msg">
                  Oops! Looks like your password reset link may have expired.
                  Please{" "}
                  <Button
                    className="linkish"
                    onClick={() => history.push("/forgot-password")}
                  >
                    request a new one
                  </Button>
                  .
                </div>
              )}
              {error && (
                <div className="form-msg">
                  Oops! Looks like something went wrong. We&apos;re on it.
                  Please try again later.
                </div>
              )}
              <Button
                type="button"
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                disabled={loading || invalidFields.length > 0}
              >
                Submit
              </Button>
            </form>
          </>
        )}
      </div>
    </div>
  );
}

export default ResetPassword;
