import React, { useContext, useState } from "react";
import "../assets/css/InviteLand.css";
import { useHistory, useRouteMatch } from "react-router-dom";
import { AppContext, UserContext } from "../App";
import BottomLinks from "../BottomLinks";
import ErrorBar from "../ErrorBar";
import Loading from "./Loading";
import { Button, Grid, Card } from "@material-ui/core";
import util from "../util";

function InviteLand({ act }) {
  const appContext = useContext(AppContext);
  const { url } = useRouteMatch();
  const history = useHistory();
  const userContext = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  async function start() {
    if (userContext.user) {
      setLoading(true);
      try {
        await appContext.api.claimAct(act);
        history.replace(`/select-charity/${act.id}/search`);
      } catch (err) {
        if (err.status === 400)
          return history.replace(history.replace(`/select-charity/${act.id}`));
        setError(true);
        setLoading(false);
        util.error(err, `Failed to claim act: ${act.id}`);
      }
    } else {
      history.push(`${url}/register`);
    }
  }

  return loading ? (
    <Loading />
  ) : (
    <div id="invite-land" className="view long with-nav">
      <div className="inner">
        <p>Hi {act.actor.firstName}!</p>
        <p>
          Looks like you&apos;ve recently performed an{" "}
          <span className="buzzword">act of kindness</span> for{" "}
          {act.actee.firstName}. To thank you, {act.actee.firstName} has pledged
          to pay it forward by performing an act of kindness for{" "}
          <span className="buzzword">{act.pledge}</span> other{" "}
          {act.pledge > 1 ? "people" : "person"} and has made a{" "}
          <span className="buzzword">
            ${util.toDollarStr(act.donation.amount)}
          </span>{" "}
          donation to charity on your behalf.
        </p>
        <p>
          Here&apos;s how {act.actee.firstName} said you helped{" "}
          {util.pronoun(act.actee, "him")}:
        </p>
        <Grid container justify="center">
          <Card>
            <p>{act.description}</p>
          </Card>
        </Grid>

        <p>
          The next step is for you to select a charity for{" "}
          {`${act.actee.firstName}'s`} donation.
        </p>
        <Button variant="contained" color="primary" onClick={start}>
          Select a Charity
        </Button>
        <p className="small">
          Once you&apos;ve done that, you&apos;ll be able to track the progress
          of {act.actee.firstName}&apos;s pay-it-forward pledge and follow the
          ripple effect your act of kindness creates as people continue to pay
          it forward.
        </p>
        <BottomLinks returnPath={url} />
        <ErrorBar error={error} />
      </div>
    </div>
  );
}

export default InviteLand;
