import React from "react";
import ReactDOM from "react-dom";
import "./assets/css/index.css";
import "./assets/css/light.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import util from "./util";
import _ from "lodash";
import { createBrowserHistory } from "history";
import ReactGA from "react-ga";
import "promise-polyfill/src/polyfill";
import Cookies from "js-cookie";

const browserHistory = createBrowserHistory();
const isIE11 = !!window.MSInputMethodContext && !!document.documentMode;
const ui = Cookies.getJSON("ui");
const userCookie = Cookies.getJSON("user");

if (isIE11) {
  window.location.href = "/ie.html";
} else {
  init();
  ReactDOM.render(
    <App
      prod={window.location.hostname === "rippl.me"}
      browserHistory={browserHistory}
      defaultUi={ui || {}}
      loggedIn={userCookie && userCookie.loggedIn}
    />,
    document.getElementById("rippl-root")
  );
}

function init() {
  /*
   * Set up GA tracking.
   */
  ReactGA.initialize("UA-143026056-1");
  ReactGA.set({ page: browserHistory.location.pathname });
  if (userCookie) ReactGA.set({ dimension1: 1 });
  ReactGA.pageview(browserHistory.location.pathname);
  console.debug(`pageView: ${browserHistory.location.pathname}`);
  browserHistory.listen((location) => {
    console.debug(`pageView: ${location.pathname}`);
    ReactGA.set({ page: location.pathname });
    ReactGA.pageview(location.pathname);
  });

  /*
   * Handle viewport sizing.
   */
  window.setHeight = () => {
    const rootHeight = window.innerHeight;
    const el = document.getElementById("rippl-root");
    el.style.height = `${rootHeight}px`;

    const view = document.getElementsByClassName("view").item(0);
    if (view) {
      view.style["justify-content"] =
        view.scrollHeight > rootHeight ? "start" : "";
    }
  };
  function setHeightDelayed(delay) {
    setTimeout(window.setHeight, delay);
  }
  window.addEventListener("resize", _.debounce(window.setHeight, 100));
  window.addEventListener("orientationchange", () => setHeightDelayed(500));
  document.addEventListener("focusout", () => setHeightDelayed(250));

  /*
   * Set ui theme.
   */
  if (ui && ui.theme === "light") {
    const body = document.getElementById("rippl");
    body.classList.add("light");
  }

  /*
   * Log any script error to Google Analytics.
   */
  const originalWindowErrorCallback = window.onerror;
  window.onerror = function customErrorHandler(
    errorMessage,
    url,
    lineNumber,
    columnNumber,
    errorObject
  ) {
    let exceptionDescription = errorMessage;
    if (errorObject && errorObject.message) {
      exceptionDescription = errorObject.message;
    }
    exceptionDescription += " @ " + url + ":" + lineNumber + ":" + columnNumber;
    util.error(null, exceptionDescription);

    if (typeof originalWindowErrorCallback === "function") {
      return originalWindowErrorCallback(
        errorMessage,
        url,
        lineNumber,
        columnNumber,
        errorObject
      );
    }
    return false;
  };

  /*
   * Log any unhalndled Promise rejections.
   */
  window.addEventListener("unhandledrejection", (e) => {
    let msg = "Unhandled rejected Promise";
    if (e.reason.status) msg += ": " + e.reason.status;
    if (e.reason.statusText) msg += ": " + e.reason.statusText;
    util.error(e.reason, msg);
  });

  serviceWorker.unregister();
}
