import React, { useState, useContext, useCallback } from "react";
import { AppContext, UserContext } from "./App";
import FloatingInput from "./FloatingInput";
import util from "./util";
import Spin from "./Spin";
import { Button } from "@material-ui/core";
import _ from "lodash";

function LoginForm({ onFailedAttempt, onLoggedIn }) {
  const api = useContext(AppContext).api;
  const userContext = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [login, setLogin] = useState({});
  const [denied, setDenied] = useState(false);
  const [invalidFields, setInvalidFields] = useState([]);
  const handleValidate = useCallback(util.handleValidate(setInvalidFields), []);

  function handleChange(field, value) {
    setLogin((prev) => ({ ...prev, [field]: value }));
  }

  async function handleSubmit(e) {
    e.preventDefault();
    if (invalidFields.length) return;
    setLoading(true);

    if (!(await api.login(login.email.toLowerCase(), login.password))) {
      if (_.isFunction(onFailedAttempt)) onFailedAttempt();
      setDenied(true);
      setLoading(false);
      return;
    }
    const user = await api.getUser();

    setLoading(false);
    userContext.setUser(user);
    onLoggedIn(user);
  }

  return (
    <form
      className={loading ? "loading" : ""}
      noValidate
      onSubmit={(e) => {
        e.preventDefault();
      }}
    >
      <fieldset>
        <div className="input-group">
          <FloatingInput
            name="email"
            label="Email"
            type="email"
            value={login.email}
            onChange={handleChange}
            required
            maxLength={255}
            onValidate={handleValidate}
            autoComplete="username"
          />
          <FloatingInput
            name="password"
            label="Password"
            type="password"
            value={login.password}
            onChange={handleChange}
            required
            maxLength={120}
            onValidate={handleValidate}
            autoComplete="current-password"
          />
        </div>
        {denied && <div className="form-msg">Incorrect email or password</div>}
        <Spin />
      </fieldset>
      <Button
        type="button"
        variant="contained"
        color="primary"
        onClick={handleSubmit}
        disabled={loading || invalidFields.length > 0}
      >
        Log In
      </Button>
    </form>
  );
}

export default LoginForm;
