import React from "react";

function Contact() {
  return (
    <div id="privacy" className="view with-nav">
      <div className="inner">
        <h2>We&apos;d love to hear from you!</h2>
        <p>
          We&apos;re constantly trying to improve the Rippl.me experience, and
          we&apos;d love to hear your ideas, what you like, or what you
          don&apos;t like.
        </p>
        <p>
          Drop us a line at{" "}
          <a
            href="mailto:hello@rippl.me"
            target="_blank"
            rel="noopener noreferrer"
          >
            hello@rippl.me
          </a>
          , or visit us on{" "}
          <a
            href="https://www.facebook.com/rippl.me"
            target="_blank"
            rel="noopener noreferrer"
          >
            Facebook
          </a>
          .
        </p>
      </div>
    </div>
  );
}

export default Contact;
