import React from "react";

function SelectCharityTimedOut({ act }) {
  return (
    <div id="select-charity-timed-out" className="view with-nav">
      <div className="inner">
        <>
          <h1>Oops</h1>
          {act.donation.timedOut ? (
            <p>
              We didn&apos;t hear from you in time to select a charity for{" "}
              {act.actee.firstName}
              &apos;s donation so it went to our default charity,{" "}
              <a
                className="nowrap"
                href={act.donation.charity.websiteUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                {act.donation.charity.name}
              </a>
              .
            </p>
          ) : (
            <p>
              Looks like you already selected a charity for{" "}
              {act.actee.firstName}
              &apos;s donation.
            </p>
          )}
        </>
      </div>
    </div>
  );
}

export default SelectCharityTimedOut;
