import React from "react";
import "./assets/css/RotatePhoneIcon.css";

function RotatePhoneIcon() {
  return (
    <div className="rotate-phone-icon">
      <svg version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 16 16">
        <path d="M3.895 12.511a.5.5 0 0 0-.383.595l.259 1.201A6.407 6.407 0 0 1 1 9a.5.5 0 0 0-1 0 7.519 7.519 0 0 0 2.896 5.916l-1.426.085A.5.5 0 0 0 1.5 16l.03-.001 3-.18a.5.5 0 0 0 .459-.604l-.5-2.32a.503.503 0 0 0-.594-.384z" />
        <path d="M13.104 1.084L14.53.999A.5.5 0 0 0 14.5 0l-.03.001-3 .18a.5.5 0 0 0-.459.604l.5 2.32a.5.5 0 0 0 .977-.211l-.258-1.201A6.409 6.409 0 0 1 15 7a.5.5 0 0 0 1 0 7.519 7.519 0 0 0-2.896-5.916z" />
        <path d="M7.293 1.636a1 1 0 0 0-1.414 0L1.636 5.879a1 1 0 0 0 0 1.414l7.071 7.071a1 1 0 0 0 1.414 0l4.243-4.243a1 1 0 0 0 0-1.414L7.293 1.636zM8.707 12.95L3.05 7.293 7.293 3.05l5.657 5.657-4.243 4.243z" />
      </svg>
    </div>
  );
}

export default RotatePhoneIcon;
