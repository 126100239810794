import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import "../assets/css/Dashboard.css";
import Loading from "./Loading";
import ErrorPage from "./Error";
import Panel from "../Panel";
import ImpactPanel from "../ImpactPanel";
import RippleImage from "../RippleImage";
import { AppContext } from "../App";
import { Button } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretUp } from "@fortawesome/free-solid-svg-icons";
import util from "../util";
import Promise from "bluebird";
import _ from "lodash";
import classNames from "classnames";

function Dashboard() {
  const appContext = useContext(AppContext);
  const light = _.get(appContext, "ui.theme") === "light";
  const api = appContext.api;
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [openDonations, setOpenDonations] = useState([]);
  const [impact, setImpact] = useState({});
  const [prevImpact, setPrevImpact] = useState({});
  const [openPledges, setOpenPledges] = useState([]);
  const [activities, setActivities] = useState([]);
  const [impactGraph, setImpactGraph] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        const [
          pendingDonations,
          impact,
          activities,
          openPledges,
          prevImpact,
          impactGraph,
        ] = await Promise.all([
          api.getPendingDonations(),
          api.getUserImpact(),
          api.getRecentActivity(),
          api.getOpenPledges(),
          api.getUserImpact(new Date(new Date().valueOf() - 7 * 24 * 3600000)),
          api.getUserImpactGraph(),
        ]);
        setOpenDonations(pendingDonations);
        setImpact(impact);
        setActivities(activities);
        setOpenPledges(openPledges);
        setPrevImpact(prevImpact);
        setImpactGraph(util.getGraphMetrics(impactGraph));
      } catch (err) {
        setError(true);
        util.error(err, "Failed to get data for dashboard");
      }
      setLoading(false);
    })();
  }, [api]);

  return (
    <>
      {loading ? (
        <Loading />
      ) : error ? (
        <ErrorPage />
      ) : (
        <div id="dashboard" className="view with-nav long">
          <div className="inner">
            <h3>Dashboard</h3>

            <Panel alert heading="Next Steps">
              <ul>
                {openDonations.map((donation) => {
                  return (
                    <li key={donation.id}>
                      <Button
                        className="linkish"
                        onClick={() =>
                          history.push(`/select-charity/${donation.act.id}`)
                        }
                      >
                        Select a charity
                      </Button>{" "}
                      for {`${donation.act.actee.firstName}'s`} donation
                    </li>
                  );
                })}
                {openPledges.map((act) => {
                  const num = act.pledge - act.completed;
                  return (
                    <li key={act.id}>
                      <Button
                        className="linkish"
                        onClick={() => history.push("/invite")}
                      >
                        Pay forward
                      </Button>{" "}
                      {act.actor.firstName}&apos;s act of kindness to{" "}
                      {num > 1
                        ? `${num} ${
                            act.completed > 0 ? "more" : "other"
                          } people`
                        : act.pledge > 1
                        ? "one more person"
                        : "someone else"}
                    </li>
                  );
                })}
                {!openDonations.length && !openPledges.length && (
                  <li>
                    {impact.direct.length || openPledges.length ? (
                      <>
                        Why not{" "}
                        <Button
                          className="linkish"
                          onClick={() => history.push("/ripple")}
                        >
                          start a new ripple
                        </Button>{" "}
                        and expand your impact?
                      </>
                    ) : (
                      <>
                        <Button
                          className="linkish"
                          onClick={() => history.push("/ripple")}
                        >
                          Start a new ripple
                        </Button>{" "}
                        and create a chain reaction of kindness!
                      </>
                    )}
                  </li>
                )}
              </ul>
            </Panel>

            {!!openPledges.length && (
              <Panel
                id="pledges"
                segmented
                filled
                heading="Your Open Pledges"
                helpText="These are acts of kindness that you've pledged to complete to pay forward acts of kindness that others have done for you."
              >
                <Panel.Segment
                  metric
                  value={_.sumBy(openPledges, "pledge")}
                  footer="Acts of Kindness"
                  onClick={() => history.push("/pledges")}
                />
                <Panel.Segment
                  metric
                  value={_.sumBy(openPledges, "completed")}
                  footer="Completed"
                />
                <Panel.Segment
                  metric
                  value={
                    _.sumBy(openPledges, "pledge") -
                    _.sumBy(openPledges, "completed")
                  }
                  footer="To Go"
                  onClick={() => history.push("/invite")}
                />
              </Panel>
            )}

            <ImpactPanel
              id="direct-impact"
              heading="Your Direct Effect"
              helpText="This is the impact you've made directly through your own acts of kindness."
              impact={impact.direct}
              prevImpact={prevImpact.direct}
              onClick={() => history.push("/acts")}
              subheading={
                <>
                  <FontAwesomeIcon icon={faCaretUp} />
                  last 7 days
                </>
              }
            />

            {!!impactGraph.length && (
              <Panel
                id="ripple-effect"
                heading="Your Ripple Effect"
                helpText={`This is the total impact that you've created in the world as people have continued to pay forward your acts of kindness. Each ring is a new generation in a pay-it-forward chain. The width of the ring shows how many acts of kindness were performed in that generation, with the ${
                  light ? "darkness" : "brightness"
                } of the ring showing how much money was raised for charity. The space between the rings shows the time between each generation.`}
              >
                <RippleImage impactGraph={impactGraph} />
              </Panel>
            )}

            <ImpactPanel
              id="total-impact"
              heading={!impactGraph.length && "Your Ripple Effect"}
              helpText={
                !impactGraph.length &&
                "This is the total impact that you've created in the world as people have continued to pay forward your acts of kindness."
              }
              className={classNames({ "with-graphic": !!impactGraph.length })}
              impact={impact.total}
              prevImpact={prevImpact.total}
              subheading={
                <>
                  <FontAwesomeIcon icon={faCaretUp} />
                  last 7 days
                </>
              }
            />

            {activities.length > 0 && (
              <Panel heading="Recent Activity">
                <ul>
                  {activities.map((activity) => {
                    return (
                      <React.Fragment key={activity.id}>
                        {activity.type === "Pledged" && (
                          <li>
                            {activity.act.actee.firstName} pledged to pay
                            forward your act of kindness to{" "}
                            {activity.act.pledge > 1
                              ? `${activity.act.pledge} other people`
                              : "one other person"}{" "}
                            and made a $
                            {util.toDollarStr(activity.act.donation.amount)}{" "}
                            donation.
                            <div className="date">
                              {util.formatDate(activity.createdAt)}
                            </div>
                          </li>
                        )}
                        {activity.type === "DonationProcessed" &&
                          !activity.act.donation.timedOut && (
                            <li>
                              {activity.act.actor.firstName} selected{" "}
                              {activity.act.donation.charity.name} as the
                              charity for your $
                              {util.toDollarStr(activity.act.donation.amount)}{" "}
                              donation and your transaction has been processed.
                              <div className="date">
                                {util.formatDate(activity.createdAt)}
                              </div>
                            </li>
                          )}
                        {activity.type === "DonationProcessed" &&
                          activity.act.donation.timedOut && (
                            <li>
                              Your $
                              {util.toDollarStr(activity.act.donation.amount)}{" "}
                              donation for {activity.act.actor.firstName}&apos;s
                              act of kindness went to our default charity{" "}
                              {activity.act.donation.charity.name} and your
                              transaction has been processed.
                              <div className="date">
                                {util.formatDate(activity.createdAt)}
                              </div>
                            </li>
                          )}
                        {activity.type === "PaidForward" && (
                          <li>
                            {activity.act.actor.firstName} paid forward your act
                            of kindness to {activity.act.actee.firstName} and
                            raised $
                            {util.toDollarStr(activity.act.donation.amount)} for
                            charity.
                            <div className="date">
                              {util.formatDate(activity.createdAt)}
                            </div>
                          </li>
                        )}
                        {activity.type === "CompletedPledge" && (
                          <li>
                            {activity.act.actor.firstName} paid forward your act
                            of kindness to {activity.act.actee.firstName},
                            raised $
                            {util.toDollarStr(activity.act.donation.amount)} for
                            charity, and completed{" "}
                            {util.pronoun(activity.act.actor, "his")}{" "}
                            pay-it-forward pledge.
                            <div className="date">
                              {util.formatDate(activity.createdAt)}
                            </div>
                          </li>
                        )}
                        {activity.type === "CharitySelectionTimedOut" && (
                          <li>
                            {activity.act.actee.firstName}&apos;s $
                            {util.toDollarStr(activity.act.donation.amount)}{" "}
                            donation went to our default charity,{" "}
                            {activity.act.donation.charity.name}.
                            <div className="date">
                              {util.formatDate(activity.createdAt)}
                            </div>
                          </li>
                        )}
                      </React.Fragment>
                    );
                  })}
                </ul>
              </Panel>
            )}
          </div>
        </div>
      )}
    </>
  );
}

/*
const testData = [
  { acts: 2, donations: 1000, started: new Date("2020-06-01T12:23:56") },
  { acts: 3, donations: 8000, started: new Date("2020-06-07T12:23:56") },
  { acts: 9, donations: 3500, started: new Date("2020-06-20T12:23:56") },
  { acts: 12, donations: 32000, started: new Date("2020-06-22T12:23:56") },
  { acts: 20, donations: 25000, started: new Date("2020-07-30T12:23:56") },
];
*/

export default Dashboard;
