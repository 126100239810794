import React, { useContext } from "react";
import { useHistory, Link } from "react-router-dom";
import "./assets/css/NavBar.css";
import RipplLogo from "./assets/images/rippl-logo.svg";
import RipplIcon from "./assets/images/rippl-icon-cutout.svg";
import { AppContext, UserContext } from "./App";
import util from "./util";
import { Menu, MenuItem, Button, IconButton, Fade } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTachometerAlt } from "@fortawesome/free-solid-svg-icons";
import { faShare } from "@fortawesome/free-solid-svg-icons";
import { faHeart } from "@fortawesome/free-regular-svg-icons";
import { faRibbon } from "@fortawesome/free-solid-svg-icons";
import { faUser } from "@fortawesome/free-regular-svg-icons";
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import { faUserShield } from "@fortawesome/free-solid-svg-icons";
import { faFileContract } from "@fortawesome/free-solid-svg-icons";
import { faQuestionCircle } from "@fortawesome/free-regular-svg-icons";
import { faHandHoldingUsd } from "@fortawesome/free-solid-svg-icons";
import { faSun as faSunLight } from "@fortawesome/free-solid-svg-icons";
import { faSun as faSunDark } from "@fortawesome/free-regular-svg-icons";

function NavBar() {
  const appContext = useContext(AppContext);
  const userContext = useContext(UserContext);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [light, setLight] = React.useState(appContext.ui.theme === "light");
  const open = !!anchorEl;
  const history = useHistory();

  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  function handleLogout() {
    handleClose();
    appContext.api.logout();
    userContext.setUser(null);
    history.push("/");
  }

  function go(to) {
    handleClose();
    util.scrollTop();
    history.push(to);
  }

  function toggleTheme() {
    appContext.ui.setUiTheme(light ? "dark" : "light");
    const body = document.getElementById("rippl");
    light ? body.classList.remove("light") : body.classList.add("light");
    util.event("UI", "SelectTheme", light ? "Dark" : "Light");
    setLight((prev) => !prev);
  }

  return (
    <Fade in>
      <div className="topnav">
        <Link to={userContext.user ? "/dashboard" : "/"} id="logo">
          <img src={RipplLogo} alt="" />
        </Link>
        <nav>
          <ul id="main-nav">
            <li>
              {userContext.user ? (
                <Button onClick={() => history.push("/dashboard")}>
                  Hi, {userContext.user.firstName}!
                </Button>
              ) : (
                <Button onClick={() => go("/login")}>Log In</Button>
              )}

              <IconButton
                aria-controls="fade-menu"
                aria-haspopup="true"
                onClick={handleClick}
              >
                <MenuIcon />
              </IconButton>
              {userContext.user ? (
                <Menu
                  id="fade-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={open}
                  onClose={handleClose}
                  TransitionComponent={Fade}
                >
                  <MenuItem onClick={() => go("/dashboard")}>
                    <div className="nav-icon">
                      <FontAwesomeIcon icon={faTachometerAlt} />
                    </div>
                    Dashboard
                  </MenuItem>
                  <MenuItem onClick={() => go("/ripple")}>
                    <div className="nav-icon">
                      <FontAwesomeIcon icon={faShare} />
                    </div>
                    Start a Ripple
                  </MenuItem>
                  <MenuItem onClick={() => go("/acts")}>
                    <div className="nav-icon">
                      <FontAwesomeIcon icon={faHeart} />
                    </div>
                    Your Acts of Kindness
                  </MenuItem>
                  <MenuItem onClick={() => go("/pledges")}>
                    <div className="nav-icon">
                      <FontAwesomeIcon icon={faRibbon} />
                    </div>
                    Your Pledges
                  </MenuItem>
                  {userContext.user.hasFundraisers > 0 && (
                    <MenuItem onClick={() => go("/fundraisers")}>
                      <div className="nav-icon">
                        <FontAwesomeIcon icon={faHandHoldingUsd} />
                      </div>
                      Your Fundraisers
                    </MenuItem>
                  )}
                  <MenuItem onClick={() => go("/profile")}>
                    <div className="nav-icon">
                      <FontAwesomeIcon icon={faUser} />
                    </div>
                    Profile
                  </MenuItem>
                  <MenuItem onClick={() => go("/faq")}>
                    <div className="nav-icon">
                      <FontAwesomeIcon icon={faQuestionCircle} />
                    </div>
                    FAQ
                  </MenuItem>
                  <MenuItem onClick={() => go("/contact")}>
                    <div className="nav-icon">
                      <FontAwesomeIcon icon={faEnvelope} />
                    </div>
                    Contact Us
                  </MenuItem>
                  <MenuItem onClick={handleLogout}>
                    <div className="nav-icon">
                      <FontAwesomeIcon icon={faSignOutAlt} />
                    </div>
                    Logout
                  </MenuItem>
                </Menu>
              ) : (
                <Menu
                  id="fade-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={open}
                  onClose={handleClose}
                  TransitionComponent={Fade}
                >
                  <MenuItem onClick={() => go("/register")}>
                    <div className="nav-icon">
                      <FontAwesomeIcon icon={faHeart} />
                    </div>
                    Join
                  </MenuItem>
                  <MenuItem onClick={() => go("/about")}>
                    <div className="nav-icon">
                      <img id="rippl-cutout" src={RipplIcon} alt="" />
                    </div>
                    About Rippl.me
                  </MenuItem>
                  <MenuItem onClick={() => go("/faq")}>
                    <div className="nav-icon">
                      <FontAwesomeIcon icon={faQuestionCircle} />
                    </div>
                    FAQ
                  </MenuItem>
                  <MenuItem onClick={() => go("/privacy")}>
                    <div className="nav-icon">
                      <FontAwesomeIcon icon={faUserShield} />
                    </div>
                    Privacy
                  </MenuItem>
                  <MenuItem onClick={() => go("/terms")}>
                    <div className="nav-icon">
                      <FontAwesomeIcon icon={faFileContract} />
                    </div>
                    Terms
                  </MenuItem>
                  <MenuItem onClick={() => go("/contact")}>
                    <div className="nav-icon">
                      <FontAwesomeIcon icon={faEnvelope} />
                    </div>
                    Contact Us
                  </MenuItem>
                </Menu>
              )}
            </li>
            <li className="theme-toggle">
              <IconButton onClick={toggleTheme}>
                <FontAwesomeIcon icon={light ? faSunDark : faSunLight} />
              </IconButton>
            </li>
          </ul>
        </nav>
      </div>
    </Fade>
  );
}

export default NavBar;
