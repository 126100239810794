import _ from "lodash";
import ReactGA from "react-ga";
import moment from "moment";

const util = {
  devContext: {
    prod: false,
    api: {
      baseUrl: "https://api-dev.rippl.me",
      pk: "VsDs3K6eVa5690effhJv55JNArJcBkTI4iAgXWov",
    },
    stripe: {
      clientId: "ca_G86UTwdQ3u6B4khzHJJIZWVxKyW2MF5D",
      pkey: "pk_test_hrwHqfTH1JOLmDPrRFBopkw800Jymlv7ax",
      account: "acct_1GCWtPBFAEkpb0uH",
    },
  },

  prodContext: {
    prod: true,
    api: {
      baseUrl: "https://api.rippl.me",
      pk: "Po88C7Ezmi34pvkdTw7wNg01IGICc8YrbfaZw500",
    },
    stripe: {
      clientId: "ca_G86UxRRS7YQJqnWnx9RVnvkk48MOsLHN",
      pkey: "pk_live_na34KfJRtlh3nWjOSbbPNLTx00FQIhytCD",
      account: "acct_1GCWtPBFAEkpb0uH",
    },
  },

  handleValidate(setInvalidFields) {
    return (fieldName, valid) => {
      setInvalidFields((prev) => [
        ...new Set(
          valid ? prev.filter((it) => it !== fieldName) : [...prev, fieldName]
        ),
      ]);
    };
  },

  isValidValue(validate, val) {
    return _.isFunction(validate)
      ? !validate(val)
      : new RegExp(validate).test(val || "");
  },

  getUrlParameter(name) {
    const match = window.location.href.match("[?&]" + name + "=([^&]*)");
    if (match) return decodeURIComponent(match[1]).replace(/\+/g, " ");
  },

  getParentPath(path) {
    const match = path.match(/(\/.+)\//);
    return match ? match[1] : path;
  },

  scrollTop(smooth) {
    const opts = {
      top: 0,
      left: 0,
    };
    if (smooth) opts.behavior = "smooth";
    window.scroll(opts);
  },

  pronoun(user, which) {
    if (!user || !which) return;
    const data = {
      Male: {
        he: "he",
        him: "him",
        his: "his",
      },
      Female: {
        he: "she",
        him: "her",
        his: "her",
      },
      Other: {
        he: "they",
        him: "them",
        his: "their",
      },
    };
    return data[user.gender][which];
  },

  toDollarStr(cents) {
    return `${cents % 100 ? (cents / 100).toFixed(2) : cents / 100}`;
  },

  formatDate(date) {
    const now = moment();
    const d = moment(date).utc();
    return d.format(now.diff(d, "months") >= 10 ? "MMM Do, YYYY" : "MMM Do");
  },

  getGraphMetrics(graph) {
    const metrics = [];
    graph.forEach((node) => recurse(node, 0));

    function recurse(node, depth) {
      if (!metrics[depth]) metrics[depth] = {};
      metrics[depth].acts = (metrics[depth].acts || 0) + 1;
      metrics[depth].donations = (metrics[depth].donations || 0) + node.amount;
      const createdAt = new Date(node.createdAt);
      if (!metrics[depth].started || createdAt < metrics[depth].started)
        metrics[depth].started = createdAt;
      if (!node.children) return;
      node.children.forEach((child) => recurse(child, depth + 1));
    }
    return metrics;
  },

  event(category, action, label, value) {
    const data = {
      category: category,
      action: action,
    };
    if (label) data.label = label;
    if (value) data.value = Math.round(value);
    console.debug(
      `event: ${data.category}:${data.action}:${data.label}:${data.value}`
    );
    ReactGA.event(data);
  },

  error(errorObject, message) {
    let exceptionDescription = message;

    if (errorObject && errorObject.message) {
      exceptionDescription += ": " + errorObject.message;
    }

    console.log(exceptionDescription);
    ReactGA.exception({
      description: exceptionDescription,
      fatal: false,
    });
  },
};

export default util;
