import React, { useContext } from "react";
import ConnectButton from "../assets/images/blue-on-dark.png";
import { AppContext } from "../App";

function Connect() {
  const appContext = useContext(AppContext);

  return (
    <div id="connect" className="view">
      <a
        href={`https://connect.stripe.com/oauth/authorize?response_type=code&client_id=${appContext.config.stripe.clientId}&scope=read_write`}
      >
        <img src={ConnectButton} width="190" alt="" />
      </a>
    </div>
  );
}

export default Connect;
