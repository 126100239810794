import React from "react";
import { useRouteMatch, useHistory } from "react-router-dom";
import FloatingInput from "../FloatingInput";
import { Button } from "@material-ui/core";
import util from "../util";
import _ from "lodash";

function PifWho({ act, onUpdateActor }) {
  const { url } = useRouteMatch();
  const history = useHistory();

  function handleSubmit() {
    history.push(`${util.getParentPath(url)}/what`);
  }

  return (
    <div id="pif-desc" className="view with-nav">
      <div className="inner">
        <h2>Who performed an act of kindness for you?</h2>

        <form
          noValidate
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <fieldset>
            <div className="input-group">
              <FloatingInput
                name="actor"
                label="First Name"
                value={act.actor ? act.actor.firstName : ""}
                maxLength={255}
                onChange={(field, value) => {
                  onUpdateActor({ firstName: value });
                }}
              />
            </div>
          </fieldset>
          <div className="button-row">
            <Button type="button" onClick={() => history.goBack()}>
              Back
            </Button>
            <Button
              type="button"
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              disabled={!_.get(act, "actor.firstName")}
            >
              Next
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default PifWho;
