import React, { useEffect } from "react";
import "./assets/css/View.css";
import Loading from "./views/Loading";
import Secure from "./Secure";

function View({ loading, secure, children, precondition, onInvalidState }) {
  useEffect(() => {
    window.setHeight();
  });

  useEffect(() => {
    if (precondition === false) return onInvalidState();
  }, [precondition, onInvalidState]);

  const contents = secure ? <Secure>{children}</Secure> : children;
  return <>{loading ? <Loading /> : contents}</>;
}

export default View;
