import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import CardLink from "../CardLink";
import { UserContext } from "../App";
import { Button } from "@material-ui/core";

function PifAsk() {
  const userContext = useContext(UserContext);
  const history = useHistory();

  return (
    <div id="pif-ask" className="view with-nav">
      <div className="inner">
        <h2>Ready to pay it forward?</h2>
        <p>
          Just perform an <span className="buzzword">act of kindness</span> for
          someone else. Here are{" "}
          <Button
            className="linkish thin"
            onClick={() => history.push("/faq#UWFHSUCAQ")}
          >
            some ideas
          </Button>{" "}
          to get you started.
        </p>
        <p>
          Then{" "}
          <Button
            className="linkish thin"
            onClick={() => history.push("/faq#F4HFy7OhN")}
          >
            ask them
          </Button>{" "}
          to pay it forward if they can and send them your link. It&apos;s that
          easy!
        </p>

        <CardLink
          header="Your pay-it-forward link:"
          url={`https://rippl.me/p/${userContext.user.id}`}
        />
      </div>
    </div>
  );
}

export default PifAsk;
