import React from "react";
import "./assets/css/Spin.css";

function Spin() {
  return (
    <div className="spinner">
      <div className="lds-ring">
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
  );
}

export default Spin;
