import React, { useEffect, useContext } from "react";
import { AppContext } from "../App";
import util from "../util";

function OAuth() {
  const appContext = useContext(AppContext);
  const scope = util.getUrlParameter("scope");
  const code = util.getUrlParameter("code");
  const error = util.getUrlParameter("error");
  const errorDesc = util.getUrlParameter("error_description");
  const valid = code && scope === "read_write";

  useEffect(() => {
    if (scope && code) {
      appContext.api.putAuthCode(scope, code);
    }
  }, [scope, code, appContext.api]);

  return (
    <div id="oauth" className="view">
      {valid && (
        <main>
          <h1>Success!</h1>
          <p>Stripe Connect authorization was successful.</p>
          <p>Thank you.</p>
        </main>
      )}
      {!valid && (
        <main>
          <h1>Oops!</h1>
          <p>Looks like something went wrong.</p>
          {error && <p>{error}</p>}
          {errorDesc && <p>{errorDesc}</p>}
        </main>
      )}
    </div>
  );
}

export default OAuth;
