import React from "react";
import "./assets/css/Breadcrumbs.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";

function Breadcrumbs({ links }) {
  const history = useHistory();

  return (
    <div className="breadcrumbs">
      {links.map((link) => (
        <Button
          key={link.copy}
          className="linkish"
          onClick={() => history.push(link.url)}
        >
          <FontAwesomeIcon icon={faAngleLeft} />
          {link.copy}
        </Button>
      ))}
    </div>
  );
}

export default Breadcrumbs;
