import React, { useState, useContext, useEffect } from "react";
import {
  Switch,
  Route,
  useRouteMatch,
  useLocation,
  useParams,
  useHistory,
} from "react-router-dom";
import { AppContext, UserContext } from "../App";
import View from "../View";
import NavBar from "../NavBar";
import PifLand from "./PifLand";
import PifWho from "./PifWho";
import PifDesc from "./PifDesc";
import PifPledge from "./PifPledge";
import PifDonation from "./PifDonation";
import PifRegister from "./PifRegister";
import PifLogin from "./PifLogin";
import PifBilling from "./PifBilling";
import PifConfirm from "./PifConfirm";
import NotFound from "./NotFound";
import ErrorPage from "./Error";
import util from "../util";
import _ from "lodash";

function PifContainer(props) {
  const isFundraiser = props.fundraiser;
  const appContext = useContext(AppContext);
  const api = appContext.api;
  const userContext = useContext(UserContext);
  const actee = userContext.user;
  const { path, url } = useRouteMatch();
  const { uid } = useParams();
  const history = useHistory();
  const location = useLocation();
  const parentLoading = props.loading;
  const [loading, setLoading] = useState(true);
  const [notFound, setNotFound] = useState(false);
  const [error, setError] = useState(false);
  const [act, setAct] = useState({
    billing: {
      fee: 100,
    },
  });

  useEffect(() => {
    if (!uid) return history.replace(`${url}/!/who`);
    if (/!$/.test(location.pathname)) return history.replace(`${url}/who`);
  }, [location.pathname, history, uid, url]);

  useEffect(() => {
    if (parentLoading || !uid) return;

    if (uid === "!") {
      setAct((prev) => ({ ...prev, actee }));
      setLoading(false);
    } else {
      (async () => {
        try {
          if (isFundraiser) {
            const participant = await api.getFundraiserParticipant(uid);
            setAct((prev) => ({
              ...prev,
              actor: participant.user,
              actee,
              fundraiser: participant.fundraiser,
            }));
          } else {
            const actor = await api.getUser(uid);
            setAct((prev) => ({ ...prev, actor, actee }));
          }
        } catch (err) {
          if (err.status === 404) {
            setNotFound(true);
          } else {
            setError(true);
            util.error(err, `Failed to get user: ${uid}`);
          }
        }
        setLoading(false);
      })();
    }
  }, [uid, parentLoading, api, history, url, actee, isFundraiser]);

  function updateAct(field, value) {
    setAct((prev) => ({ ...prev, [field]: value }));
  }

  function reset() {
    const to = uid === "!" ? `${url}/who` : url;
    history.replace(to);
  }

  return notFound ? (
    <NotFound />
  ) : error ? (
    <ErrorPage />
  ) : (
    <Switch>
      <Route exact path={path}>
        <View loading={loading}>
          <NavBar />
          <PifLand act={act} />
        </View>
      </Route>
      <Route path={`${path}/who`}>
        <View loading={loading}>
          <NavBar />
          <PifWho
            act={act}
            onUpdateActor={(value) => updateAct("actor", value)}
          />
        </View>
      </Route>
      <Route path={`${path}/what`}>
        <View
          loading={loading}
          precondition={!!_.get(act, "actor.firstName")}
          onInvalidState={reset}
        >
          <NavBar />
          <PifDesc
            act={act}
            onUpdateDescription={(value) => updateAct("description", value)}
          />
        </View>
      </Route>
      <Route path={`${path}/pledge`}>
        <View
          loading={loading}
          precondition={!!act.description}
          onInvalidState={reset}
        >
          <NavBar />
          <PifPledge
            act={act}
            onUpdatePledge={(value) => updateAct("pledge", value)}
          />
        </View>
      </Route>
      <Route path={`${path}/donation`}>
        <View
          loading={loading}
          precondition={!!act.pledge}
          onInvalidState={reset}
        >
          <NavBar />
          <PifDonation
            act={act}
            onUpdateDonation={(value) => updateAct("donation", value)}
            onUpdateBilling={(value) => updateAct("billing", value)}
          />
        </View>
      </Route>
      <Route path={`${path}/account`}>
        <View loading={loading}>
          <PifRegister onUpdateActee={(value) => updateAct("actee", value)} />
        </View>
      </Route>
      <Route path={`${path}/login`}>
        <View loading={loading}>
          <PifLogin onUpdateActee={(value) => updateAct("actee", value)} />
        </View>
      </Route>
      <Route path={`${path}/billing`}>
        <View
          loading={loading}
          precondition={!!act.donation}
          onInvalidState={reset}
        >
          <NavBar />
          <PifBilling
            act={act}
            onUpdateBilling={(value) => updateAct("billing", value)}
          />
        </View>
      </Route>
      <Route path={`${path}/confirmation`}>
        <View
          loading={loading}
          precondition={!!act.billing.paymentToken}
          onInvalidState={reset}
        >
          <NavBar />
          <PifConfirm act={act} newActor={uid === "!"} />
        </View>
      </Route>
      <Route path="*">
        <View>
          <NotFound />
        </View>
      </Route>
    </Switch>
  );
}

export default PifContainer;
