import React from "react";
import { Snackbar, Slide } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

function ErrorBar({ error }) {
  function SlideUp(props) {
    return <Slide {...props} direction="up" />;
  }

  return (
    <Snackbar open={error} TransitionComponent={SlideUp}>
      <Alert elevation={6} variant="filled" severity="error">
        Oops! Looks like something went wrong. Please try again later.
      </Alert>
    </Snackbar>
  );
}

export default ErrorBar;
