import React from "react";
import { useHistory } from "react-router-dom";
import { Button } from "@material-ui/core";
import CardLink from "../CardLink";
import util from "../util";
import _ from "lodash";

function PifConfirm({ act, newActor }) {
  const history = useHistory();
  const firstName = _.get(act, "actor.firstName");

  return (
    <div id="pif-confirm" className="view with-nav">
      <div className="inner">
        <h2>Thank you!</h2>
        {newActor ? (
          <>
            <p>
              The last step is for you to invite {firstName} to select a charity
              for your donation with the following link:
            </p>
            <CardLink
              header={`${firstName}'s invitation link:`}
              url={`https://rippl.me/i/${act.id}`}
            />
          </>
        ) : (
          <p>
            {act.fundraiser ? (
              <>Your charge has been processed. </>
            ) : (
              <>
                We&apos;ve notified {firstName} to select a charity for your
                donation. We&apos;ll let you know via email once{" "}
                {util.pronoun(act.actor, "he")}&apos;s done that and your charge
                has been processed.
              </>
            )}
            All you have to do next is be on the lookout for an opportunity to
            pay it forward.
          </p>
        )}
        <p>
          You can track your open pay-it-forward pledges from your dashboard.
        </p>
        <Button
          variant="contained"
          color="primary"
          onClick={() => history.push(`/dashboard`)}
        >
          Go to My Dashboard
        </Button>
      </div>
    </div>
  );
}

export default PifConfirm;
