import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import "../assets/css/NewRipple.css";
import Loading from "./Loading";
import ErrorPage from "./Error";
import { ReactComponent as SwipeIcon } from "../assets/images/swipe.svg";
import { Button, IconButton } from "@material-ui/core";
import CardLink from "../CardLink";
import { AppContext, UserContext } from "../App";
import util from "../util";
import ReactSwipe from "react-swipe";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";

function NewRipple() {
  const api = useContext(AppContext).api;
  const userContext = useContext(UserContext);
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [fundraisers, setFundraisers] = useState([]);
  let reactSwipeEl;

  useEffect(() => {
    (async () => {
      try {
        setFundraisers(await api.getUserFundraisers(userContext.user.id));
      } catch (err) {
        setError(true);
        util.error(
          err,
          `Failed to get fundraisers for user: ${userContext.user.id}`
        );
      }
      setLoading(false);
    })();
  }, [api, userContext.user]);

  function go(url) {
    util.scrollTop();
    history.push(url);
  }

  return loading ? (
    <Loading />
  ) : error ? (
    <ErrorPage />
  ) : (
    <div id="new-ripple" className="view with-nav long expand">
      <div className="inner">
        <h2>Ready to change the world?</h2>

        <div className="carousel-container">
          <SwipeIcon id="swipe-icon" />
          <IconButton
            id="slide-left"
            className="slide-nav"
            onClick={() => reactSwipeEl.prev()}
          >
            <FontAwesomeIcon icon={faChevronLeft} />
          </IconButton>
          <IconButton
            id="slide-right"
            className="slide-nav"
            onClick={() => reactSwipeEl.next()}
          >
            <FontAwesomeIcon icon={faChevronRight} />
          </IconButton>

          <ReactSwipe
            className="carousel"
            swipeOptions={{ continuous: false }}
            childCount={fundraisers.length + 2}
            ref={(el) => (reactSwipeEl = el)}
          >
            {fundraisers.map((fundraiser) => {
              return (
                <div key={fundraiser.id} className="slide-view">
                  <p>Start a Ripple for {fundraiser.fundraiser.name}</p>
                  {fundraiser.fundraiser.charity.logoUrl && (
                    <img
                      className="charity-logo"
                      src={fundraiser.fundraiser.charity.logoUrl}
                      alt=""
                    />
                  )}

                  <p>
                    Just perform an{" "}
                    <span className="buzzword">act of kindness</span> for
                    someone else. Here are{" "}
                    <Button
                      className="linkish thin"
                      onClick={() => history.push("/faq#UWFHSUCAQ")}
                    >
                      some ideas
                    </Button>{" "}
                    to get you started.
                  </p>
                  <p>
                    Then{" "}
                    <Button
                      className="linkish thin"
                      onClick={() => history.push("/faq#F4HFy7OhN")}
                    >
                      ask them
                    </Button>{" "}
                    to pay it forward if they can and send them your link.
                    It&apos;s that easy!
                  </p>

                  <CardLink
                    header={`Your pay-it-forward link for ${fundraiser.fundraiser.name}:`}
                    url={`https://rippl.me/f/${fundraiser.id}`}
                  />

                  <p className="small">
                    <Button
                      className="linkish"
                      onClick={() => go(`/f/${fundraiser.id}`)}
                    >
                      Here&apos;s what they&apos;ll see
                    </Button>
                  </p>
                </div>
              );
            })}

            <div className="slide-view">
              {fundraisers.length ? (
                <p>Start an Open Ripple!</p>
              ) : (
                <p>Start a Ripple!</p>
              )}
              <p>
                Just perform an{" "}
                <span className="buzzword">act of kindness</span> for someone
                else. Here are{" "}
                <Button
                  className="linkish thin"
                  onClick={() => history.push("/faq#UWFHSUCAQ")}
                >
                  some ideas
                </Button>{" "}
                to get you started.
              </p>
              <p>
                Then{" "}
                <Button
                  className="linkish thin"
                  onClick={() => history.push("/faq#F4HFy7OhN")}
                >
                  ask them
                </Button>{" "}
                to pay it forward if they can and send them your link. It&apos;s
                that easy!
              </p>

              <CardLink
                header="Your pay-it-forward link:"
                url={`https://rippl.me/p/${userContext.user.id}`}
              />

              <p className="small">
                <Button
                  className="linkish"
                  onClick={() => go(`/p/${userContext.user.id}`)}
                >
                  Here&apos;s what they&apos;ll see
                </Button>
              </p>

              {!!fundraisers.length && (
                <p className="small">
                  In an open ripple, each person who performs an act of kindness
                  selects a charity for the donation made by the person
                  they&apos;ve helped so that many charities can benefit from
                  your kindness.
                </p>
              )}
            </div>

            <div className="slide-view">
              <p>
                Has someone already performed an{" "}
                <span className="buzzword">act of kindness</span> for you, but
                isn&apos;t on Ripple.me yet?
              </p>
              <p className="small">
                You can also start a ripple by pledging to pay forward their act
                of kindness. Then you can invite them to select a charity for
                your donation and follow your progress as you pay it forward.
              </p>

              <Button
                id="pif"
                variant="contained"
                color="primary"
                onClick={() => history.push("/p")}
              >
                Pay it Forward
              </Button>
            </div>
          </ReactSwipe>
        </div>
      </div>
    </div>
  );
}

export default NewRipple;
