import React from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import LoginForm from "../LoginForm";
import util from "../util";

function PifLogin({ onUpdateActee }) {
  const { url } = useRouteMatch();
  const base = util.getParentPath(url);
  const history = useHistory();

  async function onLoggedIn(user) {
    onUpdateActee(user);
    history.replace(`${base}/billing`);
  }

  return (
    <div id="pif-login" className="view">
      <div className="inner">
        <div className="header">
          <h1>Login</h1>
        </div>

        <LoginForm onLoggedIn={onLoggedIn} />
      </div>
    </div>
  );
}

export default PifLogin;
