import React from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { Button } from "@material-ui/core";
import "../assets/css/Pledges.css";
import PanelList from "../PanelList";
import Panel from "../Panel";
import RotatePhoneIcon from "../RotatePhoneIcon";
import util from "../util";
import classNames from "classnames";

function Pledges({ acts }) {
  const history = useHistory();
  const { url } = useRouteMatch();

  function viewAct(aid) {
    util.scrollTop();
    history.push(`${url}/${aid}`);
  }

  return (
    <div
      id="pledges"
      className={classNames("view", "with-nav", {
        long: acts.length,
        wide: acts.length,
      })}
    >
      <div className="inner">
        {!acts.length ? (
          <>
            <p>
              Looks like you haven&apos;t pledged to pay forward any{" "}
              <span className="buzzword">acts of kindness</span> that someone
              has performed for you yet.
            </p>
            <p>
              Why not{" "}
              <Button
                className="linkish thin"
                onClick={() => history.push("/ripple")}
              >
                start a new ripple
              </Button>{" "}
              yourself?
            </p>
          </>
        ) : (
          <>
            <h3>Your Pledges</h3>
            <PanelList>
              <RotatePhoneIcon />
              {acts.map((act) => (
                <PanelList.Item key={act.id} onClick={() => viewAct(act.id)}>
                  <Panel.Segment
                    primary
                    value={act.actor.firstName}
                    footer={
                      <>
                        <div>Helped you</div>
                        <div>{util.formatDate(act.createdAt)}</div>
                      </>
                    }
                  />
                  <Panel.Segment
                    copy
                    label="How:"
                    value={
                      act.description.length > 120
                        ? act.description.substring(0, 119) + "..."
                        : act.description
                    }
                  />
                  <Panel.Segment
                    metric
                    label="You donated:"
                    value={
                      <>
                        <span className="dollar-sign">$</span>
                        {util.toDollarStr(act.donation.amount)}
                      </>
                    }
                  />
                  <Panel.Segment
                    label="You pledged:"
                    completed={act.completed === act.pledge}
                  >
                    <Panel.Segment
                      metric
                      value={act.pledge}
                      footer={`Act${act.pledge !== 1 ? "s" : ""} of Kindness`}
                    />
                    <Panel.Segment
                      metric
                      value={act.completed}
                      footer="Completed"
                    />
                  </Panel.Segment>
                </PanelList.Item>
              ))}
            </PanelList>
          </>
        )}
      </div>
    </div>
  );
}

export default Pledges;
