import React, { useContext, useState } from "react";
import { AppContext } from "../App";
import { useHistory } from "react-router-dom";
import LoginForm from "../LoginForm";
import Loading from "./Loading";
import ErrorBar from "../ErrorBar";
import util from "../util";

function InviteLogin({ act }) {
  const appContext = useContext(AppContext);
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  async function onLoggedIn() {
    setLoading(true);
    try {
      await appContext.api.claimAct(act);
      history.replace(`/select-charity/${act.id}/search`);
    } catch (err) {
      if (err.status === 400)
        return history.replace(history.replace(`/select-charity/${act.id}`));
      setError(true);
      setLoading(false);
      util.error(err, `Failed to claim act: ${act.id}`);
    }
  }

  return loading ? (
    <Loading />
  ) : (
    <div id="invite-login" className="view">
      <div className="inner">
        <div className="header">
          <h1>Login</h1>
        </div>

        <LoginForm onLoggedIn={onLoggedIn} />
        <ErrorBar error={error} />
      </div>
    </div>
  );
}

export default InviteLogin;
