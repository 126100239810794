import React from "react";
import { useHistory } from "react-router-dom";
import { Button } from "@material-ui/core";

function Privacy() {
  const history = useHistory();

  return (
    <div id="privacy" className="view long with-nav wide">
      <div className="inner">
        <h2>Privacy</h2>
        <p>
          We take your privacy very seriously, which is why as a Rippl.me user
          you are basically anonymous.
        </p>
        <p>Quick Version</p>
        <p className="small text-left">
          We collect only your email address and first name (so that we can say
          something other than &quot;Hey You&quot; when we talk to you). We will
          never share your email address with any marketer and will never send
          you any unwanted email. We do share your name and email address with
          our donation processing partner,{" "}
          <a
            href="https://pledgeling.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            Pledgeling
          </a>
          , so that you can receive your donation receipt for tax purposes via
          email, but your charity donations are anonymous and the charities you
          donate to do not receive your email address. We also collect billing
          information for donations but never store credit numbers in our
          systems. These are passed directly to our billing partner,{" "}
          <a
            href="https://stripe.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            Stripe
          </a>
          . Finally, we collect usage information using Google Analytics so that
          we can continue to improve the Rippl.me user experience.
        </p>
        <p className="small text-left">
          You can{" "}
          <Button className="linkish" onClick={() => history.push("/contact")}>
            contact us
          </Button>{" "}
          at any time to completely delete your Rippl.me account and remove all
          of your information from our systems.
        </p>

        <p>Full Privacy Policy</p>

        <div className="legal-info">
          <p>Last updated: April 25, 2020</p>

          <p>
            This Privacy Policy describes Our policies and procedures on the
            collection, use and disclosure of Your information when You use the
            Service and tells You about Your privacy rights and how the law
            protects You.
          </p>

          <p>
            We use Your Personal data to provide and improve the Service. By
            using the Service, You agree to the collection and use of
            information in accordance with this Privacy Policy.
          </p>

          <h1>Interpretation and Definitions</h1>
          <h2>Interpretation</h2>
          <p>
            The words of which the initial letter is capitalized have meanings
            defined under the following conditions.
          </p>
          <p>
            The following definitions shall have the same meaning regardless of
            whether they appear in singular or in plural.
          </p>

          <h2>Definitions</h2>
          <p>For the purposes of this Privacy Policy:</p>
          <ul>
            <li>
              <p>
                <strong>You</strong> means the individual accessing or using the
                Service, or the company, or other legal entity on behalf of
                which such individual is accessing or using the Service, as
                applicable.
              </p>
            </li>
            <li>
              <p>
                <strong>Company</strong> (referred to as either &quot;the
                Company&quot;, &quot;We&quot;, &quot;Us&quot; or &quot;Our&quot;
                in this Agreement) refers to Rippl.me, LLC, 382 NE 191st St
                #78868 Miami, FL 33179.
              </p>
            </li>
            <li>
              <p>
                <strong>Affiliate</strong> means an entity that controls, is
                controlled by or is under common control with a party, where
                &quot;control&quot; means ownership of 50% or more of the
                shares, equity interest or other securities entitled to vote for
                election of directors or other managing authority.
              </p>
            </li>
            <li>
              <p>
                <strong>Account</strong> means a unique account created for You
                to access our Service or parts of our Service.
              </p>
            </li>
            <li>
              <p>
                <strong>Website</strong> refers to Rippl.me, accessible at.
                https://rippl.me
              </p>
            </li>
            <li>
              <p>
                <strong>Service</strong> refers to the Website.
              </p>
            </li>
            <li>
              <p>
                <strong>Country</strong> refers to: Florida, United States.
              </p>
            </li>
            <li>
              <p>
                <strong>Service Provider</strong> means any natural or legal
                person who processes the data on behalf of the Company. It
                refers to third-party companies or individuals employed by the
                Company to facilitate the Service, to provide the Service on
                behalf of the Company, to perform services related to the
                Service or to assist the Company in analyzing how the Service is
                used.
              </p>
            </li>
            <li>
              <strong>Third-party Social Media Service</strong> refers to any
              website or any social network website through which a User can log
              in or create an account to use the Service.
            </li>
            <li>
              <p>
                <strong>Personal Data</strong> is any information that relates
                to an identified or identifiable individual.
              </p>
            </li>
            <li>
              <p>
                <strong>Cookies</strong> are small files that are placed on Your
                computer, mobile device or any other device by a website,
                containing the details of Your browsing history on that website
                among its many uses.
              </p>
            </li>
            <li>
              <p>
                <strong>Device</strong> means any device that can access the
                Service such as a computer, a cellphone or a digital tablet.
              </p>
            </li>
            <li>
              <p>
                <strong>Usage Data</strong> refers to data collected
                automatically, either generated by the use of the Service or
                from the Service infrastructure itself (for example, the
                duration of a page visit).
              </p>
            </li>
          </ul>

          <h1>Collecting and Using Your Personal Data</h1>
          <h2>Types of Data Collected</h2>

          <h3>Personal Data</h3>
          <p>
            While using Our Service, We may ask You to provide Us with certain
            personally identifiable information that can be used to contact or
            identify You. Personally identifiable information may include, but
            is not limited to:
          </p>
          <ul>
            <li>Email address</li>
            <li>First name</li>
            <li>Usage Data</li>
          </ul>

          <h3>Usage Data</h3>
          <p>Usage Data is collected automatically when using the Service.</p>
          <p>
            Usage Data may include information such as Your Device&apos;s
            Internet Protocol address (e.g. IP address), browser type, browser
            version, the pages of our Service that You visit, the time and date
            of Your visit, the time spent on those pages, unique device
            identifiers and other diagnostic data.
          </p>
          <p>
            When You access the Service by or through a mobile device, We may
            collect certain information automatically, including, but not
            limited to, the type of mobile device You use, Your mobile device
            unique ID, the IP address of Your mobile device, Your mobile
            operating system, the type of mobile Internet browser You use,
            unique device identifiers and other diagnostic data.
          </p>
          <p>
            We may also collect information that Your browser sends whenever You
            visit our Service or when You access the Service by or through a
            mobile device.
          </p>

          <h3>Tracking Technologies and Cookies</h3>
          <p>
            We use Cookies and similar tracking technologies to track the
            activity on Our Service and store certain information. Tracking
            technologies used are beacons, tags, and scripts to collect and
            track information and to improve and analyze Our Service.
          </p>
          <p>
            You can instruct Your browser to refuse all Cookies or to indicate
            when a Cookie is being sent. However, if You do not accept Cookies,
            You may not be able to use some parts of our Service.
          </p>
          <p>
            Cookies can be &quot;Persistent&quot; or &quot;Session&quot;
            Cookies. Persistent Cookies remain on your personal computer or
            mobile device when You go offline, while Session Cookies are deleted
            as soon as You close your web browser.
          </p>
          <p>
            We use both session and persistent Cookies for the purposes set out
            below:
          </p>
          <ul>
            <li>
              <p>
                <strong>Necessary / Essential Cookies</strong>
              </p>
              <p>Type: Session Cookies</p>
              <p>Administered by: Us</p>
              <p>
                Purpose: These Cookies are essential to provide You with
                services available through the Website and to enable You to use
                some of its features. They help to authenticate users and
                prevent fraudulent use of user accounts. Without these Cookies,
                the services that You have asked for cannot be provided, and We
                only use these Cookies to provide You with those services.
              </p>
            </li>
            <li>
              <p>
                <strong>Functionality Cookies</strong>
              </p>
              <p>Type: Persistent Cookies</p>
              <p>Administered by: Us</p>
              <p>
                Purpose: These Cookies allow us to remember choices You make
                when You use the Website, such as remembering your login details
                or language preference. The purpose of these Cookies is to
                provide You with a more personal experience and to avoid You
                having to re-enter your preferences every time You use the
                Website.
              </p>
            </li>
            <li>
              <p>
                <strong>Tracking and Performance Cookies</strong>
              </p>
              <p>Type: Persistent Cookies</p>
              <p>Administered by: Third-Parties</p>
              <p>
                Purpose: These Cookies are used to track information about
                traffic to the Website and how users use the Website. The
                information gathered via these Cookies may directly or
                indirectly identify you as an individual visitor. This is
                because the information collected is typically linked to a
                pseudonymous identifier associated with the device you use to
                access the Website. We may also use these Cookies to test new
                pages, features or new functionality of the Website to see how
                our users react to them.
              </p>
            </li>
          </ul>
          <h2>Use of Your Personal Data</h2>
          <p>The Company may use Personal Data for the following purposes:</p>
          <ul>
            <li>
              <p>
                <strong>To provide and maintain our Service</strong>, including
                to monitor the usage of our Service.
              </p>
            </li>
            <li>
              <p>
                <strong>To manage Your Account:</strong> to manage Your
                registration as a user of the Service. The Personal Data You
                provide can give You access to different functionalities of the
                Service that are available to You as a registered user.
              </p>
            </li>
            <li>
              <p>
                <strong>For the performance of a contract:</strong> the
                development, compliance and undertaking of the purchase contract
                for the products, items or services You have purchased or of any
                other contract with Us through the Service.
              </p>
            </li>
            <li>
              <p>
                <strong>To contact You:</strong> To contact You by email or
                other equivalent forms of electronic communication, such as a
                mobile application&apos;s push notifications regarding updates
                or informative communications related to the functionalities,
                products or contracted services, including the security updates,
                when necessary or reasonable for their implementation.
              </p>
            </li>
            <li>
              <strong>To manage Your requests:</strong> To attend and manage
              Your requests to Us.
            </li>
          </ul>

          <p>
            We may share your personal information in the following situations:
          </p>

          <ul>
            <li>
              <p>
                <strong>With Service Providers:</strong> We may share Your
                personal information with Service Providers to monitor and
                analyze the use of our Service, for payment processing, to
                contact You.
              </p>
            </li>
            <li>
              <p>
                <strong>For Business transfers:</strong> We may share or
                transfer Your personal information in connection with, or during
                negotiations of, any merger, sale of Company assets, financing,
                or acquisition of all or a portion of our business to another
                company.
              </p>
            </li>
            <li>
              <p>
                <strong>With Affiliates:</strong> We may share Your information
                with Our affiliates, in which case we will require those
                affiliates to honor this Privacy Policy. Affiliates include Our
                parent company and any other subsidiaries, joint venture
                partners or other companies that We control or that are under
                common control with Us.
              </p>
            </li>
            <li>
              <p>
                <strong>With Business partners:</strong> We may share Your
                information with Our business partners to offer You certain
                products, services or promotions.
              </p>
            </li>
            <li>
              <p>
                <strong>With other users:</strong> when You share personal
                information or otherwise interact in the public areas with other
                users, such information may be viewed by all users and may be
                publicly distributed outside. If You interact with other users
                or register through a Third-Party Social Media Service, Your
                contacts on the Third-Party Social Media Service may see Your
                name, profile, pictures and description of Your activity.
                Similarly, other users will be able to view descriptions of Your
                activity, communicate with You and view Your profile.
              </p>
            </li>
          </ul>

          <h2>Retention of Your Personal Data</h2>
          <p>
            The Company will retain Your Personal Data only for as long as is
            necessary for the purposes set out in this Privacy Policy. We will
            retain and use Your Personal Data to the extent necessary to comply
            with our legal obligations (for example, if we are required to
            retain your data to comply with applicable laws), resolve disputes,
            and enforce our legal agreements and policies.
          </p>
          <p>
            The Company will also retain Usage Data for internal analysis
            purposes. Usage Data is generally retained for a shorter period of
            time, except when this data is used to strengthen the security or to
            improve the functionality of Our Service, or We are legally
            obligated to retain this data for longer time periods.
          </p>

          <h2>Transfer of Your Personal Data</h2>
          <p>
            Your information, including Personal Data, is processed at the
            Company&apos;s operating offices and in any other places where the
            parties involved in the processing are located. It means that this
            information may be transferred to — and maintained on — computers
            located outside of Your state, province, country or other
            governmental jurisdiction where the data protection laws may differ
            than those from Your jurisdiction.
          </p>
          <p>
            Your consent to this Privacy Policy followed by Your submission of
            such information represents Your agreement to that transfer.
          </p>
          <p>
            The Company will take all steps reasonably necessary to ensure that
            Your data is treated securely and in accordance with this Privacy
            Policy and no transfer of Your Personal Data will take place to an
            organization or a country unless there are adequate controls in
            place including the security of Your data and other personal
            information.
          </p>

          <h2>Disclosure of Your Personal Data</h2>
          <h3>Business Transactions</h3>
          <p>
            If the Company is involved in a merger, acquisition or asset sale,
            Your Personal Data may be transferred. We will provide notice before
            Your Personal Data is transferred and becomes subject to a different
            Privacy Policy.
          </p>
          <h3>Law enforcement</h3>
          <p>
            Under certain circumstances, the Company may be required to disclose
            Your Personal Data if required to do so by law or in response to
            valid requests by public authorities (e.g. a court or a government
            agency).
          </p>
          <h3>Other legal requirements</h3>
          <p>
            The Company may disclose Your Personal Data in the good faith belief
            that such action is necessary to:
          </p>
          <ul>
            <li>Comply with a legal obligation</li>
            <li>Protect and defend the rights or property of the Company</li>
            <li>
              Prevent or investigate possible wrongdoing in connection with the
              Service
            </li>
            <li>
              Protect the personal safety of Users of the Service or the public
            </li>
            <li>Protect against legal liability</li>
          </ul>

          <h2>Security of Your Personal Data</h2>
          <p>
            The security of Your Personal Data is important to Us, but remember
            that no method of transmission over the Internet, or method of
            electronic storage is 100% secure. While We strive to use
            commercially acceptable means to protect Your Personal Data, We
            cannot guarantee its absolute security.
          </p>

          <h1>Detailed Information on the Processing of Your Personal Data</h1>
          <p>
            Service Providers have access to Your Personal Data only to perform
            their tasks on Our behalf and are obligated not to disclose or use
            it for any other purpose.
          </p>

          <h2>Analytics</h2>
          <p>
            We use the following third-party Service provider to monitor and
            analyze the use of our Service.
          </p>
          <ul>
            <li>
              <p>
                <strong>Google Analytics</strong>
              </p>
              <p>
                Google Analytics is a web analytics service offered by Google
                that tracks and reports website traffic. Google uses the data
                collected to track and monitor the use of our Service. This data
                is shared with other Google services. Google may use the
                collected data to contextualise and personalise the ads of its
                own advertising network.
              </p>
              <p>
                You can opt-out of having made your activity on the Service
                available to Google Analytics by installing the Google Analytics
                opt-out browser add-on. The add-on prevents the Google Analytics
                JavaScript (ga.js, analytics.js and dc.js) from sharing
                information with Google Analytics about visits activity.
              </p>{" "}
              <p>
                For more information on the privacy practices of Google, please
                visit the Google Privacy & Terms web page:{" "}
                <a href="https://policies.google.com/privacy?hl=en">
                  https://policies.google.com/privacy?hl=en
                </a>
              </p>
            </li>
          </ul>

          <h2>Email Communication</h2>
          <p>
            We may use Your Personal Data to contact You with updates regarding
            the Service or the Company. You may opt-out of receiving any, or
            all, of these communications from Us by following the unsubscribe
            link or instructions provided in any email We send or by contacting
            Us.
          </p>
          <p>
            We use the following Email Service Provider to manage and send
            emails to You.
          </p>
          <ul>
            <li>
              <p>
                <strong>Mailchimp</strong>
              </p>
              <p>
                Mailchimp is an email sending service provided by The Rocket
                Science Group LLC.
              </p>
              <p>
                For more information on the privacy practices of Mailchimp,
                please visit their Privacy policy:{" "}
                <a href="https://mailchimp.com/legal/privacy/">
                  https://mailchimp.com/legal/privacy/
                </a>
              </p>
            </li>
          </ul>

          <h2>Payments</h2>
          <p>
            We provide You the opportunity to make charitable donations, and may
            provide paid products and/or services, within the Service. In that
            case, we use third-party services for payment processing (e.g.
            payment processors).
          </p>
          <p>
            We will not store or collect Your payment card details. That
            information is provided directly to Our third-party payment
            processors whose use of Your personal information is governed by
            their Privacy Policy. These payment processors adhere to the
            standards set by PCI-DSS as managed by the PCI Security Standards
            Council, which is a joint effort of brands like Visa, Mastercard,
            American Express and Discover. PCI-DSS requirements help ensure the
            secure handling of payment information.
          </p>
          <p>
            We use the following third-party Service provider to process
            payments within the Service.
          </p>

          <ul>
            <li>
              <p>
                <strong>Stripe</strong>
              </p>
              <p>
                Their Privacy Policy can be viewed at{" "}
                <a
                  href="https://stripe.com/us/privacy"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://stripe.com/us/privacy
                </a>
              </p>
            </li>
          </ul>

          <h1>Children&apos;s Privacy</h1>
          <p>
            Our Service does not address anyone under the age of 13. We do not
            knowingly collect personally identifiable information from anyone
            under the age of 13. If You are a parent or guardian and You are
            aware that Your child has provided Us with Personal Data, please
            contact Us. If We become aware that We have collected Personal Data
            from anyone under the age of 13 without verification of parental
            consent, We take steps to remove that information from Our servers.
          </p>
          <p>
            If We need to rely on consent as a legal basis for processing Your
            information and Your country requires consent from a parent, We may
            require Your parent&apos;s consent before We collect and use that
            information.
          </p>

          <h1>
            Your California Privacy Rights (California&apos;s Shine the Light
            law)
          </h1>
          <p>
            Under California Civil Code Section 1798 (California&apos;s Shine
            the Light law), California residents with an established business
            relationship with us can request information once a year about
            sharing their Personal Data with third parties for the third
            parties&apos; direct marketing purposes.
          </p>
          <p>
            If you&apos;d like to request more information under the California
            Shine the Light law, and if you are a California resident, You can
            contact Us using the contact information provided below.
          </p>

          <h1>
            California Privacy Rights for Minor Users (California Business and
            Professions Code Section 22581)
          </h1>
          <p>
            California Business and Professions Code section 22581 allow
            California residents under the age of 18 who are registered users of
            online sites, services or applications to request and obtain removal
            of content or information they have publicly posted.
          </p>
          <p>
            To request removal of such data, and if you are a California
            resident, You can contact Us using the contact information provided
            below, and include the email address associated with Your account.
          </p>
          <p>
            Be aware that Your request does not guarantee complete or
            comprehensive removal of content or information posted online and
            that the law may not permit or require removal in certain
            circumstances.
          </p>

          <h1>Links to Other Websites</h1>
          <p>
            Our Service may contain links to other websites that are not
            operated by Us. If You click on a third party link, You will be
            directed to that third party&apos;s site. We strongly advise You to
            review the Privacy Policy of every site You visit.
          </p>
          <p>
            We have no control over and assume no responsibility for the
            content, privacy policies or practices of any third party sites or
            services.
          </p>

          <h1>Changes to this Privacy Policy</h1>
          <p>
            We may update our Privacy Policy from time to time. We will notify
            You of any changes by posting the new Privacy Policy on this page.
          </p>
          <p>
            We will let You know via email and/or a prominent notice on Our
            Service, prior to the change becoming effective and update the
            &quot;Last updated&quot; date at the top of this Privacy Policy.
          </p>
          <p>
            You are advised to review this Privacy Policy periodically for any
            changes. Changes to this Privacy Policy are effective when they are
            posted on this page.
          </p>

          <h1>Contact Us</h1>
          <p>
            If you have any questions about this Privacy Policy, You can contact
            us via email at hello@rippl.me.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Privacy;
