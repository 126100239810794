import React, { useState, useEffect, useRef, useContext, useMemo } from "react";
import { useRouteMatch, useHistory } from "react-router-dom";
import { AppContext, UserContext } from "../App";
import "../assets/css/PifBilling.css";
import StripeBadge from "../assets/images/powered_by_stripe.svg";
import Panel from "../Panel";
import Spin from "../Spin";
import { Button, FormControlLabel, Checkbox } from "@material-ui/core";
import ErrorBar from "../ErrorBar";
import util from "../util";
import _ from "lodash";

function PifBilling({ act, onUpdateBilling }) {
  const appContext = useContext(AppContext);
  const api = appContext.api;
  const userContext = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [cardError, setCardError] = useState();
  const { url } = useRouteMatch();
  const history = useHistory();
  const stripe = useRef(null);
  const card = useRef(null);
  const firstName = _.get(act, "actor.firstName");
  const feeAmount = useMemo(
    () => (act.donation <= 20 ? 100 : act.donation * 5),
    [act.donation]
  );

  useEffect(() => {
    stripe.current = window.Stripe(appContext.config.stripe.pkey, {
      stripeAccount: appContext.config.stripe.account,
    });
    const elements = stripe.current.elements({
      fonts: [
        {
          cssSrc: "https://fonts.googleapis.com/css?family=Raleway",
        },
      ],
    });
    const light = _.get(appContext, "ui.theme") === "light";
    const style = {
      base: {
        fontSize: "18px",
        color: light ? "#555" : "#fff",
        "::placeholder": {
          fontFamily: "Raleway",
          color: light ? "#888" : "#b0c0d1",
        },
      },
    };
    card.current = elements.create("card", { style });
    card.current.mount("#card-element");
  }, [card, appContext]);

  function handleChange(field, value) {
    onUpdateBilling({ ...act.billing, [field]: value });
  }

  async function handleSubmit() {
    setError(false);
    setLoading(true);
    const { token, error } = await stripe.current.createToken(card.current);

    if (error) {
      setCardError(error.message);
      setLoading(false);
      return;
    }

    try {
      act.billing.paymentToken = token.id;
      await api.createAct(act);
      util.event("Act", "Pledge", null, act.donation);
      if (act.billing.fee)
        util.event("Act", "Fee", null, act.billing.fee / 100);
      history.push(`${util.getParentPath(url)}/confirmation`);
    } catch (err) {
      if (err.status === 402) {
        setCardError(err.response.body);
      } else {
        setError(true);
        util.error(err, "Failed to create act");
      }
      setLoading(false);
    }
  }

  return (
    <div id="pif-billing" className="view with-nav">
      <div className="inner">
        <h2>Let&apos;s Make it Official</h2>
        <p>Finalize your charity donation.</p>

        <form
          className={loading ? "loading" : ""}
          noValidate
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <fieldset>
            <div id="card-group" className="input-group">
              <div className="input">
                <div id="card-element" />
                {cardError && (
                  <div id="card-errors" className="help-block with-errors">
                    {cardError}
                  </div>
                )}
              </div>
            </div>
            <div id="platform-donation">
              <FormControlLabel
                value="end"
                control={
                  <Checkbox
                    color="primary"
                    size="small"
                    checked={!!act.billing.fee}
                    onChange={() =>
                      handleChange("fee", act.billing.fee ? 0 : feeAmount)
                    }
                  />
                }
                label={`Tip an additional $${util.toDollarStr(feeAmount)}${
                  act.donation > 20 ? " (5%)" : ""
                } to help keep Rippl.me going! We don't take a single cent of your charity donation and are entirely dependent on your kindness to keep the lights on.`}
                labelPlacement="end"
              />
            </div>
            <Spin />
          </fieldset>
          <p>
            Your Total:{" "}
            <span id="amount">
              ${util.toDollarStr(act.donation * 100 + act.billing.fee)}
            </span>
          </p>
          {_.get(userContext, "user.id") === act.actor.id && (
            <Panel id="pif-self" heading="Oops" alert>
              <Panel.Body>
                <p className="small">
                  Looks like you&apos;re trying to pay it forward to yourself.
                  This link is intended for you to send to someone else when you
                  ask them to pay it forward.
                </p>
              </Panel.Body>
            </Panel>
          )}
          <Button
            type="button"
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={loading || _.get(userContext, "user.id") === act.actor.id}
          >
            Submit
          </Button>
          <div>
            <Button id="back" type="button" onClick={() => history.goBack()}>
              Back
            </Button>
          </div>

          {!act.fundraiser && (
            <p className="subtext">
              A hold for $
              {util.toDollarStr(act.donation * 100 + act.billing.fee)} will be
              placed on your credit card now. Once {firstName} selects a charity
              for your donation, the charge will be processed.{" "}
            </p>
          )}
          <p className="subtext">
            <strong>
              We don&apos;t store any of your credit card information.
            </strong>
          </p>
          <div className="powered-by">
            <a
              href="https://stripe.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={StripeBadge} alt="" />
            </a>
          </div>
        </form>

        <ErrorBar error={error} />
      </div>
    </div>
  );
}

export default PifBilling;
