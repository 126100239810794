import React from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import "../assets/css/InviteRegister.css";
import { Button } from "@material-ui/core";
import RegisterForm from "../RegisterForm";
import util from "../util";

function InviteRegister({ act }) {
  const { url } = useRouteMatch();
  const history = useHistory();

  async function onUserCreated() {
    history.replace(`/select-charity/${act.id}/search`);
  }

  return (
    <div id="invite-register" className="view">
      <div className="inner">
        <p>First, let&apos;s set up your account.</p>
        <p id="login-link" className="subtext">
          Already have an account? Log in{" "}
          <Button
            className="linkish"
            onClick={() => history.replace(`${util.getParentPath(url)}/login`)}
          >
            here
          </Button>
          .
        </p>

        <RegisterForm preUser={act.actor} onUserCreated={onUserCreated} />
      </div>
    </div>
  );
}

export default InviteRegister;
